<div class="dialogContainer">
    <h3 class="title3 mb-3">{{ 'ADD_LANGUAGE_DIALOG.NEW_LANGUAGE' | translate }}</h3>

    <form [formGroup]="form" class="d-flex flex-column mb-4">
    
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'ADD_LANGUAGE_DIALOG.LANGUAGE_NAME' | translate }}</mat-label>
            <input matInput formControlName="description" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-4">
            <mat-label>{{ 'ADD_LANGUAGE_DIALOG.LANGUAGE_ISO' | translate }}</mat-label>
            <mat-select formControlName="name" hideSingleSelectionIndicator="true">
                <mat-option *ngFor="let iso of data.isoList" [value]="iso">
                    <span class="flag-icon flag-icon-{{ iso }}"></span>
                    {{ iso }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-radio-group aria-label="Select an option" color="primary" formControlName="direction" class="d-flex flex-column mb-3">
            <span class="subheadText">{{ 'ADD_LANGUAGE_DIALOG.CHOOSE_DIRECTION' | translate }}</span>
            <mat-radio-button value="L">{{ 'ADD_LANGUAGE_DIALOG.LEFT_DIRECTION' | translate }}</mat-radio-button>
            <mat-radio-button value="R">{{ 'ADD_LANGUAGE_DIALOG.RIGHT_DIRECTION' | translate }}</mat-radio-button>
        </mat-radio-group>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="addition()">{{ 'DEVICE.ADD' | translate }}</button>
        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'DEVICE.CANCEL' | translate }}</button>
    </div>
</div>