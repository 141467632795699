<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="addView">{{ 'GENERAL.ADD' | translate }} {{ 'SERVICE_TYPE_DIALOG.SERVICE_TYPE' | translate | lowercase }}</h3>
    <h3 class="title3 mb-3" *ngIf="!addView">{{ 'GENERAL.UPDATE' | translate }} {{ 'SERVICE_TYPE_DIALOG.SERVICE_TYPE' | translate | lowercase }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.ICON' | translate }}</mat-label>
            <input matInput formControlName="icon" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.COLOR' | translate }}</mat-label>
            <input matInput formControlName="color" autocomplete="off" required>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.ERROR_EMPTY' | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button *ngIf="addView" type="submit" class="btn actionButton w-50 mr-1" (click)="executeAction()">{{ 'GENERAL.ADD' | translate }}</button>
        <button mat-button *ngIf="!addView" type="submit" class="btn actionButton w-50 mr-1" (click)="executeAction()">{{ 'GENERAL.UPDATE' | translate }}</button>

        <button mat-button class="btn outlineActionButton  w-50 ml-1" mat-dialog-close>{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>