<div class="dialogContainer">
    <h3 class="title3 mb-3">{{ 'NOTIFICATION_ADD.ADD' | translate }}</h3>

    <form [formGroup]="form" class="mb-5">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'NOTIFICATION_ADD.DESTINATION_TYPE' | translate }}</mat-label>
            <mat-select formControlName="destinationTypeSelected" (selectionChange)="setDestination()" hideSingleSelectionIndicator="true" required>
                <mat-option value="u">{{ 'GENERAL.USER' | translate }}
                </mat-option>
                <mat-option value="d">{{ 'GENERAL.DOMAIN' | translate }}
                </mat-option>
                <mat-option value="a">{{ 'GENERAL.BROADCAST' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'NOTIFICATION_ADD.DESTINATION' | translate }}</mat-label>
            <mat-select formControlName="destinationSelected" hideSingleSelectionIndicator="true" required>
                <mat-option *ngFor="let user of userList" value={{user.Id}}>{{user.Firstname}} {{user.Lastname}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customTextArea mb-md-3">
            <mat-label>{{ 'NOTIFICATION_ADD.PAYLOAD' | translate }}</mat-label>
            <textarea   required matInput formControlName="payload"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="10"
                        cdkAutosizeMaxRows="10">
            </textarea>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customDatePicker mb-2">
            <mat-label>{{'NOTIFICATION_ADD.EXPIRE_DATE' | translate }}</mat-label>
            <input matInput [owlDateTime]="dt1" [min]="minDate" formControlName="expirationDate" [owlDateTimeTrigger]="dt1" readonly>

            <i [owlDateTimeTrigger]="dt1" matSuffix class="urbanicon-monitoring-outline pointer"></i>

            <owl-date-time #dt1></owl-date-time>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
    
    <div class="actionButtons d-flex justify-content-between">
        <button mat-button class="btn outlineActionButton w-50 mr-1" matDialogClose>{{ 'GENERAL.CANCEL' | translate }}</button>
        <button mat-button type="submit" class="btn actionButton w-50 ml-1" (click)="add()">{{ 'GENERAL.ADD'|translate }}</button>
    </div>
</div>