<div class="dialogContainer">
    <h3 class="title3 mb-3">{{ 'ADMINISTRATION.NEW_ROLE' | translate }}</h3>

    <form [formGroup]="form" class="d-flex flex-column mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-2">
            <mat-label>{{ 'ADMINISTRATION.ROLE_NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
    
    <div class="d-flex justify-content-between">
        <button mat-button class="btn outlineActionButton w-50 mr-1" matDialogClose>{{ 'DEVICE.CANCEL' | translate }}</button>
        <button mat-button type="submit" class="btn actionButton w-50 ml-1" (click)="addition()">{{ 'DEVICE.ADD' | translate }}</button>
    </div>
</div>