<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="this.addMode">{{ 'GENERAL.ADD' | translate }} {{ 'GENERAL.WIDGET' | translate }}</h3>
    <h3 class="title3 mb-3" *ngIf="!this.addMode">{{ 'GENERAL.EDIT' | translate }} {{ 'GENERAL.WIDGET' | translate }}</h3>
    
    <form [formGroup]="form" class="mb-3">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="Name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customTextArea mb-3">
            <mat-label>{{ 'GENERAL.DESCRIPTION' | translate }}</mat-label>
            <textarea   matInput formControlName="Description"                         
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="5"
                        cdkAutosizeMaxRows="5" 
                        autocomplete="off" 
                        required>
            </textarea>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.ERROR_EMPTY' | translate }}
        </mat-error>
    </form>

    <button mat-button class="btn outlineActionButton w-100 mb-4" [matMenuTriggerFor]="selectRoleMenu">{{ 'ADMINISTRATION.ADD_ROLE' | translate }}</button>

    <mat-menu #selectRoleMenu="matMenu" class="customMenu h-75">
        <div class="menuPadding overlayBG">
            <mat-selection-list #roleSelectionList class="d-flex flex-column"  #role (selectionChange)="onRoleSelected(role.selectedOptions.selected)">
                <mat-list-option *ngFor="let role of roleList"
                                (click)="$event.stopPropagation()" 
                                [disableRipple]="true"  
                                [value]="role" 
                                [ngClass]="{'d-none' : role.Name == 'Administrators'}">
                    {{role.Name}}
                </mat-list-option>
            </mat-selection-list>
        </div>
    </mat-menu>

    <div class="selectedRoleContainer">
        <div *ngFor="let role of form.get('Roles')?.value" class="d-inline-flex align-items-center selectedRole">
            <span class="paragraphText" [ngClass]="{'m-0' : role.Name === 'Administrators'}">{{role.Name}}</span>
            <i *ngIf="role.Name !== 'Administrators'" class="urbanicon-remove active pointer" (click)="removeRoleFromWidget(role.Id)"></i>
        </div>
    </div>

    <div class="d-flex justify-content-between">
        <button mat-button class="btn outlineActionButton w-50 mr-1" mat-dialog-close>
            {{ 'GENERAL.CANCEL' | translate }}
        </button>

        <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="this.addMode" (click)="addWidget()">
            {{ 'GENERAL.ADD' | translate }}
        </button>

        <button mat-button type="submit" class="btn actionButton w-50 ml-1" *ngIf="!this.addMode" (click)="editWidget()">
            {{ 'GENERAL.UPDATE' | translate }}
        </button>
    </div>
</div>