<div class="dialogContainer">
    <h3 class="title3 mb-3">{{ 'AROUND_ME_DIALOG.TITLE' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <div *ngIf="addressVisible">
            <mat-form-field appearance="outline" class="customMatFormField mb-3">
                <mat-label>{{ 'DEVICE.ADDRESS' | translate }}</mat-label>
                <input matInput id="autocomplete" type="text">
            </mat-form-field>
        </div>
    
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE.LATITUDE' | translate }}</mat-label>
            <input matInput type="number" formControlName="latitude" type="text" autocomplete="off" required [readonly]="positionBlocked">
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE.LONGITUDE' | translate }}</mat-label>
            <input matInput type="number" formControlName="longitude" type="text" autocomplete="off" required [readonly]="positionBlocked">
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>Range Km²</mat-label>
            <input matInput type="number" formControlName="range" autocomplete="off">
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE.FILTER_BY_NAME' | translate }}</mat-label>
            <input matInput type="text" formControlName="device" autocomplete="off">
        </mat-form-field>
    
        <mat-error *ngIf="!correctInput">
            {{ 'AROUND_ME_DIALOG.ERROR' | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button class="btn actionButton w-50 mr-1" (click)="search()">{{ 'GENERAL.SEARCH' | translate }}</button>
        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>