import { HttpClient } from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {AddUserRequest} from "../models/administrator/addUserRequest";
import {AuthorizeUserRequest} from "../models/administrator/authorizeUserRequest";
import {UpdateUserRequest} from "../models/administrator/updateUserRequest";
import {User} from "../models/loginResponse";
import {Role} from "../models/userRoles";
import {LoaderService} from "./loader/loader.service";
import {MainState} from '../../store/main/main.reducer';
import * as MainActions from '../../store/main/main.actions';
import {Domain} from "../models/domain";
import {AddDomainRequest} from "../models/administrator/addDomainRequest";
import {AddRoleRequest} from "../models/administrator/addRoleRequest";
import {AuthorizeRoleRequest} from "../models/administrator/authorizeRoleRequest";
import {Resource} from "../models/resource";
import {Phrase} from "../models/administrator/phrase";
import {ClientSettingRequest} from "../models/administrator/clientSettingRequest";
import {ClientSettings} from "../models/clientSettings";
import {ResourceRequest} from "../models/administrator/resourceRequest";
import {DeviceBrand} from "../models/deviceBrand";
import {DeviceBrandGet} from "../models/administrator/deviceBrandGet";
import {DeviceModelRequest} from "../models/administrator/deviceModelRequest";
import {DeviceType} from "../models/deviceType";
import {Device} from "../models/device";
import {DeviceTypeCapability} from "../models/deviceTypeCapability";
import {DeviceGroup} from "../models/deviceGroup";
import {DeviceCommandType} from "../models/device-command/deviceCommandType";
import {WidgetRequest} from "../models/administrator/widgetRequest";
import {ServerSetting} from "../models/administrator/serverSetting";
import {NameEntity} from "../models/nameEntity";
import {TemplateRequest} from "../models/administrator/templateRequest";
import {TemplateItem} from "../models/administrator/templateItem";
import {LocationEventRequest} from "../models/administrator/locationEventRequest";
import {DeviceEventRequest} from "../models/administrator/deviceEventRequest";
import {DomainVisibility} from "../models/domainVisibility";
import {ThemeRequest} from "../models/administrator/themeRequest";
import { DeviceEventsRejectedResponse } from "../models/administrator/deviceEventsRejectedResponse";

@Injectable({
  providedIn: 'root'
})
export class AdministratorApiService {
  constructor(private http: HttpClient, private store: Store<MainState>, private readonly loaderService: LoaderService) {
  }

  public addUser(request: AddUserRequest): Observable<any> {
    return this.http.post<AddUserRequest>(
      `system.admin.user.add.api`,
      request
    ).pipe(
      map((res: any) => res.Id),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public authorizeUser(request: AuthorizeUserRequest): Observable<any> {
    return this.http.post<AuthorizeUserRequest>(
      `system.admin.user.authorize.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deauthorizeUser(request: AuthorizeUserRequest): Observable<any> {
    return this.http.post<AuthorizeUserRequest>(
      `system.admin.user.deauthorize.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteUser(userId: string): Observable<any> {
    return this.http.get<any>(
      `system.admin.user.delete.api?id=${userId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public getUser(userId: string): Observable<User> {
    return this.http.get<any>(
      `system.admin.user.get.api?id=${userId}`
    ).pipe(
      map((res: any) => res.User),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public hardDeleteUser(userId: string): Observable<any> {
    return this.http.get<any>(
      `system.admin.user.harddelete.api?id=${userId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public listUsers(): Observable<User[]> {
    return this.http.get<any>(
      `system.admin.user.list.api`
    ).pipe(
      map((res: any) => res.Users),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateUser(request: UpdateUserRequest): Observable<any> {
    return this.http.post<UpdateUserRequest>(
      `system.admin.user.update.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public roleList(): Observable<Role[]> {
    return this.http.get<any>(
      `system.admin.role.list.api`
    ).pipe(
      map((res: any) => res.Roles),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public domainList(): Observable<Domain[]> {
    return this.http.get<any>(
      `system.admin.domain.list.api`
    ).pipe(
      map((res: any) => res.Domains),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public addDomain(request: AddDomainRequest): Observable<Domain> {
    return this.http.post<AddDomainRequest>(
      `uv.domain.add.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public addRole(request: AddRoleRequest): Observable<Role> {
    return this.http.post<AddRoleRequest>(
      `system.admin.role.add.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public authorizeRole(request: AuthorizeRoleRequest): Observable<Role> {
    return this.http.post<AuthorizeRoleRequest>(
      `system.admin.resource.authorize.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deauthorizeRole(request: AuthorizeRoleRequest): Observable<Role> {
    return this.http.post<AuthorizeRoleRequest>(
      `system.admin.resource.deauthorize.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public resourceList(): Observable<Resource[]> {
    return this.http.get<any>(
      `system.admin.resource.list.api`
    ).pipe(
      map((res: any) => res.Resources),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public grantList(roleId: string): Observable<Resource[]> {
    return this.http.get<any>(
      `system.admin.resource.grants.api?r=${roleId}`
    ).pipe(
      map((res: any) => res.Resources),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public setDomainProperty(domainId: String, key: String, value: String): Observable<any> {
    return this.http.post<any>(
      `uv.domain.property.set.api`,
      {
        domain: domainId,
        name: key,
        value: value
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public upsertPhrase(request: Phrase): Observable<any> {
    return this.http.post<any>(
      `uv.utility.phrase.add.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public phraseList(language: string): Observable<Phrase[]> {
    return this.http.get<any>(
      `uv.utility.phrase.list.api?language=${language}`
    ).pipe(
      map((res: any) => res.Phrases),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public setClientSetting(request: ClientSettingRequest): Observable<any> {
    return this.http.post<any>(
      `uv.utility.clientsetting.set.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public getClientSettings(): Observable<any> {
    return this.http.get<ClientSettings>(`uv.utility.clientsetting.list.api`).pipe(
      map(res => res.Settings)
    )
  }

  public addResource(request: ResourceRequest): Observable<any> {
    return this.http.post<any>(
      `uv.resource.add.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateResource(request: ResourceRequest): Observable<any> {
    return this.http.post<any>(
      `uv.resource.update.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteResource(resourceId: string): Observable<any> {
    return this.http.post<any>(
      `uv.resource.delete.api`,
      {
        resourceId: resourceId
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public getResources(): Observable<ResourceRequest[]> {
    return this.http.get<any>(`uv.resource.list.api`).pipe(
      map(res => res.Resources)
    )
  }

  public addDeviceBrand(name: String): Observable<any> {
    return this.http.post<any>(
      `uv.devicebrand.add.api`,
      {
        "Name": name
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateDeviceBrand(request: DeviceBrand): Observable<any> {
    return this.http.post<any>(
      `uv.devicebrand.update.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteDeviceBrand(deviceBrandId: string): Observable<any> {
    return this.http.get<any>(
      `uv.devicebrand.delete.api?id=${deviceBrandId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deviceBrandGet(deviceBrandId: string): Observable<DeviceBrandGet> {
    return this.http.get<any>(
      `uv.devicebrand.get.api?id=${deviceBrandId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public addDeviceModel(request: DeviceModelRequest): Observable<any> {
    return this.http.post<any>(
      `uv.devicemodel.add.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateDeviceModel(request: DeviceModelRequest): Observable<any> {
    return this.http.post<any>(
      `uv.devicemodel.update.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteDeviceModel(deviceModelId: string): Observable<any> {
    return this.http.get<any>(
      `uv.devicemodel.delete.api?id=${deviceModelId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public addDeviceType(request: DeviceType): Observable<any> {
    return this.http.post<any>(
      `uv.devicetype.add.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateDeviceType(request: DeviceType): Observable<any> {
    return this.http.post<any>(
      `uv.devicetype.update.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteDeviceType(deviceTypeId: string): Observable<any> {
    return this.http.get<any>(
      `uv.devicetype.delete.api?id=${deviceTypeId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public devicesByModel(deviceModelId: string): Observable<Device[]> {
    return this.http.get<any>(
      `uv.device.list.model.api?id=${deviceModelId}`
    ).pipe(
      map((res: any) => res.Devices),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public addDeviceTypeCapability(request: DeviceTypeCapability): Observable<any> {
    return this.http.post<any>(
      `uv.devicetype.capability.add.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateDeviceTypeCapability(request: DeviceTypeCapability): Observable<any> {
    return this.http.post<any>(
      `uv.devicetype.capability.update.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteDeviceTypeCapability(deviceTypeCapabilityId: string): Observable<any> {
    return this.http.get<any>(
      `uv.devicetype.capability.delete.api?id=${deviceTypeCapabilityId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deviceTypeGet(deviceTypeId: string): Observable<DeviceType> {
    return this.http.get<any>(
      `uv.devicetype.get.api?id=${deviceTypeId}`
    ).pipe(
      map((res: any) => res.DeviceType),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deviceGroupList(): Observable<DeviceGroup[]> {
    return this.http.get<any>(
      `uv.devicegroup.list.api`
    ).pipe(
      map((res: any) => res.Groups),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deviceGroupAdd(name: String, color: String): Observable<any> {
    return this.http.post<any>(
      `uv.devicegroup.add.api`,
      {
        "Name": name,
        "Color": color
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deviceGroupUpdate(name: string, color: string, deviceGroupId: string): Observable<any> {
    return this.http.post<any>(
      `uv.devicegroup.update.api`,
      {
        "Id": deviceGroupId,
        "Name": name,
        "Color": color
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deviceGroupDelete(deviceGroupId: string): Observable<any> {
    return this.http.get<any>(
      `uv.devicegroup.delete.api?id=${deviceGroupId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deviceGroupSet(deviceId: string, deviceGroupId: string): Observable<any> {
    return this.http.post<any>(
      `uv.device.group.set.api`,
      {
        "Group": deviceGroupId,
        "Device": deviceId
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deviceGroupRemove(deviceId: string, deviceGroupId: string): Observable<any> {
    return this.http.post<any>(
      `uv.device.group.remove.api`,
      {
        "Group": deviceGroupId,
        "Device": deviceId
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deviceGroupGet(deviceGroupId: string): Observable<DeviceGroup> {
    return this.http.get<any>(
      `uv.devicegroup.get.api?id=${deviceGroupId}`
    ).pipe(
      map((res: any) => res.DeviceGroup),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deviceParentRemove(deviceId: string): Observable<any> {
    return this.http.get<any>(
      `uv.device.parent.remove.api?device=${deviceId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deviceSetCoordinates(deviceId: string, latitude: number, longitude: number): Observable<any> {
    return this.http.post<any>(
      `uv.device.coordinates.set.api`,
      {
        "device": deviceId,
        "latitude": latitude,
        "longitude": longitude
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public executePost(url: string, request: any): Observable<any> {
    return this.http.post<any>(
      url,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public executeGet(url: string): Observable<any> {
    return this.http.get<any>(
      url
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public addDeviceCommandType(request: DeviceCommandType): Observable<any> {
    return this.http.post<DeviceCommandType>(
      `uv.device.command.type.add.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateDeviceCommandType(request: DeviceCommandType): Observable<any> {
    return this.http.post<DeviceCommandType>(
      `uv.device.command.type.update.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    );
  }

  public deleteDeviceCommandType(deviceCommandTypeId: number): Observable<any> {
    return this.http.get<any>(
      `uv.device.command.type.delete.api?id=${deviceCommandTypeId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide();
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    );
  }

  public listDeviceCommandType(): Observable<DeviceCommandType[]> {
    return this.http.get<DeviceCommandType[]>(
      `uv.device.command.type.list.api`
    ).pipe(
      map((res: any) => res.DeviceCommandTypes),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    );
  }

  public addWidget(request: WidgetRequest): Observable<any> {
    return this.http.post<WidgetRequest>(
      `uv.widget.add.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateWidget(request: WidgetRequest): Observable<any> {
    return this.http.post<WidgetRequest>(
      `uv.widget.update.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteWidget(widgetId: string): Observable<any> {
    return this.http.get<any>(
      `uv.widget.delete.api?id=${widgetId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide();
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    );
  }

  public listServerSetting(): Observable<ServerSetting[]> {
    return this.http.get<ServerSetting[]>(
      `uv.server.setting.list.api`
    ).pipe(
      map((res: any) => res.Settings),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    );
  }

  public addServerSetting(request: ServerSetting): Observable<any> {
    return this.http.post<ServerSetting>(
      `uv.server.setting.add.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateServerSetting(request: ServerSetting): Observable<any> {
    return this.http.post<ServerSetting>(
      `uv.server.setting.update.api?id=${request.Id}`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteServerSetting(serverSettingId: string): Observable<any> {
    return this.http.get<any>(
      `uv.server.setting.delete.api?id=${serverSettingId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide();
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    );
  }

  public propagateSettings(): Observable<any> {
    return this.http.get<any>(
      `uv.server.setting.propagate.api`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide();
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    );
  }

  public listTemplateFormat(): Observable<NameEntity<number>[]> {
    return this.http.get<NameEntity<number>[]>(
      `uv.template.format.list.api`
    ).pipe(
      map((res: any) => res.Items),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    );
  }

  public addTemplateFormat(request: NameEntity<number>): Observable<any> {
    return this.http.post<NameEntity<number>>(
      `uv.template.format.add.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateTemplateFormat(request: NameEntity<number>): Observable<any> {
    return this.http.post<NameEntity<number>>(
      `uv.template.format.update.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteTemplateFormat(templateFormatId: number): Observable<any> {
    return this.http.get<any>(
      `uv.template.format.delete.api?id=${templateFormatId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide();
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    );
  }

  public listTemplateType(): Observable<NameEntity<number>[]> {
    return this.http.get<NameEntity<number>[]>(
      `uv.template.type.list.api`
    ).pipe(
      map((res: any) => res.Items),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    );
  }

  public addTemplateType(request: NameEntity<number>): Observable<any> {
    return this.http.post<NameEntity<number>>(
      `uv.template.type.add.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateTemplateType(request: NameEntity<number>): Observable<any> {
    return this.http.post<NameEntity<number>>(
      `uv.template.type.update.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteTemplateType(templateTypeId: number): Observable<any> {
    return this.http.get<any>(
      `uv.template.type.delete.api?id=${templateTypeId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide();
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    );
  }

  public listTemplate(): Observable<TemplateItem[]> {
    return this.http.get<TemplateItem[]>(
      `uv.template.list.api`
    ).pipe(
      map((res: any) => res.Items),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    );
  }

  public addTemplate(request: TemplateRequest): Observable<any> {
    return this.http.post<TemplateRequest>(
      `uv.template.add.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateTemplate(request: TemplateRequest): Observable<any> {
    return this.http.post<TemplateRequest>(
      `uv.template.update.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteTemplate(templateId: number): Observable<any> {
    return this.http.get<any>(
      `uv.template.delete.api?id=${templateId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide();
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    );
  }

  public setLocationProperty(locationId: String, key: String, value: String): Observable<any> {
    return this.http.post<any>(
      `uv.location.property.api`,
      {
        location: locationId,
        key: key,
        value: value
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public setLocationEventProperty(locationEventId: String, key: String, value: String): Observable<any> {
    return this.http.post<any>(
      `uv.location.event.property.api`,
      {
        locationEvent: locationEventId,
        key: key,
        value: value
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public locationEventAdd(request: LocationEventRequest): Observable<any> {
    return this.http.post(
      `uv.location.event.add.api`,
      request
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public locationEventUpdate(request: LocationEventRequest): Observable<any> {
    return this.http.post(
      `uv.location.event.update.api`,
      request
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public locationEventDelete(locationEventId: string): Observable<any> {
    return this.http.get(
      `uv.location.event.delete.api?id=${locationEventId}`
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public deviceEventAdd(request: DeviceEventRequest): Observable<any> {
    return this.http.post(
      `uv.device.event.add.api`,
      request
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public deviceEventDelete(eventIds: string): Observable<any> {
    return this.http.get(
      `uv.device.event.delete.api?ids=${eventIds}`
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public deviceEventsRejected(minutes: number): Observable<DeviceEventsRejectedResponse> {
    return this.http.get<DeviceEventsRejectedResponse>(
      `uv.device.events.rejected.api?minutes=${minutes}`
    ).pipe(
      map((res: DeviceEventsRejectedResponse) => res),
      catchError(err => {
        this.loaderService.hide();
        this.store.dispatch(MainActions.setError({ error: err?.error?.Message ?? 'customError' }));
        return [];
      })
    );
  }

  public updateDomain(request: AddDomainRequest): Observable<Domain> {
    return this.http.post<AddDomainRequest>(
      `uv.domain.update.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public domainVisibilityList(): Observable<DomainVisibility[]> {
    return this.http.get<DomainVisibility[]>(
      `system.admin.domain.visibility.list.api`
    ).pipe(
      map((res: any) => res.Items),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    );
  }

  public domainVisibilityAdd(request: DomainVisibility): Observable<any> {
    return this.http.post(
      `system.admin.domain.visibility.add.api`,
      request
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public domainVisibilityUpdate(request: DomainVisibility): Observable<any> {
    return this.http.post(
      `system.admin.domain.visibility.update.api`,
      request
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public domainVisibilityDelete(domainVisibilityId: number): Observable<any> {
    return this.http.get(
      `system.admin.domain.visibility.delete.api?id=${domainVisibilityId}`
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public themeList(): Observable<ThemeRequest[]> {
    return this.http.get<ThemeRequest[]>(
      `uv.theme.list.api`
    ).pipe(
      map((res: any) => res.Items),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    );
  }

  public themeAdd(request: ThemeRequest): Observable<any> {
    return this.http.post(
      `uv.theme.add.api`,
      request
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public themeUpdate(request: ThemeRequest): Observable<any> {
    return this.http.post(
      `uv.theme.update.api`,
      request
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public themeDelete(id: number): Observable<any> {
    return this.http.get(
      `uv.theme.delete.api?id=${id}`
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public themeDetail(id: number): Observable<ThemeRequest> {
    return this.http.get<ThemeRequest>(
      `uv.theme.detail.api?id=${id}`
    ).pipe(
      map((res: any) => res.Theme),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    );
  }
}
