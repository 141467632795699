<div class="dialogContainer">
    <h3 class="title3 mb-3">{{ 'ADMINISTRATION.NEW_DOMAIN' | translate }}</h3>

    <form [formGroup]="form"  class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'ADMINISTRATION.DOMAIN_NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'ADMINISTRATION.DOMAIN_PARENT' | translate }}</mat-label>
            <mat-select formControlName="parentSelected" hideSingleSelectionIndicator="true">
                <mat-option *ngFor="let parentDomain of parents" value={{parentDomain.Id}}>{{parentDomain.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'ADMINISTRATION.DOMAIN_VISIBILITY' | translate }}</mat-label>
            <mat-select required formControlName="visibilitySelected" hideSingleSelectionIndicator="true">
                <mat-option *ngFor="let visibility of domainVisibilityItems" value={{visibility.Id}}>
                    {{visibility.Description}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error" class="mt-2">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="addition()">{{ 'DEVICE.ADD' | translate }}</button>
        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'DEVICE.CANCEL' | translate }}</button>
    </div>
</div>