<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="this.isAdd">{{ 'DEVICE_MODEL.ADD_BODY_FIELD' | translate }}</h3>
    <h3 class="title3 mb-3" *ngIf="!this.isAdd">{{ 'DEVICE_MODEL.UPDATE_BODY_FIELD' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-checkbox matInput class="mb-3" formControlName="optional" labelPosition="after">
            {{ 'DEVICE_MODEL.OPTIONAL' | translate }}        
        </mat-checkbox>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE_MODEL.MIN' | translate }}</mat-label>
            <input matInput formControlName="min" type="number" autocomplete="off" [required]="minRequired">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE_MODEL.MAX' | translate }}</mat-label>
            <input matInput formControlName="max" autocomplete="off">
            <mat-error *ngIf="form.controls.max.errors?.min !== undefined">{{ 'DEVICE_MODEL.MAX_MINOR_THAN_MIN' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3" matTooltip="{{ 'DEVICE_MODEL.MAX_OFFSET_HINT' | translate }}">
            <mat-label>{{ 'DEVICE_MODEL.MAX_OFFSET' | translate }}</mat-label>
            <input matInput formControlName="maxOffset" type="number" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE_MODEL.DECIMALS_COUNT' | translate }}</mat-label>
            <input matInput formControlName="maxOffset" type="number" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE_MODEL.RANGE_MANDATORY' | translate }}</mat-label>
            <mat-select formControlName="rangeMandatory" required>
                <mat-option *ngFor="let option of rangeMandatoryOptions" value="{{ option }}">
                    {{ rangeMandatoryEnum[option] }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3" matTooltip="{{ 'DEVICE_MODEL.PATH_HINT' | translate }}">
            <mat-label>{{ 'DEVICE_MODEL.PATH' | translate }}</mat-label>
            <input matInput formControlName="path" autocomplete="off"
                placeholder="{{'DEVICE_MODEL.PATH_PLACEHOLDER' | translate}}">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE_MODEL.MAP_TO' | translate }}</mat-label>
            <input matInput formControlName="mapTo" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE_MODEL.TYPE' | translate }}</mat-label>
            <mat-select formControlName="type" required>
                <mat-option *ngFor="let option of randomTypeOptions" value="{{ option }}">
                    {{ randomTypeEnum[option] }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3"
            [matTooltip]="(showHint === 'default') ? ('DEVICE_MODEL.DEFAULT_HINT' | translate) : (showHint === 'random') ? ('DEVICE_MODEL.RANDOM_HINT' | translate) : ''">
            <mat-label>{{ 'DEVICE_MODEL.DEFAULT' | translate }}</mat-label>
            <input matInput formControlName="default" autocomplete="off">
        </mat-form-field>
        
        <mat-form-field appearance="outline" class="customMatFormField mb-3"
            [matTooltip]="(showHint === 'defaults') ? ('DEVICE_MODEL.DEFAULTS_HINT' | translate) : (showHint === 'random') ? ('DEVICE_MODEL.RANDOM_HINT' | translate) : ''">
            <mat-label>{{ 'DEVICE_MODEL.DEFAULTS' | translate }}</mat-label>
            <input matInput formControlName="defaults" autocomplete="off"
                placeholder="{{'DEVICE_MODEL.DEFAULTS_PLACEHOLDER' | translate}}">
        </mat-form-field>
        
        <mat-form-field appearance="outline" class="customMatFormField mb-3"
            [matTooltip]="(showHint === 'random') ? ('DEVICE_MODEL.RANDOM_HINT' | translate) : ''">
            <mat-label>{{ 'DEVICE_MODEL.RANDOM_VALUES_COUNT' | translate }}</mat-label>
            <input matInput formControlName="randomValuesCount" type="number" autocomplete="off">
        </mat-form-field>

        <mat-error #errorMessage *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button *ngIf="this.isAdd" type="submit" class="btn actionButton w-50 mr-1" (click)="saveEventBody()">
            {{ 'GENERAL.ADD' | translate }}
        </button>
        <button mat-button *ngIf="!this.isAdd" type="submit" class="btn actionButton w-50 mr-1" (click)="saveEventBody()">
            {{ 'GENERAL.UPDATE' | translate }}
        </button>
        <button mat-button class="btn outlineActionButton w-50 ml-1" mat-dialog-close>{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>