<mat-nav-list class="pt-4">
    <div #dashboardButton class="mb-5 dashboardButton">
        <button *ngIf="isSidebarOpened" mat-menu-item class="pt-3 mat-menu-item-fix" [ngClass]="{'activeDashboard': oneActive === 'dashboard'}" (click)="goToMain()">
            <div class="d-flex align-items-center">
                <i [ngClass]="(oneActive === 'dashboard') ? 'urbanicon-dashboard' : 'urbanicon-dashboard-outline'"></i>
                <p class="linkText">{{ 'SIDEBAR.DASHBOARD' | translate }}</p>
            </div>
        </button>

        <button *ngIf="!isSidebarOpened" mat-menu-item class="d-flex justify-content-center p-0" [ngClass]="{'activeDashboard': oneActive === 'dashboard'}" (click)="goToMain()">
            <i [ngClass]="(oneActive === 'dashboard') ? 'urbanicon-dashboard' : 'urbanicon-dashboard-outline'" matTooltip="{{ 'SIDEBAR.DASHBOARD' | translate }}"></i>
        </button>
    </div>

    <div *ngFor="let section of sidebarSections; let i = index"
        [ngClass]="[i === 0 ? 'mb-3' : 'my-3', i !== sidebarSections.length - 1 ? 'sidebarBorder' : '']">
        <h6 *ngIf="isSidebarOpened" class="text-uppercase sidebarSectionTitle paragraphText" [ngClass]="i === 0 ? 'pb-4' : 'py-4'">{{section.Title | translate}} {{index}}</h6>

        <urban-menu-list *ngFor="let item of section.List" [item]="item" [expandedItem]="oneExpanded" [activeItem]="oneActive"
            (sidebarClose)="sidebarCloser()"
            (sidebarToggler)="sidebarToggler()"
            (clickedToggler)="clickedEvent($event)"
            [sidebarOpened]="isSidebarOpened">
        </urban-menu-list>
    </div>
<mat-nav-list>