import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from './../models/deviceEventLatest';
import { GoogleMapsKey } from './../models/googleMapsKey';
import { DangerRateDomainRequest, DangerRateEvent, MostDangerousEvent, MostDangerousEventsRequest } from '../models/roadRisk';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, takeWhile} from 'rxjs/operators';
import { CheckTokenResponse } from '../models/checkTokenResponse';
import { Configuration } from '../models/configuration';
import { FindEmailResponse } from '../models/findEmailResponse';
import { LoginResponse, User } from '../models/loginResponse';
import { ResetPasswordResponse } from '../models/resetPasswordResponse';
import { Domain } from '../models/domain';
import { GetAvailableDomainsResponse } from '../models/getAvailableDomainsResponse';
import { Resource } from '../models/resource';
import { Device } from '../models/device';
import { GetDevicesResponse } from '../models/getDevicesResponse';
import { DeviceBrand } from '../models/deviceBrand';
import { GetDevicesBrandsResponse } from '../models/getDevicesBrandsResponse';
import { DeviceModel } from '../models/deviceModel';
import { GetDevicesModelsResponse } from '../models/getDevicesModelsResponse';
import { DeviceCreateRequest } from '../models/deviceCreateRequest';
import { DeviceUpdateRequest } from '../models/deviceUpdateRequest';
import { DeviceDeleteRequest } from '../models/deviceDeleteRequest';
import { DevicePropertyRequest } from '../models/devicePropertyRequest';
import { Role, UserRoles } from '../models/userRoles';
import { ChangePasswordRequest } from '../models/changePasswordRequest';
import { DeviceType } from '../models/deviceType';
import { DeviceTypeListResponse } from '../models/deviceTypeListResponse';
import { DeviceDetailResponse } from '../models/deviceDetailResponse';
import { SearchVideoRequest } from '../models/searchVideoRequest';
import { SearchVideoResponse } from '../models/searchVideoResponse';
import { Video } from '../models/video';
import { LatestVideosRequest } from '../models/latestVideosRequest';
import { ClientSettings } from '../models/clientSettings';
import { Translation } from '../models/translation';
import { GetTranslationsModel } from '../models/getTranslationsModel';
import { UserPropertyRequest } from '../models/userPropertyRequest';
import { UpdateUserRequest } from '../models/administrator/updateUserRequest';
import { LoaderService } from './loader/loader.service';
import { Store } from '@ngrx/store';
import { MainState } from '../../store/main/main.reducer';
import * as MainActions from '../../store/main/main.actions';
import { AddAuthorizationRequest } from '../models/addAuthorizationRequest';
import { AddUserDomainRequest } from '../models/addUserDomainRequest';
import { SendMessageRequest } from '../models/sendMessageRequest';
import { TraceListRequest, TraceListResponse } from '../models/traceListElements';
import { AccessCountResponse, AccessListCountRequest, AccessListCountWithDomainRequest, AccessListResponse } from '../models/accessListElements';
import { PhonePrefix } from '../models/phonePrefix';
import { DomainProperty } from '../models/domainProperty';
import { StyleModel } from '../models/styleModel';
import { DeviceEventRequest, DeviceEventsDevice, DeviceEventsEvent, DeviceEventsResponse } from '../models/deviceEvent';
import { SetUserPropertyRequest } from '../models/setUserPropertyRequest';
import { AddRemoveLanguageRequest } from '../models/language';
import { CheckLangDiffRequest } from '../models/checkLangDiffRequest';
import { Phrase } from '../models/administrator/phrase';
import { DeviceAlias } from '../models/deviceAlias';
import { DeviceEventsRequest } from '../models/deviceEventsRequest';
import { DeviceEventResponse } from '../models/deviceEventListResponse';
import { DomainEventsRequest } from '../models/domainEventsRequest';
import { SMSSAdvertiser } from '../models/smss/smssAdvertiser';
import { SmssWriteResponse } from '../models/smss/smssWriteResponse';
import { SMSSCampaign } from '../models/smss/smssCampaign';
import { SmssDeleteResponse } from '../models/smss/smssDeleteResponse';
import { Calendar } from '../models/calendar';
import { Location } from '../models/location';
import { CalendarRequest } from '../models/calendarRequest';
import { LocationEvent } from "../models/locationEvent";
import { Theme } from "../models/theme";
import { BacnetEventsRequest } from '../models/bacnet/bacnetEventsRequest';
import { BacnetEventsResponse } from '../models/bacnet/bacnetEventsResponse';
import { PrivacyPolicyResponse } from "../models/privacyPolicyResponse";
import { DeviceByModel, DeviceByModelResponse, DeviceEventByModel } from "../models/deviceEventByModel";
import { DeviceByType, DeviceByTypeResponse, DeviceEventByType } from '../models/deviceEventByType';
import { AddServiceRequest, AddServiceTypeRequest, DeviceGroupServiceRequest, GetServiceDetailResponse, GetServiceResponse, GetServiceTypeResponse, ServiceType, UpdateServiceRequest } from '../models/service/service';
import { ServiceEventItem, ServiceEventResponse, ServiceEvents } from '../models/service/serviceEventResponse';
import { AddAdvertisingEventRequest, AddAdvertisingRequest, AddAdvertisingTypeRequest, GetAdvertisingsEventsRequest, GetAdvertisingsRequest, UpdateAdvertisingRequest, UpdateAdvertisingTypeRequest, GetAdvertisingsEventsDatesRequest } from '../models/advertising/advertisingRequest';
import { AdvertisingDetailResponse, AdvertisingEventsCountResponse, AdvertisingsEventsCountsResponse, GetAdvertisingTypesResponse, GetAdvertisingsEventsDatesResponseItem, GetAdvertisingsResponse } from '../models/advertising/advertisingResponse';
import { AddConsumerRequest, UpdateConsumerRequest, SetConsumerPropertyRequest, ServiceConsumerRequest } from '../models/advertising/consumerRequest';
import { GetConsumersResponse, ConsumerDetailResponse } from '../models/advertising/consumerResponse';
import { Dashboard, DashboardList, DashboardRequest } from '../models/dashboard';
import { Widget, WidgetToHandle } from '../models/widget';
import { EventParsedBody } from '../models/administrator/eventBody';
import { EventBodyUtilityService } from './event-body-utility.service';

interface MyConfig {
  regexPassword: string
}
@Injectable({
  providedIn: 'root'
})
export class ApiService {


  public config: MyConfig = {
    regexPassword: ''
  };

  constructor(
    private http: HttpClient,
    private readonly loaderService: LoaderService,
    private store: Store<MainState>,
    private eventBodyService: EventBodyUtilityService
  ) { }

  // Da vedere se questi tre metodi si possono unire
  public getConfiguration(): Observable<Configuration> {
    return this.http.get<Configuration>(`system.clientsettings.api`)
  }

  /* public setConfiguration(data: Configuration): void {
    this.config.regexPassword = data.Settings["regex_password"];
    console.log(this.config)
  } */

  /* public get retrieveConfiguration() {
    return this.config;
  } */
  // Questo qui sopra è il terzo metodo

  /* public get retrieveRegexPassword() {
    return this.config.regexPassword
  }
 */
  public login(body): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      `system.login.api`,
      body
    ).pipe(
      map((res: any) => res)
    )
  }

  /* public getToken(): Observable<string> {
    return this.http.post<CheckTokenResponse>(`system.token.api`, { isreusable: true }).pipe(
      map(res => res.Text)
    )
  } */

  public getAvailableDomains(): Observable<Domain[]> {
    return this.http.get<GetAvailableDomainsResponse>(`system.domains.api`).pipe(
      map(res => res.Domains)
    )
  }

  public setUserDomain(domain: string): Observable<any> {
    return this.http.post(
      `uv.domain.api`,
      { domain }
    )
  }

  public setUserRoles(domain: string): Observable<UserRoles> {
    return this.http.post<UserRoles>(
      `system.roles.api`,
      { domain }
    )
  }

  public tryLogout(): Observable<any> {
    return this.http.get<any>(`system.logout.api`)
  }

  public findEmailToResetPassword(email: string): Observable<FindEmailResponse> {
    return this.http.get<FindEmailResponse>(`system.setresettoken.api?email=${email}`)
  }

  public checkToken(token: string): Observable<CheckTokenResponse> {
    return this.http.get<CheckTokenResponse>(`system.userresettoken.api?token=${token}`)
  }

  public activateApp(token: string): Observable<any> {
    return this.http.get<any>(`uv.user.activate.api?t=${token}`)
  }

  public resetPassword(newPassword: string, token: string): Observable<ResetPasswordResponse> {
    return this.http.post<ResetPasswordResponse>(
      `system.resetpassword.api`,
      { token, password: newPassword }
    )
  }

  public changePassword(changePasswordRequest: ChangePasswordRequest): Observable<any> {
    return this.http.post<any>(
      'system.password.api',
      {
        username: changePasswordRequest.username,
        old: changePasswordRequest.oldPassword,
        new: changePasswordRequest.newPassword,
        confirm: changePasswordRequest.confirmedNewPassword
      }
    )
  }

  public getUserResources(): Observable<{ resources: Resource[] }> {
    return this.http.get<Resource[]>(`system.resources.api`).pipe(
      map(res => ({ resources: res }))
    )
  }

  public getGoogleMapsKey(): Observable<string> {
    return this.http.get<GoogleMapsKey>(`uv.utility.googlemaps.key.api`).pipe(
      map(res => res.Value)
    );
  }

  public getDashboardList(): Observable<{ dashboards: Dashboard[] }> {
    return this.http.get<DashboardList>(`uv.dashboard.list.api`).pipe(
      map(res => ({ dashboards: res.Dashboards }))
    )
  }

  public createNewDashboard(dashboard: DashboardRequest): Observable<any> {
    return this.http.post<any>(
      `uv.dashboard.create.api`,
      dashboard
    )
  }

  public updateDashboard(dashboard: DashboardRequest): Observable<any> {
    return this.http.post<any>(
      `uv.dashboard.update.api`,
      dashboard
    )
  }

  public deleteDashboard(id: string): Observable<any> {
    return this.http.post<any>(
      `uv.dashboard.delete.api`,
      { dashboardId: id }
    )
  }

  public getWidgetList(): Observable<{ widgets: Widget[] }> {
    return this.http.get<Widget[]>(`uv.widget.list.api`).pipe(
      map(res => ({ widgets: res }))
    )
  }

  public addWidgets(widgetsToAdd: WidgetToHandle[]): Observable<any> {
    return this.http.post<any>(
      `uv.dashboard.add.api`,

      {
        requests:
          widgetsToAdd.map((widget: WidgetToHandle) => {
            return {
              dashboardId: widget.dashboardId,
              widgetId: widget.widgetId,
              position: widget.widgetPosition,
              configuration: widget.widgetConfig,
              column: widget.widgetColumn
            };
          })
      }
    )
  }

  public removeWidget(widgetToAdd: WidgetToHandle): Observable<any> {
    return this.http.post<any>(
      `uv.dashboard.remove.api`,
      {
        dashboardId: widgetToAdd.dashboardId,
        widgetId: widgetToAdd.widgetId
      }
    )
  }

  public getLanguages(): Observable<any> {
    return this.http.get<any>(`system.languages.api`).pipe(
      map((res: any) => res)
    )
  }

  public updateWidgetPosition(newArray: { Positions: { WidgetId: string, Position: number, Column: number }[], DashboardId: string }): Observable<any> {
    const Positions = newArray.Positions.map(x => ({ ...x, Position: x.Position + 1 }));
    const Columns = newArray.Positions.map(x => ({ ...x, Column: x.Column }));
    return this.http.post(
      `uv.widget.positions.api`,
      {
        DashboardId: newArray.DashboardId,
        Positions,
        Columns
      }
    )
  }

  public getDevices(): Observable<Device[]> {
    return this.http.get<GetDevicesResponse>(`uv.device.list.api`).pipe(
      map(res => res.Devices),
    )
  }

  public getDevicesBrands(): Observable<DeviceBrand[]> {
    return this.http.get<GetDevicesBrandsResponse>(`uv.devicebrand.list.api`).pipe(
      map(res => res.Brands)
    )
  }

  public getDevicesModels(): Observable<DeviceModel[]> {
    return this.http.get<GetDevicesModelsResponse>(`uv.devicemodel.list.api`).pipe(
      map(res => res.Models)
    )
  }

  public addDevice(deviceToAdd: DeviceCreateRequest): Observable<any> {
    return this.http.post<any>(
      `uv.device.create.api`,
      deviceToAdd
    )
  }

  public updateDevice(deviceToUpdate: DeviceUpdateRequest): Observable<any> {
    return this.http.post<any>(
      `uv.device.update.api`,
      deviceToUpdate
    )
  }

  public deleteDevice(deviceToDelete: DeviceDeleteRequest): Observable<any> {
    return this.http.post<any>(
      `uv.device.delete.api`,
      deviceToDelete
    )
  }

  public addDeviceProperty(deviceProperty: DevicePropertyRequest): Observable<any> {
    return this.http.post<any>(
      `uv.device.property.api`,
      deviceProperty
    )
  }

  public getDeviceType(): Observable<DeviceType[]> {
    return this.http.get<DeviceTypeListResponse>(`uv.devicetype.list.api`).pipe(
      map(res => res.Types)
    )
  }

  public getDevice(deviceId: string): Observable<Device> {
    return this.http.get<DeviceDetailResponse | any>(`uv.device.detail.api?device=` + deviceId).pipe(
      map(res => res.Device),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getDevicesAroundMe(lat: number, lng: number, range: number = 500, filter = ''): Observable<Device[]> {
    return this.http.post<any>('uv.device.search.api', {
      latitude: lat,
      longitude: lng,
      range,
      filter
    }).pipe(
      map(res => res.Devices)
    )
  }

  public getDeviceModelById(modelId: string): Observable<DeviceModel> {
    return this.http.get<any>(`uv.devicemodel.get.api?id=` + modelId).pipe(
      map(res => res.DeviceModel),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public searchVideos(searchVideoRequest: SearchVideoRequest): Observable<Video[]> {
    return this.http.post<SearchVideoResponse>(
      `uv.video.search.api`,
      searchVideoRequest
    ).pipe(map(res => res.Videos));
  }

  public latestVideos(latestVideoRequest: LatestVideosRequest): Observable<Video[]> {
    return this.http.post<SearchVideoResponse>(
      `uv.video.latest.api`,
      latestVideoRequest
    ).pipe(map(res => res.Videos));
  }

  public getClientSettings(): Observable<any> {
    return this.http.get<ClientSettings>(`system.clientsettings.api`).pipe(
      map(res => res.Settings)
    )
  }

  public getTranslations(language: string): Observable<Translation[]> {
    return this.http.get<GetTranslationsModel>(`system.translations.api?language=` + language).pipe(
      map(res => res.List)
    )
  }

  public addUserProperty(userProperty: UserPropertyRequest): Observable<any> {
    return this.http.post<any>(
      `system.property.api`,
      userProperty
    ).pipe(map(res => res));
  }

  public addSpecificUserProperty(request: SetUserPropertyRequest): Observable<any> {
    return this.http.post<any>(
      `uv.user.property.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public addUser(request: AddUserDomainRequest): Observable<any> {
    return this.http.post<AddUserDomainRequest>(
      `uv.user.add.api`,
      request
    ).pipe(
      map((res: any) => res.Id),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public addAuthorization(request: AddAuthorizationRequest): Observable<any> {
    return this.http.post<AddAuthorizationRequest>(
      `uv.authorization.add.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public removeAuthorization(request: AddAuthorizationRequest): Observable<any> {
    return this.http.post<AddAuthorizationRequest>(
      `uv.authorization.remove.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public deleteUser(userId: string): Observable<any> {
    return this.http.get<any>(
      `uv.user.delete.api?id=${userId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public listUsers(): Observable<User[]> {
    return this.http.get<any>(
      `uv.user.list.api`
    ).pipe(
      map((res: any) => res.Users),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public updateUser(request: UpdateUserRequest): Observable<any> {
    return this.http.post<UpdateUserRequest>(
      `uv.user.update.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getUser(userId: string): Observable<User> {
    return this.http.get<any>(
      `uv.user.get.api?id=${userId}`
    ).pipe(
      map((res: any) => res.User),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public roleList(): Observable<Role[]> {
    return this.http.get<any>(
      `uv.role.list.api`
    ).pipe(
      map((res: any) => res.Roles),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public sendMessage(request: SendMessageRequest): Observable<any> {
    return this.http.post<SendMessageRequest>(
      `uv.message.send.api`,
      request
    ).pipe(
      //map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getTracesList(request: TraceListRequest): Observable<TraceListResponse> {
    return this.http.post<TraceListRequest>(
      `uv.trace.list.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getDomainTracesList(request: TraceListRequest): Observable<TraceListResponse> {
    return this.http.post<TraceListRequest>(
      `uv.trace.list.domain.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getAccessesListAdmin(request: AccessListCountWithDomainRequest): Observable<AccessListResponse> {
    return this.http.post<TraceListRequest>(
      `uv.statistics.access.list.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getAccessesListDomainAdmin(request: AccessListCountRequest): Observable<AccessListResponse> {
    return this.http.post<TraceListRequest>(
      `uv.statistics.access.list.domain.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getAccessesCountAdmin(request: AccessListCountWithDomainRequest): Observable<AccessCountResponse> {
    return this.http.post<TraceListRequest>(
      `uv.statistics.access.count.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getAccessesCountDomainAdmin(request: AccessListCountRequest): Observable<AccessCountResponse> {
    return this.http.post<TraceListRequest>(
      `uv.statistics.access.count.domain.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getPhonePrefixes(): Observable<PhonePrefix[]> {
    return this.http.get<{ Prefixes: PhonePrefix[] }>(
      `uv.utility.prefix.list.api`
    ).pipe(
      map((res: any) => res.Prefixes),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getDomainProperties(domainId: String): Observable<DomainProperty[]> {
    return this.http.get<any>(`uv.domain.property.list.api?id=${domainId}`).pipe(
      map(res => res.Properties)
    )
  }

  public getDomainStyle(): Observable<StyleModel[]> {
    return this.http.get<any>(`uv.domain.style.api`).pipe(
      map(res => res.Styles)
    )
  }

  public getDeviceEvents(request: DeviceEventRequest): Observable<void | DeviceEventsResponse> {
    return this.http.post<DeviceEventsResponse>(
      `uv.device.events.api`,
      request
    ).pipe(
      map((res: DeviceEventsResponse) => {
        let eventsDevices: DeviceEventsResponse = { Devices: [] };
        res.Devices.forEach((device: DeviceEventsDevice) => {
          let parsedBodyDevice: DeviceEventsDevice = device;
          let parsedBodyEvents: DeviceEventsEvent[] = [];
          device.Events.forEach(event => {
            let parsedBodyEvent: DeviceEventsEvent = event;
            parsedBodyEvent.Body = JSON.parse(event.Body);
            parsedBodyEvents.push(parsedBodyEvent);
          });
          parsedBodyDevice.Events = parsedBodyEvents;
          eventsDevices.Devices.push(parsedBodyDevice);
        });
        return eventsDevices;
      }),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getDeviceEventLatest(request: DeviceEventLatestRequest): Observable<void | DeviceEventLatestResponse> {
    return this.http.post<DeviceEventLatestResponse>(
      `uv.device.event.latest.api`,
      request
    ).pipe(
      map((res: DeviceEventLatestResponse) => {
        let eventsDevices: DeviceEventLatestResponse = { Devices: [] };
        res.Devices.forEach((device: EventsDeviceLatest) => {
          let parsedBodyDevice: EventsDeviceLatest = device;
          let parsedBodyEvents: EventLatest[] = [];
          device.Events.forEach(event => {
            let parsedBodyEvent: EventLatest = event;
            parsedBodyEvent.Body = JSON.parse(event.Body);
            parsedBodyEvents.push(parsedBodyEvent);
          });
          parsedBodyDevice.Events = parsedBodyEvents;
          eventsDevices.Devices.push(parsedBodyDevice);
        });
        return eventsDevices;
      }),
      catchError(err => {
        this.loaderService.hide();
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getDeviceEventLatest24Hours(request: DeviceEventLatestRequest): Observable<void | DeviceEventLatestResponse> {
    return this.http.post<DeviceEventLatestResponse>(
      `uv.device.event.last24hours.api`,
      request
    ).pipe(
      map((res: DeviceEventLatestResponse) => {
        let eventsDevices: DeviceEventLatestResponse = { Devices: [] };
        res.Devices.forEach((device: EventsDeviceLatest) => {
          let parsedBodyDevice: EventsDeviceLatest = device;
          let parsedBodyEvents: EventLatest[] = [];
          device.Events.forEach(event => {
            let parsedBodyEvent: EventLatest = event;
            parsedBodyEvent.Body = JSON.parse(event.Body);
            parsedBodyEvents.push(parsedBodyEvent);
          });
          parsedBodyDevice.Events = parsedBodyEvents;
          eventsDevices.Devices.push(parsedBodyDevice);
        });
        return eventsDevices;
      }),
      catchError(err => {
        this.loaderService.hide();
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getDeviceByEventsException(): Observable<DeviceEventLatestResponse> {
    return this.http.get('uv.device.list.byeventsexception.api').pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public addRemoveLanguage(request: AddRemoveLanguageRequest): Observable<any> {
    return this.http.post(
      `uv.language.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public checkLanguageDifferences(request: CheckLangDiffRequest): Observable<Phrase[]> {
    return this.http.post(
      `uv.language.compare.api`,
      request
    ).pipe(
      map((res: any) => res.Phrases),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getDeviceAliases(deviceId: string): Observable<DeviceAlias[]> {
    return this.http.get<any>(`uv.device.alias.list.api?id=${deviceId}`).pipe(
      map(res => res.Aliases),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public addDeviceAlias(request: DeviceAlias): Observable<any> {
    return this.http.post<DeviceAlias>(
      `uv.device.alias.set.api`,
      request
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public deleteDeviceAlias(request: DeviceAlias): Observable<any> {
    return this.http.post<DeviceAlias>(
      `uv.device.alias.delete.api`,
      request
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public deviceEventsList(request: DeviceEventsRequest): Observable<DeviceEventResponse> {
    return this.http.post(
      `uv.device.event.list.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public domainEvents(request: DomainEventsRequest): Observable<DeviceEventResponse> {
    return this.http.post(
      `uv.domain.events.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public domainEventsLatest24Hours(): Observable<DeviceEventResponse> {
    return this.http.get('uv.domain.events.last24hours.api').pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public bacnetEventsList(request: BacnetEventsRequest): Observable<BacnetEventsResponse> {
    return this.http.post(
      `uv.integration.bacnetmonitor.events.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public advertisers(resellerId: string): Observable<SMSSAdvertiser[]> {
    return this.http.get<any>(`int.smss.advertiser.find.api?resellerId=${resellerId}`).pipe(
      map(res => res.Advertisers),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public advertiserWrite(request: SMSSAdvertiser): Observable<SmssWriteResponse> {
    return this.http.post(
      `int.smss.advertiser.write.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public advertiserDelete(advertiserId: string): Observable<SmssDeleteResponse> {
    return this.http.get(
      `int.smss.advertiser.delete.api?id=${advertiserId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public advertiserRead(advertiserId: string): Observable<SMSSAdvertiser> {
    return this.http.get(
      `int.smss.advertiser.read.api?id=${advertiserId}`
    ).pipe(
      map((res: any) => res.Advertiser),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public campaigns(advertiserId: string): Observable<SMSSCampaign[]> {
    return this.http.get<any>(`int.smss.campaign.find.api?advertiserId=${advertiserId}`).pipe(
      map(res => res.Campaigns),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public campaignWrite(request: SMSSCampaign): Observable<SmssWriteResponse> {
    return this.http.post(
      `int.smss.campaign.write.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public campaignDelete(campaignId: string): Observable<SmssDeleteResponse> {
    return this.http.get(
      `int.smss.campaign.delete.api?id=${campaignId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public campaignRead(campaignId: string): Observable<SMSSCampaign> {
    return this.http.get(
      `int.smss.campaign.read.api?id=${campaignId}`
    ).pipe(
      map((res: any) => res.Campaign),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public locations(): Observable<Location[]> {
    return this.http.get<any>(`uv.location.list.api`).pipe(
      map(res => res.Locations),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public locationAdd(request: Location): Observable<any> {
    return this.http.post(
      `uv.location.add.api`,
      request
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public locationUpdate(request: Location): Observable<any> {
    return this.http.post(
      `uv.location.update.api`,
      request
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public locationDelete(locationId: string): Observable<any> {
    return this.http.get(
      `uv.location.delete.api?id=${locationId}`
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public locationGet(locationId: string): Observable<Location> {
    return this.http.get<any>(
      `uv.location.get.api?id=${locationId}`
    ).pipe(
      map(res => res.Location),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public calendars(): Observable<Calendar[]> {
    return this.http.get<any>(`uv.calendar.list.api`).pipe(
      map(res => res.Calendars),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public calendarAdd(request: CalendarRequest): Observable<any> {
    return this.http.post(
      `uv.calendar.add.api`,
      request
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public calendarUpdate(request: CalendarRequest): Observable<any> {
    return this.http.post(
      `uv.calendar.update.api`,
      request
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public calendarDelete(calendarId: string): Observable<any> {
    return this.http.get(
      `uv.calendar.delete.api?id=${calendarId}`
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getDangerRateDomain(request: DangerRateDomainRequest): Observable<DangerRateEvent> {
    return this.http.post(
      `int.opendata.danger.rate.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getMostDangerousEvents(request: MostDangerousEventsRequest): Observable<MostDangerousEvent[]> {
    return this.http.post(
      `int.opendata.danger.rate.list.api`,
      request
    ).pipe(
      map((res: any) => res.Rates),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getDevicesByType(typeName: string): Observable<Device[]> {
    return this.http.get<GetDevicesResponse>(`uv.device.list.type.api?name=${typeName}`).pipe(
      map((res: any) => res.Devices),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getUserInfo(): Observable<User> {
    return this.http.get<User>(`uv.user.info.api`)
  }

  public getDomainsAsAdmin(): Observable<Domain[]> {
    return this.http.get<Domain[]>(`uv.domain.list.admin.api`).pipe(
      map((res: any) => res.Domains),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public deviceChangeDomain(deviceId: string, domainId: string): Observable<any> {
    return this.http.post(
      `uv.device.domain.api`,
      {
        "device": deviceId,
        "domain": domainId
      }
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public confirmDeleteUser(token: string): Observable<any> {
    return this.http.get<any>(`uv.user.delete.confirm.api?t=${token}`)
  }

  public locationEvents(locationId: string): Observable<LocationEvent[]> {
    return this.http.get<any>(`uv.location.event.list.api?l=${locationId}`).pipe(
      map(res => res.LocationEvents),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public locationEventGet(locationEventId: string): Observable<LocationEvent> {
    return this.http.get<any>(
      `uv.location.event.get.api?id=${locationEventId}`
    ).pipe(
      map(res => res.LocationEvent),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getTheme(hostname: string): Observable<Theme> {
    return this.http.get(`uv.theme.get.api?hostname=${hostname}`).pipe(
      map((res: any) => res.Theme),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getPrivacyPolicy(language: string, target : string): Observable<PrivacyPolicyResponse> {
    return this.http.get(`uv.utility.privacy.mobile.api?l=${language}&t=${target}`).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getDeviceEventLatest24HoursByModel(modelName: string): Observable<void | DeviceByModelResponse> {
    return this.http.get<DeviceByModelResponse>(
      `uv.device.event.last24hours.model.api?m=${modelName}`
    ).pipe(
      map((res: DeviceByModelResponse) => {
        let eventsDevices: DeviceByModelResponse = { Items: [] };
        res.Items.forEach((device: DeviceByModel) => {
          let parsedBodyDevice: DeviceByModel = device;
          let parsedBodyEvents: DeviceEventByModel[] = [];
          device.Events.forEach(event => {
            let parsedBodyEvent: DeviceEventByModel = event;
            parsedBodyEvent.Body = JSON.parse(event.Body);
            parsedBodyEvents.push(parsedBodyEvent);
          });
          parsedBodyDevice.Events = parsedBodyEvents;
          eventsDevices.Items.push(parsedBodyDevice);
        });
        return eventsDevices;
      }),
      catchError(err => {
        this.loaderService.hide();
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getDeviceEventsByModelAndDatetime(modelName: string, start: number, end: number): Observable<void | DeviceByModelResponse> {
    return this.http.get<DeviceByModelResponse>(
      `uv.device.events.model.api?m=${modelName}&s=${start}&e=${end}`
    ).pipe(
      map((res: DeviceByModelResponse) => {
        let eventsDevices: DeviceByModelResponse = { Items: [] };
        res.Items.forEach((device: DeviceByModel) => {
          let parsedBodyDevice: DeviceByModel = device;
          let parsedBodyEvents: DeviceEventByModel[] = [];
          device.Events.forEach(event => {
            let parsedBodyEvent: DeviceEventByModel = event;
            parsedBodyEvent.Body = JSON.parse(event.Body);
            parsedBodyEvents.push(parsedBodyEvent);
          });
          parsedBodyDevice.Events = parsedBodyEvents;
          eventsDevices.Items.push(parsedBodyDevice);
        });
        return eventsDevices;
      }),
      catchError(err => {
        this.loaderService.hide();
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getDeviceEventLatest24HoursByTypes(types: string[]): Observable<void | DeviceByTypeResponse> {
    return this.http.get<any>(`uv.device.event.last24hours.types.api?t=${types}`).pipe(
      map((res: DeviceByTypeResponse) => {
        let eventsDevices: DeviceByTypeResponse = { Items: [] };
        res.Items.forEach((device: DeviceByType) => {
          let parsedBodyDevice: DeviceByType = device;
          let parsedBodyEvents: DeviceEventByType[] = [];
          device.Events.forEach(event => {
            let parsedBodyEvent: DeviceEventByType = event;
            parsedBodyEvent.Body = JSON.parse(event.Body);
            parsedBodyEvents.push(parsedBodyEvent);
          });
          parsedBodyDevice.Events = parsedBodyEvents;
          eventsDevices.Items.push(parsedBodyDevice);
        });
        return eventsDevices;
      }),
      catchError(err => {
        this.loaderService.hide();
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getDeviceEventLatest24HoursByModels(models: string[]): Observable<void | DeviceByModelResponse> {
    return this.http.get<any>(`uv.device.event.last24hours.models.api?m=${models}`).pipe(
      map((res: DeviceByModelResponse) => {
        let eventsDevices: DeviceByModelResponse = { Items: [] };
        res.Items.forEach((device: DeviceByModel) => {
          let parsedBodyDevice: DeviceByModel = device;
          let parsedBodyEvents: DeviceEventByModel[] = [];
          device.Events.forEach(event => {
            let parsedBodyEvent: DeviceEventByModel = event;
            parsedBodyEvent.Body = JSON.parse(event.Body);
            parsedBodyEvents.push(parsedBodyEvent);
          });
          parsedBodyDevice.Events = parsedBodyEvents;
          eventsDevices.Items.push(parsedBodyDevice);
        });
        return eventsDevices;
      }),
      catchError(err => {
        this.loaderService.hide();
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public addServiceType(request: AddServiceTypeRequest): Observable<void> {
    return this.http.post<any>(
      `uv.service.type.add.api`,
      {
        "name": request.Name,
        "icon": request.Icon,
        "color": request.Color
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public updateServiceType(request: ServiceType): Observable<void> {
    return this.http.post<any>(
      `uv.service.type.update.api`,
      {
        "id": request.Id,
        "name": request.Name,
        "icon": request.Icon,
        "color": request.Color
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public deleteServiceType(id: number): Observable<void> {
    return this.http.get<any>(`uv.service.type.delete.api?id=${id}`).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getServiceTypes(): Observable<void | GetServiceTypeResponse> {
    return this.http.get<any>(`uv.service.type.list.api`).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public addService(request: AddServiceRequest): Observable<void> {
    return this.http.post<any>(
      `uv.service.add.api`,
      {
        "name": request.Name,
        "description": request.Description,
        "serviceType": request.Type,
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public updateService(request: UpdateServiceRequest): Observable<void> {
    return this.http.post<any>(
      `uv.service.update.api`,
      {
        "id": request.Id,
        "name": request.Name,
        "description": request.Description,
        "serviceType": request.TypeId
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public deleteService(id: string): Observable<void> {
    return this.http.get<any>(`uv.service.delete.api?id=${id}`).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getServices(serviceTypeId?: number): Observable<void | GetServiceResponse> {
    return this.http.get<any>(`uv.service.list.api?st=${serviceTypeId}`).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public serviceDetail(id: string): Observable<void | GetServiceDetailResponse> {
    return this.http.get<any>(`uv.service.get.api?id=${id}`).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public addDeviceGroupToService(request: DeviceGroupServiceRequest): Observable<void> {
    return this.http.post<any>(
      `uv.service.group.set.api`,
      {
        "service": request.ServiceId,
        "group": request.GroupId,
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public removeDeviceGroupFromService(request: DeviceGroupServiceRequest): Observable<void> {
    return this.http.post<any>(
      `uv.service.group.remove.api`,
      {
        "service": request.ServiceId,
        "group": request.GroupId,
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getDeviceEventLatest24hoursByServicesName(services: string[]): Observable<void | ServiceEventResponse> {
    return this.http.get<any>(`uv.device.event.last24hours.services.api?s=${services}`).pipe(
      map((res: ServiceEventResponse) => {
        let eventsDevices: ServiceEventResponse = { Items: [] };
        res.Items.forEach((device: ServiceEventItem) => {
          let parsedBodyDevice: ServiceEventItem = device;
          let parsedBodyEvents: ServiceEvents[] = [];
          device.Events.forEach(event => {
            let parsedBodyEvent: ServiceEvents = event;
            parsedBodyEvent.Body = JSON.parse(event.Body);
            parsedBodyEvents.push(parsedBodyEvent);
          });
          parsedBodyDevice.Events = parsedBodyEvents;
          eventsDevices.Items.push(parsedBodyDevice);
        });
        return eventsDevices;
      }),
      catchError(err => {
        this.loaderService.hide();
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getDeviceEventLatest24hoursByServicesTypeName(types: string[]): Observable<void | ServiceEventResponse> {
    return this.http.get<any>(`uv.device.event.last24hours.service.types.api?st=${types}`).pipe(
      map((res: ServiceEventResponse) => {
        let eventsDevices: ServiceEventResponse = { Items: [] };
        res.Items.forEach((device: ServiceEventItem) => {
          let parsedBodyDevice: ServiceEventItem = device;
          let parsedBodyEvents: ServiceEvents[] = [];
          device.Events.forEach(event => {
            let parsedBodyEvent: ServiceEvents = event;
            parsedBodyEvent.Body = JSON.parse(event.Body);
            parsedBodyEvents.push(parsedBodyEvent);
          });
          parsedBodyDevice.Events = parsedBodyEvents;
          eventsDevices.Items.push(parsedBodyDevice);
        });
        return eventsDevices;
      }),
      catchError(err => {
        this.loaderService.hide();
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getDeviceEventLatest24HoursInfoGuaranteed(request: DeviceEventLatestRequest): Subject<DeviceEventLatestResponse> {
    let eventsSubject: Subject<DeviceEventLatestResponse> = new Subject<DeviceEventLatestResponse>();
    let oldNotInfoEvents: DeviceEventLatestResponse;

    this.getDeviceEventLatest24Hours(request).pipe(takeWhile(() => !eventsSubject.isStopped))
    .subscribe((res: DeviceEventLatestResponse) => {
      if (res?.Devices?.length > 0) {
        let eventBodies: Record<string, EventParsedBody> = this.eventBodyService.initEventBody(res.Devices);
        if (res.Devices.every(device => device.Events.find(event => ['Info', 'Debug'].includes(event.Level)) !== undefined)) {
          eventsSubject.next(res);
          eventsSubject.complete();
        }
        else {
          res.Devices.forEach(device => {
            device.Events?.filter(event => event.Level !== 'Info' || !this.eventBodyService.isAProperEvent(event, device.Model, eventBodies))
              .forEach(event => {
              if (oldNotInfoEvents === undefined) {
                oldNotInfoEvents = { Devices: [] };
              }
               let deviceIndex: number = oldNotInfoEvents.Devices.findIndex(eventDevice => eventDevice.Id === device.Id);
               if (deviceIndex === -1) {
                let eventDevice: EventsDeviceLatest = {
                  ...device,
                  Events: []
                };
                oldNotInfoEvents.Devices.push(eventDevice);
                deviceIndex = oldNotInfoEvents.Devices.length - 1;
              }
              oldNotInfoEvents.Devices[deviceIndex].Events.push(event);
            });
          });

          request.Level = 'Info';
          this.getDeviceEventLatest24Hours(request)
          .pipe(takeWhile(() => !eventsSubject.isStopped)).subscribe((res: DeviceEventLatestResponse) => {
            if (oldNotInfoEvents?.Devices?.length > 0) {
              res.LatestBadEvents = oldNotInfoEvents;
            }
            eventsSubject.next(res);
            eventsSubject.complete();
          });
        }
      }
      else {
        eventsSubject.next(null);
        eventsSubject.complete();
      }
    });

    return eventsSubject;
  }

  public addAdvertisingType(request: AddAdvertisingTypeRequest): Observable<void> {
    return this.http.post<any>(
      `uv.advertising.type.add.api`,
      {
        "name": request.Name,
        "attachmentType": request.AttachmentType
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public updateAdvertisingType(request: UpdateAdvertisingTypeRequest): Observable<void> {
    return this.http.post<any>(
      `uv.advertising.type.update.api`,
      {
        "id": request.Id,
        "name": request.Name,
        "attachmentType": request.AttachmentType
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public deleteAdvertisingType(id: number): Observable<void> {
    return this.http.get<any>(`uv.advertising.type.delete.api?id=${id}`).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getAdvertisingTypes(): Observable<void | GetAdvertisingTypesResponse> {
    return this.http.get<any>(`uv.advertising.type.list.api?`).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public addConsumer(request: AddConsumerRequest): Observable<void> {
    return this.http.post<any>(
      `uv.consumer.add.api`,
      {
        "name": request.Name,
        "description": request.Description
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public updateConsumer(request: UpdateConsumerRequest): Observable<void> {
    return this.http.post<any>(
      `uv.consumer.update.api`,
      {
        "id": request.Id,
        "name": request.Name,
        "description": request.Description
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public deleteConsumer(id: string): Observable<void> {
    return this.http.get<any>(`uv.consumer.delete.api?id=${id}`).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getConsumers(serviceId?: string): Observable<void | GetConsumersResponse> {
    return this.http.get<any>(`uv.consumer.list.api` + (serviceId !== undefined ? `?sid=${serviceId}` : '')).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public consumerDetail(id: string): Observable<void | ConsumerDetailResponse> {
    return this.http.get<any>(`uv.consumer.get.api?id=${id}`).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public setConsumerProperty(request: SetConsumerPropertyRequest): Observable<void> {
    return this.http.post<any>(
      `uv.consumer.property.api`,
      {
        "consumer": request.Consumer,
        "key": request.Key,
        "value": request.Value
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public addServiceConsumer(request: ServiceConsumerRequest): Observable<void> {
    return this.http.post<any>(
      `uv.service.consumer.add.api`,
      {
        "service": request.Service,
        "consumer": request.Consumer
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public deleteServiceConsumer(request: ServiceConsumerRequest): Observable<void> {
    return this.http.post<any>(
      `uv.service.consumer.delete.api`,
      {
        "service": request.Service,
        "consumer": request.Consumer
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public addAdvertising(request: AddAdvertisingRequest): Observable<void> {
    return this.http.post<any>(
      `uv.advertising.add.api`,
      {
        "name": request.Name,
        "type": request.Type,
        "message": request.Message,
        "image": request.Images,
        "startDate": request.StartDate,
        "endDate": request.EndDate,
        "startTime": `${request.StartTime.hours}:${request.StartTime.minutes}:00.000`,
        "endTime": `${request.EndTime.hours}:${request.EndTime.minutes}:00.000`,
        "service": request.Service
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public updateAdvertising(request: UpdateAdvertisingRequest): Observable<void> {
    return this.http.post<any>(
      `uv.advertising.update.api`,
      {
        "id": request.Id,
        "name": request.Name,
        "type": request.Type,
        "message": request.Message,
        "image": request.Images,
        "startDate": request.StartDate,
        "endDate": request.EndDate,
        "startTime": `${request.StartTime.hours}:${request.StartTime.minutes}:00.000`,
        "endTime": `${request.EndTime.hours}:${request.EndTime.minutes}:00.000`,
        "service": request.Service
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public deleteAdvertising(id: string): Observable<void> {
    return this.http.get<any>(`uv.advertising.delete.api?id=${id}`).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getAdvertisings(request?: GetAdvertisingsRequest): Observable<void | GetAdvertisingsResponse> {
    if (request === undefined) {
      request = {};
    }
    if (request.End === undefined) {
      let endDate: Date = new Date();
      let startDate: Date = new Date(endDate);
      startDate.setDate(startDate.getDate() - 1);
      request.Start = Math.round(startDate.getTime() / 1000);
      endDate.setMinutes(endDate.getMinutes() + 1);
      request.End = Math.round(endDate.getTime() / 1000);
    }
    return this.http.post<any>(
      `uv.advertising.list.api?`,
      {
        "page": request?.Page,
        "limit": request?.Limit ? request.Limit : 10,
        "filter": request?.Filter,
        "order": request?.Order ? request.Order : 'Created',
        "direction": request?.Direction? request.Direction : 'DESC',
        "start": request?.Start,
        "end": request?.End,
        "service": request?.Service
      }
    ).pipe(
      map((res: any) => {
        return {
          Total: res.Total,
          Items: res.Items.map((item: any) => {
            let startTimeParts: string[] = item.StartTime.split(':');
            let endTimeParts: string[] = item.EndTime.split(':');

            return {
              ...item,
              StartTime: {
                hours: startTimeParts[0],
                minutes: startTimeParts[1]
              },
              EndTime: {
                hours: endTimeParts[0],
                minutes: endTimeParts[1]
              }
            };
          })
        };
      }),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public advertisingDetail(id: string): Observable<void | AdvertisingDetailResponse> {
    return this.http.get<any>(`uv.advertising.get.api?id=${id}`).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public addAdvertisingEvent(request: AddAdvertisingEventRequest): Observable<void> {
    return this.http.post<any>(
      `uv.advertising.event.add.api`,
      {
        "advertising": request.Advertising
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getAdvertisingsEvents(request?: GetAdvertisingsEventsRequest): Observable<void | any> {
    return this.http.post<any>(
      `uv.advertising.event.list.api?`,
      {
        "page": request?.Page,
        "limit": request?.Limit ? request.Limit : 10,
        "filter": request?.Filter,
        "order": request?.Order ? request.Order : 'Created',
        "direction": request?.Direction? request.Direction : 'DESC',
        "start": request?.Start,
        "end": request?.End,
        "domain": request?.Domain,
        "user": request?.User
      }
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getAdvertisingEventsDates(request?: GetAdvertisingsEventsDatesRequest): Observable<GetAdvertisingsEventsDatesResponseItem[]> {
    return this.http.get<any>(`uv.advertising.events.list.dates.api?id=${request?.Id}&s=${request?.Dates?.Start}&${request?.Dates?.End}`).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getAdvertisingEventsCount(id?: string): Observable<void | AdvertisingEventsCountResponse> {
    return this.http.get<any>(`uv.advertising.event.count.api?id=${id}`).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }

  public getAdvertisingsEventsCounts(): Observable<void | AdvertisingsEventsCountsResponse> {
    return this.http.get<any>('uv.advertising.events.count.api').pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    );
  }
}
