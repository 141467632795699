<div class="dialogContainer">
    <h3 class="title3 mb-3">{{'DEVICE_EVENTS.TITLE' | translate }}</h3>

    <form [formGroup]="detailGroup" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'GENERAL.ID' | translate }}</mat-label>
            <input matInput formControlName="id" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3" *ngIf="detailGroup.controls.deviceId.value">
            <mat-label>{{'GENERAL.DEVICE_ID' | translate }}</mat-label>
            <input matInput formControlName="deviceId" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3" *ngIf="detailGroup.controls.deviceName.value">
            <mat-label>{{'GENERAL.DEVICE_NAME' | translate }}</mat-label>
            <input matInput formControlName="deviceName" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'DEVICE_EVENTS.TYPE' | translate }}</mat-label>
            <input matInput formControlName="type" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'DEVICE_EVENTS.LEVEL' | translate }}</mat-label>
            <input matInput formControlName="level" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'GENERAL.CREATED' | translate }}</mat-label>
            <input matInput formControlName="created" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customTextArea">
            <mat-label>{{ 'DEVICE_EVENTS.BODY' | translate }}</mat-label>
            <textarea   matInput formControlName="body"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="10"
                        readonly>
            </textarea>
        </mat-form-field>
    </form>

    <button mat-button class="btn outlineActionButton" [mat-dialog-close]="true">{{'GENERAL.CLOSE' | translate }}</button>
</div>