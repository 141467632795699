<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="isAdd">{{ 'TASK_SCHEDULE.ADD' | translate }}</h3>
    <h3 class="title3 mb-3" *ngIf="!isAdd">{{ 'TASK_SCHEDULE.UPDATE' | translate }} - {{ updateData.targetTaskSchedule.Id }}</h3>
    
    <form *ngIf="isAdd" class="mb-3">
        <mat-form-field class="tableSearch mt-2" appearance="outline">
            <div matPrefix class="prefix">
                <i class="urbanicon-search"></i>
            </div>
            <mat-label>{{ 'DEVICE.SEARCH' | translate }}</mat-label>
            <input matInput [formControl]="selectedDeviceControl" [matAutocomplete]="auto" required autofocus="false">
            <button matSuffix mat-icon-button type="submit" *ngIf="selectedDeviceControl.value !== null && selectedDeviceControl.value !== ''" (click)="clearSelection()" matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                <i class="urbanicon-close"></i>
            </button>

            <mat-autocomplete class="overlayBG" #auto="matAutocomplete" [displayWith]="displayFn"
                (optionSelected)='deviceSelected($event.option.value)'>
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    <span class="urbanicon-{{ option.Model.Type?.Icon }}"></span>
                    {{option.Name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>

    <form [formGroup]="daysForm" class="mb-3">
        <p class="paragraphText">{{ 'TASK_SCHEDULE.SELECT_DATE' | translate }}</p>

        <div class="d-flex flex-column mb-3">
            <mat-checkbox formControlName="mondayCheck"> 
                <p class="paragraphText mb-0 mt-2">{{ 'GENERAL.MONDAY' | translate}}</p>
            </mat-checkbox>

            <mat-checkbox formControlName="tuesdayCheck">
                <p class="paragraphText mb-0 mt-2">{{ 'GENERAL.TUESDAY' | translate}}</p>
            </mat-checkbox>

            <mat-checkbox formControlName="wednesdayCheck">
                <p class="paragraphText mb-0 mt-2">{{ 'GENERAL.WEDNESDAY' | translate}}</p>
            </mat-checkbox>

            <mat-checkbox formControlName="thursdayCheck">
                <p class="paragraphText mb-0 mt-2">{{ 'GENERAL.THURSDAY' | translate}}</p>
            </mat-checkbox>

            <mat-checkbox formControlName="fridayCheck">
                <p class="paragraphText mb-0 mt-2">{{ 'GENERAL.FRIDAY' | translate}}</p>
            </mat-checkbox>

            <mat-checkbox formControlName="saturdayCheck">
                <p class="paragraphText mb-0 mt-2">{{ 'GENERAL.SATURDAY' | translate}}</p>
            </mat-checkbox>
            
            <mat-checkbox formControlName="sundayCheck">
                <p class="paragraphText mb-0 mt-2">{{ 'GENERAL.SUNDAY' | translate}}</p>
            </mat-checkbox>
        </div>

        <mat-form-field appearance="outline" class="customTextArea mb-3">
            <mat-label>{{ 'TASK_SCHEDULE.JSON' | translate }}</mat-label>
            <textarea   matInput [formControl]="jsonControl"                       
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="10"
                        required>
            </textarea>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ error | translate }}
        </mat-error>
    </form>

    <div class="mb-4">
        <p class="paragraphText">{{ 'TASK_SCHEDULE.SELECT_HOUR' | translate }}</p>
        <input type="time" [(ngModel)]="hourSelected" [owlDateTimeTrigger]="timePicker" [owlDateTime]="timePicker" placeholder="{{ 'GENERAL.TIME' | translate }}" readonly>
        <owl-date-time [pickerType]="'timer'" #timePicker></owl-date-time>
    </div>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="this.isAdd" (click)="addition()">{{ 'GENERAL.ADD' | translate }}</button>
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="!this.isAdd" (click)="update()">{{ 'GENERAL.UPDATE' | translate }}</button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>
