import { Component, Input, ViewChild, ElementRef, SimpleChanges, AfterViewInit, OnDestroy, OnChanges } from '@angular/core';
import { ChartDataList, RadarChartData } from '../../models/ChartDataList';
import { Subject } from 'rxjs';
import { Chart, Filler, LineElement, PointElement, RadarController, RadialLinearScale, Tooltip } from 'chart.js';

@Component({
  selector: 'urban-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss']
})
export class RadarChartComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input('radarAreaChartTitle') public chartTitle: string = '';
  @Input('passedData') private passedData: ChartDataList;
  @Input('dataUnit') private dataUnit: string;
  @Input('dataDescriptions') private dataDescriptions: string[];
  @Input('passedSubtitle') public chartSubtitle: string = '';
  @Input('darkModeStatus') public isDarkActive: boolean;
  @ViewChild('radarAreaChartCanvas') chartCanvas: ElementRef;

  private chart: Chart;
  public noDataOnChart: boolean = false;
  private colors: string[] = [];
  public validKeys: string[] = ['isDarkActive', 'passedData', 'dataUnit', 'dataDescriptions'];
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor() {
    Chart.register(RadarController, RadialLinearScale, PointElement, LineElement, Tooltip, Filler);
    this.colors = this.getChartColors();
  }

  ngAfterViewInit(): void {
    this.drawChart();
  }

  private drawChart(): void {
    this.clearChart();

    if (this.passedData && this.dataDescriptions?.length > 0) {
      this.noDataOnChart = false;

      let chartData: RadarChartData = {
        labels: this.dataDescriptions,
        datasets: [
          {
            label: this.dataUnit,
            data: Object.values(this.passedData),
            fill: true,
            backgroundColor: this.colors[0] ? this.colors[0] + '33' : '',
            borderColor: this.colors[0] ?? '',
            pointBackgroundColor: this.colors[0] ?? '',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: this.colors[0] ?? ''
          }
        ]
      };

      var canvas: HTMLCanvasElement = this.chartCanvas.nativeElement;
      var context: CanvasRenderingContext2D = canvas.getContext("2d");

      this.chart = new Chart(context, {
        type: 'radar',
        data: chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            line: {
              borderWidth: 2
            }
          },
          scales: {
            r: {
              grid: {
                 color: window.getComputedStyle(document.body).getPropertyValue('--custom-light-accent-text-color')
              },
              ticks: {
                color: this.isDarkActive ? window.getComputedStyle(document.body).getPropertyValue('--custom-light-primary-text-color') : 'black',
                backdropColor: 'transparent'
              },
              pointLabels:{
                color: this.isDarkActive ? window.getComputedStyle(document.body).getPropertyValue('--custom-light-primary-text-color') : 'black',
              },
            },
          },
        }
      });
    } else {
      this.noDataOnChart = true;
    }
  }

  private getChartColors(): string[] {
    let colors: string[] = [];

    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-light-warn-main-color'));
    
    return colors;
  }

  private clearChart(): void {
    if(this.chart) {
      this.chart.destroy();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const key in changes) {
      if (!this.validKeys.includes(key)) {
        continue;
      }
    
      if (changes[key]) {
        const change = changes[key];

        if (change.currentValue !== change.previousValue && !change.firstChange) {
          this.drawChart();
          break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.clearChart();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
