<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }}</h3>
    <h3 class="title3 mb-3" *ngIf="!this.isAdd">{{ 'GENERAL.DETAIL' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'CALENDARS.SINGLE_TITLE' | translate }}</mat-label>
            <input matInput formControlName="title" autocomplete="off"
                required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'CALENDARS.LOCATION' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" formControlName="location">
                <mat-option *ngFor="let location of locations" value={{location.Id}}>
                    {{location.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="d-flex">
            <mat-form-field appearance="outline" class="customDatePicker mb-3 mr-1">
                <mat-label>{{'GENERAL.START_DATE' | translate }}</mat-label>
                <input matInput [owlDateTime]="dt1" formControlName="start" [owlDateTimeTrigger]="dt1" readonly>
    
                <i [owlDateTimeTrigger]="dt1" matSuffix class="urbanicon-monitoring-outline pointer"></i>
    
                <owl-date-time #dt1></owl-date-time>
            </mat-form-field>
    
            <mat-form-field appearance="outline" class="customDatePicker mb-3 ml-1">
                <mat-label>{{'GENERAL.END_DATE' | translate }}</mat-label>
                <input matInput [owlDateTime]="dt2" formControlName="end" [owlDateTimeTrigger]="dt2" readonly>
    
                <i [owlDateTimeTrigger]="dt2" matSuffix class="urbanicon-monitoring-outline pointer"></i>
    
                <owl-date-time #dt2></owl-date-time>
            </mat-form-field>
        </div>

        <div class="d-flex align-items-center mb-3">
            <mat-form-field appearance="outline" class="customMatFormField mr-2">
                <mat-label>{{ 'CALENDARS.PRIORITY' | translate }}</mat-label>
                <mat-select hideSingleSelectionIndicator="true" formControlName="priority" [(value)]="currentColor">
                    <mat-option *ngFor="let priority of priorities" value={{priority.Value}}>
                        {{ 'CALENDARS.PRIORITY_' + priority.Key | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
    
            <div class="cerchio" [style.background]="'#' + currentColor">
                <input #colorPicker type="color" [value]="'#' + currentColor"
                    (change)='updateColor(colorPicker.value)' disabled>
            </div>
        </div>

        <mat-form-field appearance="outline" class="customTextArea mb-3">
            <mat-label>{{ 'CALENDARS.TEXT' | translate }}</mat-label>
            <textarea   matInput formControlName="text"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="5"
                        cdkAutosizeMaxRows="10" 
                autocomplete="off"></textarea>
        </mat-form-field>

        <mat-error class="pb-2" *ngIf="error">
            {{ error | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="addition()">
            {{ isAdd ? ('GENERAL.ADD' | translate) : ('GENERAL.UPDATE' | translate) }}
        </button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'GENERAL.CLOSE' | translate }}</button>
    </div>
</div>