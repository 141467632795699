<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }} {{ 'GENERAL.LOCATION' | translate }}</h3>
    <h3 class="title3 mb-3" *ngIf="!this.isAdd">{{ 'GENERAL.DETAIL' | translate }} {{ 'GENERAL.LOCATION' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'LOCATIONS.ADDRESS' | translate }}</mat-label>
            <input matInput formControlName="address" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'LOCATIONS.ZIP' | translate }}</mat-label>
            <input matInput formControlName="zip" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'LOCATIONS.CITY' | translate }}</mat-label>
            <input matInput formControlName="city" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'LOCATIONS.STATE' | translate }}</mat-label>
            <input matInput formControlName="state" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'LOCATIONS.COUNTRY' | translate }}</mat-label>
            <input matInput formControlName="country" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'LOCATIONS.LATITUDE' | translate }}</mat-label>
            <input matInput formControlName="latitude" type="number" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'LOCATIONS.LONGITUDE' | translate }}</mat-label>
            <input matInput formControlName="longitude" type="number" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customTextArea mb-3">
            <mat-label>{{ 'LOCATIONS.DESCRIPTION' | translate }}</mat-label>
            <textarea   matInput formControlName="description"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="3"
                        cdkAutosizeMaxRows="3" 
                        autocomplete="off">
            </textarea>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.ERROR_EMPTY' | translate }}
        </mat-error>
    </form>
    
    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="this.isAdd" (click)="addition()">{{ 'GENERAL.ADD' | translate }}</button>
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="!this.isAdd" (click)="addition()">{{ 'GENERAL.UPDATE' | translate }}</button>
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="!this.isAdd" (click)="events()">{{ 'GENERAL.EVENTS' | translate }}</button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'GENERAL.CLOSE' | translate }}</button>
    </div>
</div>