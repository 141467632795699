import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Device } from '../../models/device';

@Component({
  selector: 'urban-add-streaming-camera-dialog',
  templateUrl: './add-streaming-camera-dialog.component.html',
  styleUrls: ['./add-streaming-camera-dialog.component.scss']
})
export class AddStreamingCameraDialogComponent implements OnInit {

  public cameras: Device[];
  public selectedCamera: Device;

  constructor(
    public dialogRef: MatDialogRef<AddStreamingCameraDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        addableCameras: Device[];
      }
  ) { }

  ngOnInit(): void {
    this.cameras = this.data.addableCameras;
  }

  public radioChange(camera: MatRadioChange) {
    this.selectedCamera = camera.value;
  }

  public addCamera() {
    this.dialogRef.close(this.selectedCamera);
  }
}
