import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceModelRequest } from '../../models/administrator/deviceModelRequest';
import { DeviceBrand } from '../../models/deviceBrand';
import { DeviceModel } from '../../models/deviceModel';
import { DeviceType } from '../../models/deviceType';

@Component({
  selector: 'urban-device-model-dialog',
  templateUrl: './device-model-dialog.component.html',
  styleUrls: ['./device-model-dialog.component.scss']
})
export class DeviceModelDialogComponent implements OnInit {

  private deviceBrand: DeviceBrand;

  public deviceModel: DeviceModel;

  private deviceModelRequest: DeviceModelRequest;

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required],
    brand: [{ value: '', disabled: true }, Validators.required],
    type: [{ value: '', disabled: false }, Validators.required],
    properties: [{ value: '', disabled: false }],
    eventBody: [{ value: '', disabled: false }]
  });

  public error: string | null;

  public deviceTypes: DeviceType[] = [];

  public isAdd: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<DeviceModelDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { types: DeviceType[], brand: DeviceBrand, model: DeviceModel }
  ) {
    this.deviceTypes = this.data.types;
    this.deviceBrand = this.data.brand;
    this.deviceModel = this.data.model;

    if (this.deviceModel && this.deviceModel !== undefined) {
      this.isAdd = false;
    }
  }

  ngOnInit(): void {
    this.deviceTypes = this.data.types;
    this.deviceBrand = this.data.brand;
    this.deviceModel = this.data.model;

    if (this.deviceModel && this.deviceModel != undefined) {
      this.form.controls.name.setValue(this.deviceModel.Name);
      this.form.controls.type.setValue(this.deviceModel.Type.Id);
      this.form.controls.eventBody.setValue(this.deviceModel.EventBody);

      if (this.deviceModel.Properties && this.deviceModel.Properties !== undefined && this.deviceModel.Properties.length > 0) {
        this.form.controls.properties.setValue(this.deviceModel.Properties.join(','));
      }
    }

    this.form.controls.brand.setValue(this.deviceBrand.Name);
  }

  addition() {
    if (this.form.valid) {
      this.deviceModelRequest = {
        id: this.deviceModel && this.deviceModel !== undefined ? this.deviceModel.Id : '',
        name: this.form.controls.name.value,
        brand: this.deviceBrand.Id,
        type: this.form.controls.type.value,
        properties: this.form.controls.properties.value,
        eventBody: this.form.controls.eventBody.value
      }

      this.dialogRef.close(this.deviceModelRequest)
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
