<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }}</h3>
    <h3 class="title3 mb-3" *ngIf="!this.isAdd">{{ 'GENERAL.DETAIL' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'SMSS.WALLED_GARDEN_DESTHOST' | translate }}</mat-label>
            <input matInput formControlName="DestHost" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'SMSS.WALLED_GARDEN_DESTHOSTPORT' | translate }}</mat-label>
            <input matInput formControlName="DestHostPort" autocomplete="off" required type="number" min="0">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'SMSS.WALLED_GARDEN_ALLOW' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" formControlName="Allow">
                <mat-option value="0">
                    {{ 'GENERAL.FALSE' | translate }}
                </mat-option>
                <mat-option value="1">
                    {{ 'GENERAL.TRUE' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ error }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="addition()">{{'GENERAL.SAVE' | translate }}</button>
        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'GENERAL.CLOSE' | translate }}</button>
    </div>
</div>