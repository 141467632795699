import { Component, Input, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren, AfterViewInit, OnChanges, OnDestroy } from '@angular/core';
import { Device } from '../../models/device';
import { MainSubscriptionsService } from '../../services/main-subscriptions/main-subscriptions.service';
import { MatDialog } from '@angular/material/dialog';
import { PassDataService } from '../../services/pass-data/pass-data.service';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Router } from '@angular/router';
import { Subject, timer } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators'
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { DeviceGroupDetailDialogComponent } from '../../dialogs/device-group-detail-dialog/device-group-detail-dialog.component';
import { LocationDialogComponent } from '../../dialogs/location-dialog/location-dialog.component';
import { GoogleMarker, InfoContentData } from '../../models/googleMarker';
import { UserRoles } from '../../models/userRoles';
import { Location } from '../../models/location';
import { DomainProperty } from '../../models/domainProperty';
import { GoogleMapCircle } from '../../models/googleMapCircle';

@Component({
  selector: 'urban-wifi-widget',
  templateUrl: './wifi-widget.component.html',
  styleUrls: ['./wifi-widget.component.scss']
})
export class WifiWidgetComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap
  @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;
  @ViewChildren(MapMarker) mapMarkers: QueryList<MapMarker>;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  private defaultPosition: google.maps.LatLng;
  private listLocations: Location[];
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private firstBoundsSaving: boolean = true;
  public userRoles: UserRoles['Roles'];
  public mapOptions: google.maps.MapOptions;
  public mapBounds: google.maps.LatLngBounds;
  public mapZoom: number;
  public markers: Array<GoogleMarker> = [];
  public circles: Array<GoogleMapCircle> = [];
  public isAdmin: boolean = false;
  public currentUrl: string;
  public refreshMap: boolean = false;
  public guidPattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');

  @Input('initialZoom') private defaultZoom: number;
  @Input('asWidget') public meantAsWidget: boolean = false;
  @Input('devicesList') public listDevices: Device[];
  @Input('passedHeight') public passedHeight: number = null;
  @Input('darkModeStatus') public isDarkActive: boolean;
  @Input('fixedMarkers') public stillMarkers: boolean = false;

  constructor(
    private mainService: MainSubscriptionsService,
    private passDataService: PassDataService,
    private router: Router,
    public dialog: MatDialog
  ) {

    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(roles => {
      this.userRoles = roles;
    });
    this.passDataService.currentDomainProperties$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(properties => {
      let defaultCoordinatesProperties: DomainProperty = properties
        .find((property: DomainProperty) => property.Key === 'DefaultCoordinates');
      if (defaultCoordinatesProperties !== undefined ) {
        try {
          let coordinatesObject: any = JSON.parse(defaultCoordinatesProperties.Value);
          if (['lat', 'lng'].every((key: string) => Object.keys(coordinatesObject).includes(key))) {
            this.defaultPosition = new google.maps.LatLng(coordinatesObject.lat, coordinatesObject.lng);
          }
          else {
            this.defaultPosition = null;
          }
        }
        catch {
          this.defaultPosition = null;
        }
      }
    });
    this.passDataService.locations$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(locations => {
      this.listLocations = locations;
    });
  }

  ngAfterViewInit() {
    this.setBounds();
  }

  private setBounds(): void {
    if (this.mapBounds === undefined) {
      let mapBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
      let atLeastOnePosition: boolean = false;

      if (this.listDevices != null && this.listDevices.length !== undefined) {
        this.listDevices.forEach((device: Device) =>  {
          mapBounds.extend(new google.maps.LatLng(device.Latitude, device.Longitude));
          atLeastOnePosition = true;
        });
      }

      if (!atLeastOnePosition && this.defaultPosition) {
        mapBounds.extend(this.defaultPosition);
      }

      if(mapBounds) {
        this.mapBounds = mapBounds;

        this.map.boundsChanged.pipe(first()).subscribe(() => {
          this.map.fitBounds(this.mapBounds, 0);

          this.map.boundsChanged.pipe(first()).subscribe(() => {
            if (this.defaultZoom !== undefined && this.listDevices.length < 2) {
              this.map.googleMap.setZoom(atLeastOnePosition ? 15 : this.defaultZoom);
            }
          });
        });
      }
    } else {
      this.tryTillCondition( 'fit',
        () => {
          this.map.fitBounds(this.mapBounds, 0);
        },
        () => this.map !== undefined && this.mapBounds !== null,
        true);
    }
  }

  private tryTillCondition(name: string, callback: () => void, condition: () => boolean = () => true, firstTime: boolean = false): void {
    timer(firstTime ? 0 : 500).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      if (condition()) {
        callback();
      }
      else {
        this.tryTillCondition(name, callback, condition);
      }
    });
  }

  ngOnInit(): void {
    this.currentUrl = this.router.url.split('/').pop().split(';')[0];

    this.mapOptions = {
      mapId: this.isDarkActive ? 'af8729d51fc92cc9' : '805d80ad475b1388',
      gestureHandling: "cooperative",
      mapTypeId: "roadmap"
    } as google.maps.MapOptions

    this.isAdmin = this.userRoles.some(x => x.Name === 'Administrators' || x.Name === 'Domain admin');

    this.addMarkers();
  }

  private addMarkers(): void {
    if (this.listDevices?.length > 0) {
      this.listDevices.forEach((device: Device) => {
        if (device.Parent === null || device.Parent === undefined) {
          let children = this.buildDevices(device.Childs);

          this.markers.push(new GoogleMarker(
            new google.maps.LatLng(device.Latitude, device.Longitude),
            {
              color: 'black',
              text: device.Name.length > 12 ? device.Name.slice(0, 12) + '...' : device.Name,
              fontSize: '0px',
              fontWeight: 'normal',
              scaledSize: new google.maps.Size(80, 80),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(20, 0),
              labelOrigin: null
            },
            device.Name,
            { icon: { scaledSize: new google.maps.Size(0, 0), url: '' } },
            device.Id,
            this._buildInfoContentArray(children)
          ));

          let wifiRange: number = +(device.Properties?.['Signal_range']);

          if (wifiRange) {
            this.circles.push({
              Id: device.Id,
              Name: device.Name,
              Options:  {
                center: new google.maps.LatLng(device.Latitude, device.Longitude),
                radius: wifiRange,
                fillColor: '#' + device.Model.Type.Color,
                strokeColor: '#' + device.Model.Type.Color,
                fillOpacity: 0.1,
                strokeWeight: 3,
              }
            });
          }
        }
      });
    }
  }

  public openInfoWindow(circleIndex: number): void {
    let selectedMarkerIndex: number = this.markers.findIndex((marker: GoogleMarker) => marker.info === this.circles[circleIndex]?.Id);
    if (selectedMarkerIndex === -1) {
      return;
    }

    let anchorPoint: MapMarker = this.mapMarkers.toArray()[selectedMarkerIndex];
    if (anchorPoint === undefined) {
      return;
    }

    let infoWindow: MapInfoWindow = this.infoWindowsView.toArray()[circleIndex];
    if (infoWindow === undefined) {
      return;
    }

    infoWindow.open(anchorPoint)
  }

  public openInfo(content: string): void {
    if (content != undefined && content != null && content != '' && content.match(this.guidPattern)) {
      this.goToDeviceDetail(content);
    }
  }

  public goToDeviceDetail(deviceId: string): void {
    this.mainService.setNavigationInfoComand({ Id: deviceId, BackRoute: this.currentUrl });
    this.mainService.updateAllDataAndNavigateComand('device');
  }

  private _buildInfoContentArray(devices: Device[]): InfoContentData[] {

    if (!devices || devices === undefined) {
      return [];
    }

    let elements: Array<InfoContentData> = [];

    devices.forEach((element) => {
      if (elements.some(function (item) {
        return item.Name == element.Model.Type.Name
      })) {
        elements.find(x => x.Name == element.Model.Type.Name).Elements++;
      }
      else {
        elements.push(new InfoContentData(element.Model.Type.Name, element.Model.Type.Icon))
      }
    });

    return elements;
  }

  private buildDevices(devices: Device[]): Device[] {
    if (!devices || devices === undefined) {
      return [];
    }

    let childs: Device[] = [];

    for (let device of devices) {
      if (device.Childs?.length > 0) {
        childs.push(device);
        for (let child of device.Childs) {
          childs.push(child);
        }
      }
      else {
        childs.push(device);
      }
    }

    return childs;
  }

  public saveBounds(): void {
    if (this.firstBoundsSaving || false) {
      this.firstBoundsSaving = false;
    }
    else {
      let mapBounds: google.maps.LatLngBounds = this.map.getBounds();
      if (mapBounds !== null) {
        this.mapBounds = mapBounds;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDarkActive && changes.isDarkActive.currentValue !== changes.isDarkActive.previousValue && !changes.isDarkActive.firstChange) {
      this.refreshMap = true;
      timer(0).subscribe(() => {
        this.mapOptions = {
          mapId: this.isDarkActive ? 'af8729d51fc92cc9' : '805d80ad475b1388',
          gestureHandling: "cooperative",
          mapTypeId: "roadmap",
        } as google.maps.MapOptions;

        this.refreshMap = false;
        this.setBounds();
      });
    }
    else if (changes.stillMarkers && changes.stillMarkers.currentValue !== changes.stillMarkers.previousValue && !changes.stillMarkers.firstChange) {
      if (this.listDevices?.length > 0) {
        this.markers = this.markers.filter(marker => !this.listDevices.some(device => device.Id === marker.info));
      }
      this.addMarkers();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
