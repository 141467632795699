<div class="dialogContainer">
    <h3 class="title3 mb-3">{{ 'ADMINISTRATION.ADD_USER' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'ADMINISTRATION.USERNAME' | translate }}</mat-label>
            <input matInput formControlName="username" autocomplete="off">
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'ADMINISTRATION.EMAIL' | translate }}</mat-label>
            <input matInput type="email" formControlName="email" autocomplete="off" email>
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'ADMINISTRATION.FIRSTNAME' | translate }}</mat-label>
            <input matInput formControlName="firstname" autocomplete="off">
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'ADMINISTRATION.LASTNAME' | translate }}</mat-label>
            <input matInput formControlName="lastname" autocomplete="off">
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'ADMINISTRATION.DOMAIN' | translate }}</mat-label>
            <mat-select formControlName="domainSelected" hideSingleSelectionIndicator="true">
                <mat-option *ngFor="let domain of data.myDomainsList" value={{domain.Id}}>{{domain.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'ADMINISTRATION.ROLE' | translate }}</mat-label>
            <mat-select formControlName="roleSelected" hideSingleSelectionIndicator="true">
                <mat-option *ngFor="let role of data.myRolesList" value={{role.Id}}>{{role.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    
        <div class="d-flex mb-3">
            <mat-form-field class="customMatFormField mr-2" appearance="outline">
                <mat-label>{{ 'PROFILE.PREFIX' | translate }}</mat-label>
                <mat-select formControlName="phonePrefix" hideSingleSelectionIndicator="true">
                    <mat-option *ngFor="let prefix of data.myPrefixesList" [value]="prefix.Code">
                        <span class="flag-icon flag-icon-{{ prefix.Flag }}"></span>
                        {{ prefix.Country }}
                        {{ "+" + prefix.Code }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="customMatFormField" appearance="outline">
                <mat-label>{{ 'PROFILE.PHONE' | translate }}</mat-label>
                <input matInput formControlName="phoneNumber" autocomplete="off" type="number" min="0">
                <i matSuffix class="urbanicon-phone-outline"></i>
            </mat-form-field>
        </div>
    
        <mat-error *ngIf="error" class="mt-2">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
    
    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="addition()">{{ 'CHANGE_PASSWORD_DIALOG.CONFIRM' | translate }}</button>
        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'CHANGE_PASSWORD_DIALOG.CANCEL' | translate }}</button>
    </div>
</div>