import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthorizeUserRequest } from '../../models/administrator/authorizeUserRequest';
import { User } from '../../models/loginResponse';
import { Role } from '../../models/userRoles';
import { Domain } from '../../models/domain';

@Component({
  selector: 'urban-add-authorization-dialog',
  templateUrl: './add-authorization-dialog.component.html',
  styleUrls: ['./add-authorization-dialog.component.scss']
})
export class AddAuthorizationDialogComponent implements OnInit {

  public form: UntypedFormGroup = this.formBuilder.group({
    domainSelected: [{ value: '', disabled: false }, Validators.required],
    roleSelected: [{ value: '', disabled: true }, Validators.required]
  });

  public availableRolesPerDomain: Record<string, Role[]> = {};
  public availableRoles: Role[] = [];
  public domains: Domain[] = [];
  public currentUser: User;
  private authorizeRequest: AuthorizeUserRequest;
  public error: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<AddAuthorizationDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        user: User,
        domains: Domain[],
        availableRolesPerDomain: Record<string, Role[]>,
      }) { }

  ngOnInit(): void {
    this.currentUser = this.data.user;
    this.domains = this.data.domains;
    this.availableRolesPerDomain = this.data.availableRolesPerDomain;
  }

  public updateAvailableRoles() {
    const selectedDomainId = this.form.get('domainSelected')?.value;
    this.availableRoles = this.availableRolesPerDomain[selectedDomainId] || [];
    this.form.get('roleSelected')?.enable();
  }

  public add() {
    if (this.form.valid) {

      this.authorizeRequest = {
        User: this.currentUser.Id,
        Domain: this.form.controls.domainSelected.value,
        Role: this.form.controls.roleSelected.value
      };

      this.dialogRef.close(this.authorizeRequest)
    } else {
      this.error = "ERROR_EMPTY";
    }
  }
}
