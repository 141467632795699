<div class="h-100 d-flex flex-column">
    <div class="goToPageBtn" *ngIf="meantAsWidget">
        <button *ngIf="isAdmin" mat-button class="btn outlineActionButton" (click)="goToMapPage()">
            {{ 'GENERAL.GO_TO_PAGE' | translate }}
        </button>
    </div>

    <div class="map-container">
        <div class="panel" *ngIf="infoActionPanel">
            <urban-alert-panel alertType="info"
                [descriptionText]="( editMode === 'drag' ? 'MAPS.DRAG_INFO' : editMode === 'device' ? 'MAPS.ADD_DEVICE_INFO' : 'MAPS.ADD_LOCATION_INFO') | translate"
                (close)="infoActionPanel = false" [overlayPanel]="true">
            </urban-alert-panel>
        </div>

        <google-map
            width="100%" class="test" *ngIf="!refreshMap" [options]="mapOptions" [height]="passedHeight === 0 ? '100%' : (passedHeight ? passedHeight : 750)"
            (mapClick)="editMode === 'device' || editMode === 'location' ? executeAction($event): {}" (boundsChanged)="saveBounds()">
            <map-marker
                #marker="mapMarker" *ngFor="let myMarker of markers; let i = index" [position]="myMarker.position"
                [options]="myMarker.options" [label]="myMarker.label" [title]="myMarker.title"
                (mapClick)="showInfoWindow ? openInfoWindow(marker, i, myMarker.info) : {}"
                (mapDragend)="editMode === 'drag' ? setMarkerCoordinates(marker, myMarker.info) : {}">

                <map-info-window *ngIf="showInfoWindow" #mapInfoWindow>
                    <div class="mapInfoWindowContainer">
                        <span [ngStyle]="{'margin-bottom' : myMarker?.groups?.length > 0 ? '10px' : '0'}" class="d-block substrongText pointer mapInfoWindowTitle" (click)="openInfo(myMarker.info)">{{ myMarker.title }}</span>

                        <div *ngIf="myMarker?.groups?.length > 0" class="d-flex align-items-center">
                            <span class="caption d-block">{{ (myMarker?.groups.length > 1 ? 'DEVICE.GROUPS' : 'SERVICE_DETAIL.GROUP') | translate }}:</span>

                            <div class="d-flex align-items-center justify-content-start flex-wrap">
                                <div class="d-flex align-items-center" *ngFor="let group of myMarker.groups" (click)="openDeviceGroupDetail(group.Id, group.Name)">
                                    <div class="cerchio" [ngStyle]="{'background-color': group.Color}"></div>
                                    <span class="link linkText mr-1">{{ group.Name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </map-info-window>
            </map-marker>

            <map-polygon *ngFor="let polygon of polygons" [path]="polygon.path" [options]="polygon.options"></map-polygon>
        </google-map>
    </div>

    <div *ngIf="refreshMap" style="width:'100%';" [ngStyle]="{'height:' : passedHeight ? passedHeight : '750px' }"></div>
</div>
