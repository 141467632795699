<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }}</h3>
    <h3 class="title3 mb-3" *ngIf="!this.isAdd">{{ 'GENERAL.DETAIL' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <div class="d-flex mb-3">
            <mat-form-field appearance="outline" class="customDatePicker mr-2">
                <mat-label>{{'GENERAL.START_DATE' | translate }}</mat-label>
                <input matInput [owlDateTime]="dt1" formControlName="start" [owlDateTimeTrigger]="dt1" readonly>
    
                <i [owlDateTimeTrigger]="dt1" matSuffix class="urbanicon-monitoring-outline pointer"></i>
    
                <owl-date-time #dt1></owl-date-time>
            </mat-form-field>
    
            <mat-form-field appearance="outline" class="customDatePicker">
                <mat-label>{{'GENERAL.END_DATE' | translate }}</mat-label>
                <input matInput [owlDateTime]="dt2" formControlName="end" [owlDateTimeTrigger]="dt2" readonly>
    
                <i [owlDateTimeTrigger]="dt2" matSuffix class="urbanicon-monitoring-outline pointer"></i>
    
                <owl-date-time #dt2></owl-date-time>
            </mat-form-field>
        </div>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.PRIORITY' | translate }}</mat-label>
            <input type="number" matInput formControlName="priority" min="1" max="10" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.DURATION' | translate }}</mat-label>
            <input type="number" matInput formControlName="duration" min="0" step="0.1" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'CONTENT_MANAGEMENT.CHANNEL' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" formControlName="channel">
                <mat-option *ngFor="let channel of channels" value={{channel.Id}}>
                    {{channel.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'CONTENT_MANAGEMENT.CONTENT' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" formControlName="content">
                <mat-option *ngFor="let content of contents" value={{content.Id}}>
                    {{content.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="this.isAdd" (click)="addition()">
            {{ 'GENERAL.ADD' | translate }}
        </button>
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="!this.isAdd" (click)="addition()">
            {{ 'GENERAL.UPDATE' | translate }}
        </button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>
