<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }}</h3>
    <h3 class="title3 mb-3" *ngIf="!this.isAdd">{{ 'GENERAL.UPDATE' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.LABEL' | translate }}</mat-label>
            <input matInput formControlName="label" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.ISVISIBLE' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" formControlName="isVisible">
                <mat-option value="1">
                    {{ 'GENERAL.YES' | translate }}
                </mat-option>
                <mat-option value="0">
                    {{ 'GENERAL.NO' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.ORDER' | translate }}</mat-label>
            <input matInput type="number" formControlName="order" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE_TYPE.ACTION_COMPLEXITY' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" (selectionChange)="changeActionType()" formControlName="complexAction">
                <mat-option value="false">{{ 'DEVICE_TYPE.SINGLE_ACTION' | translate }}
                </mat-option>
                <mat-option value="true">{{ 'DEVICE_TYPE.COMPLEX_ACTION' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div *ngIf="!this.complexAction">
            <mat-form-field appearance="outline" class="customMatFormField mb-3">
                <mat-label>{{ 'GENERAL.ACTION' | translate }}</mat-label>
                <input matInput formControlName="action" autocomplete="off">
            </mat-form-field>
        </div>

        <div *ngIf="this.complexAction">
            <mat-form-field appearance="outline" class="customMatFormField mb-3">
                <mat-label>{{ 'GENERAL.URL' | translate }}</mat-label>
                <input matInput formControlName="path" autocomplete="off">
            </mat-form-field>

            <mat-form-field appearance="outline" class="customMatFormField mb-3">
                <mat-label>{{ 'GENERAL.PARAMETERS' | translate }}</mat-label>
                <input matInput formControlName="parameters" autocomplete="off">
            </mat-form-field>

            <mat-form-field appearance="outline" class="customMatFormField mb-3">
                <mat-label>{{ 'GENERAL.ICON' | translate }}</mat-label>
                <input matInput formControlName="capabilityIcon" autocomplete="off">
            </mat-form-field>

            <mat-form-field appearance="outline" class="customTextArea mb-3">
                <mat-label>{{ 'GENERAL.BODY' | translate }}</mat-label>
                <textarea   matInput formControlName="body"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="5"
                            cdkAutosizeMaxRows="5">
                </textarea>
            </mat-form-field>
        </div>

        <mat-error *ngIf="error">
            {{ 'GENERAL.ERROR_EMPTY' | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button *ngIf="this.isAdd" type="submit" class="btn actionButton w-50 mr-1" (click)="addition()">{{ 'GENERAL.ADD' | translate }}</button>
        <button mat-button *ngIf="!this.isAdd" type="submit" class="btn actionButton w-50 mr-1" (click)="addition()">{{ 'GENERAL.UPDATE' | translate }}</button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>