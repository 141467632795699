import { SankeyDiagramChartComponent } from './components/sankey-diagram-chart/sankey-diagram-chart.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MenuListComponent } from './components/sidebar/menu-list/menu-list.component';
import { RouterModule } from '@angular/router';
import { MatModule } from './mat/mat.module';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { AddDeviceDialogComponent } from './dialogs/add-device-dialog/add-device-dialog.component';
import { StreamingDialogComponent } from './dialogs/streaming-dialog/streaming-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordDialogComponent } from './dialogs/change-password-dialog/change-password-dialog.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DashboardManagementDialogComponent } from './dialogs/dashboard-management-dialog/dashboard-management-dialog.component';
import { RemoveWidgetDialogComponent } from './dialogs/remove-widget-dialog/remove-widget-dialog.component';
import { AroundMeDialogComponent } from './dialogs/around-me-dialog/around-me-dialog.component';
import { AroundMeWidgetComponent } from './widgets/around-me-widget/around-me-widget.component';
import { SearchDeviceWidgetComponent } from './widgets/search-device-widget/search-device-widget.component';
import { MapsWidgetComponent } from './widgets/maps-widget/maps-widget.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleChartsModule } from 'angular-google-charts';
import { SetDomainDialogComponent } from './dialogs/set-domain-dialog/set-domain-dialog.component';
import { InvisibleChildComponent } from './components/invisible-child/invisible-child.component';
import { AddUserDialogComponent } from './dialogs/add-user-dialog/add-user-dialog.component';
import { AddAuthorizationDialogComponent } from './dialogs/add-authorization-dialog/add-authorization-dialog.component';
import { AddDomainDialogComponent } from './dialogs/add-domain-dialog/add-domain-dialog.component';
import { AddRoleDialogComponent } from './dialogs/add-role-dialog/add-role-dialog.component';
import { AddUserDomainDialogComponent } from './dialogs/add-user-domain-dialog/add-user-domain-dialog.component';
import { AddAuthorizationUserDialogComponent } from './dialogs/add-authorization-user-dialog/add-authorization-user-dialog.component';
import { PlatformStatisticsDetailDialogComponent } from './dialogs/platform-statistics-detail-dialog/platform-statistics-detail-dialog.component';
import { RemoteControlLoaderComponent } from './components/remote-control-loader/remote-control-loader.component';
import { AddNotificationDialogComponent } from './dialogs/add-notification-dialog/add-notification-dialog.component';
import { StreamingLinkDialogComponent } from './dialogs/streaming-link-dialog/streaming-link-dialog.component';
import { NotificationDialogComponent } from './dialogs/notification-dialog/notification-dialog.component';
import { DomainPropertyDialogComponent } from './dialogs/domain-property-dialog/domain-property-dialog.component';
import { PhraseDialogComponent } from './dialogs/phrase-dialog/phrase-dialog.component';
import { ClientSettingDialogComponent } from './dialogs/client-setting-dialog/client-setting-dialog.component';
import { ResourceDialogComponent } from './dialogs/resource-dialog/resource-dialog.component';
import { AddLanguageDialogComponent } from './dialogs/add-language-dialog/add-language-dialog.component';
import { AddDeviceAliasDialogComponent } from './dialogs/add-device-alias-dialog/add-device-alias-dialog.component';
import { DeviceEventDetailDialogComponent } from './dialogs/device-event-detail-dialog/device-event-detail-dialog.component';
import { HeatmapWeatherMapsComponent } from './widgets/heatmap-weather-maps/heatmap-weather-maps.component';
import { DeviceBrandDialogComponent } from './dialogs/device-brand-dialog/device-brand-dialog.component';
import { DeviceModelDialogComponent } from './dialogs/device-model-dialog/device-model-dialog.component';
import { DeviceTypeDialogComponent } from './dialogs/device-type-dialog/device-type-dialog.component';
import { HeatmapPeopleMapsComponent } from './widgets/heatmap-people-maps/heatmap-people-maps.component';
import { DeviceTypeCapabilityDialogComponent } from './dialogs/device-type-capability-dialog/device-type-capability-dialog.component';
import { GoogleLinechartComponent } from './components/google-linechart/google-linechart.component';
import { NoteWidgetComponent } from './widgets/note-widget/note-widget.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DeviceGroupDialogComponent } from './dialogs/device-group-dialog/device-group-dialog.component';
import { DeviceGroupSetDialogComponent } from './dialogs/device-group-set-dialog/device-group-set-dialog.component';
import { NewsDialogComponent } from './dialogs/news-dialog/news-dialog.component';
import { StreamingWidgetComponent } from './widgets/streaming-widget/streaming-widget.component';
import { DeviceGroupDetailDialogComponent } from './dialogs/device-group-detail-dialog/device-group-detail-dialog.component';
import { SmartDialogComponent } from './dialogs/smart-dialog/smart-dialog.component';
import { AdvertiserDialogComponent } from './dialogs/advertiser-dialog/advertiser-dialog.component';
import { CampaignDialogComponent } from './dialogs/campaign-dialog/campaign-dialog.component';
import { LocationDialogComponent } from './dialogs/location-dialog/location-dialog.component';
import { CalendarDialogComponent } from './dialogs/calendar-dialog/calendar-dialog.component';
import { WalledGardenDialogComponent } from './dialogs/walled-garden-dialog/walled-garden-dialog.component';
import { ParkingMapsWidgetComponent } from './widgets/parking-maps-widget/parking-maps-widget.component';
import { GoogleColumnchartComponent } from './components/google-columnchart/google-columnchart.component';
import { GoogleAreachartComponent } from './components/google-areachart/google-areachart.component';
import { TrafficMapsWidgetComponent } from './widgets/traffic-maps-widget/traffic-maps-widget.component';
import { AddTaskScheduleDialogComponent } from './dialogs/add-task-schedule-dialog/add-task-schedule-dialog.component';
import { DevicesMapDialogComponent } from './dialogs/devices-map-dialog/devices-map-dialog.component';
import { GoogleBarchartComponent } from './components/google-barchart/google-barchart.component';
import { HeatmapTrashBinMapsComponent } from './widgets/heatmap-trash-bin-maps/heatmap-trash-bin-maps.component';
import { GoogleHeatmapComponent } from './components/google-heatmap/google-heatmap.component';
import { DeviceCommandTypeDialogComponent } from './dialogs/device-command-type-dialog/device-command-type-dialog.component';
import { DeviceNotificationDetailDialogComponent } from './dialogs/device-notification-detail-dialog/device-notification-detail-dialog.component';
import { AddStreamingCameraDialogComponent } from './dialogs/add-streaming-camera-dialog/add-streaming-camera-dialog.component';
import { PathsChartComponent } from './components/paths-chart/paths-chart.component';
import { GoogleBubblechartComponent } from './components/google-bubblechart/google-bubblechart.component';
import { AddWidgetToDashboardDialogComponent } from './dialogs/add-widget-to-dashboard-dialog/add-widget-to-dashboard-dialog.component';
import { WidgetManagementDialogComponent } from './dialogs/widget-management-dialog/widget-management-dialog.component';
import { ServerSettingDialogComponent } from './dialogs/server-setting-dialog/server-setting-dialog.component';
import { AlertEventsWidgetComponent } from './widgets/alert-events-widget/alert-events-widget.component';
import { AlertEventsDialogComponent } from './dialogs/alert-events-dialog/alert-events-dialog.component';
import { NameEntityDialogComponent } from './dialogs/name-entity-dialog/name-entity-dialog.component';
import { TemplateDialogComponent } from './dialogs/template-dialog/template-dialog.component';
import {PropertyDialogComponent} from "./dialogs/property-dialog/property-dialog.component";
import {LocationEventDialogComponent} from "./dialogs/location-event-dialog/location-event-dialog.component";
import {ManualEventDialogComponent} from "./dialogs/manual-event-dialog/manual-event-dialog.component";
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { DomainVisibilityDialogComponent } from './dialogs/domain-visibility-dialog/domain-visibility-dialog.component';
import { ScheduleRuleDialogComponent } from './dialogs/schedule-rule-dialog/schedule-rule-dialog.component';
import { BroadcastClientDialogComponent } from './dialogs/broadcast-client-dialog/broadcast-client-dialog.component';
import { ChannelDialogComponent } from './dialogs/channel-dialog/channel-dialog.component';
import { BroadcastDialogComponent } from './dialogs/broadcast-dialog/broadcast-dialog.component';
import { ContentDialogComponent } from './dialogs/content-dialog/content-dialog.component';
import { ImxCameraWidgetComponent } from './widgets/imx-camera-widget/imx-camera-widget.component';
import { ContentDataDialogComponent } from './dialogs/content-data-dialog/content-data-dialog.component';
import { GooglePiechartComponent } from './components/google-piechart/google-piechart.component';
import { TrafficWidgetComponent } from './widgets/traffic-widget/traffic-widget.component';
import { ServiceTypeDialogComponent } from './dialogs/service-type-dialog/service-type-dialog.component';
import { ServiceDialogComponent } from './dialogs/service-dialog/service-dialog.component';
import { WeatherWidgetComponent } from './widgets/weather-widget/weather-widget.component';
import { PolarAreaChartComponent } from './components/polar-area-chart/polar-area-chart.component';
import { CrosswalkWidgetComponent } from './widgets/crosswalk-widget/crosswalk-widget.component';
import { AlertPanelComponent } from './components/alert-panel/alert-panel.component';
import { MarketingWidgetComponent } from './widgets/marketing-widget/marketing-widget.component';
import { RadarChartComponent } from './components/radar-chart/radar-chart.component';
import { TrashBinWidgetComponent } from './widgets/trash-bin-widget/trash-bin-widget.component';
import { AdvertisingTypeDialogComponent } from './dialogs/advertising-type-dialog/advertising-type-dialog.component';
import { AdvertisingDialogComponent } from './dialogs/advertising-dialog/advertising-dialog.component';
import { ConsumerDialogComponent } from './dialogs/consumer-dialog/consumer-dialog.component';
import { WifiWidgetComponent } from './widgets/wifi-widget/wifi-widget.component';
import { DeviceEventsRejectedWidgetComponent } from './widgets/device-events-rejected-widget/device-events-rejected-widget.component';
import { DeviceModelEventBodyDialogComponent } from './dialogs/device-model-event-body-dialog/device-model-event-body-dialog.component';
import { NgxDropzoneModule } from 'ngx-dropzone';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    DashboardManagementDialogComponent,
    RemoveWidgetDialogComponent,
    AddDeviceDialogComponent,
    ChangePasswordDialogComponent,
    ConfirmationDialogComponent,
    MenuListComponent,
    StreamingDialogComponent,
    LoaderComponent,
    SearchDeviceWidgetComponent,
    MapsWidgetComponent,
    ParkingMapsWidgetComponent,
    AroundMeWidgetComponent,
    AroundMeDialogComponent,
    SetDomainDialogComponent,
    InvisibleChildComponent,
    AddUserDialogComponent,
    AddAuthorizationDialogComponent,
    AddDomainDialogComponent,
    AddRoleDialogComponent,
    AddUserDomainDialogComponent,
    AddAuthorizationUserDialogComponent,
    PlatformStatisticsDetailDialogComponent,
    RemoteControlLoaderComponent,
    AddNotificationDialogComponent,
    StreamingLinkDialogComponent,
    NotificationDialogComponent,
    DomainPropertyDialogComponent,
    PhraseDialogComponent,
    ClientSettingDialogComponent,
    ResourceDialogComponent,
    AddLanguageDialogComponent,
    AddDeviceAliasDialogComponent,
    DeviceEventDetailDialogComponent,
    HeatmapWeatherMapsComponent,
    DeviceBrandDialogComponent,
    DeviceModelDialogComponent,
    DeviceTypeDialogComponent,
    HeatmapPeopleMapsComponent,
    DeviceTypeCapabilityDialogComponent,
    GoogleLinechartComponent,
    NoteWidgetComponent,
    DeviceGroupDialogComponent,
    DeviceGroupSetDialogComponent,
    NewsDialogComponent,
    StreamingWidgetComponent,
    DeviceGroupDetailDialogComponent,
    SmartDialogComponent,
    AdvertiserDialogComponent,
    CampaignDialogComponent,
    LocationDialogComponent,
    CalendarDialogComponent,
    WalledGardenDialogComponent,
    GoogleColumnchartComponent,
    GoogleAreachartComponent,
    TrafficMapsWidgetComponent,
    AddTaskScheduleDialogComponent,
    DevicesMapDialogComponent,
    GoogleBarchartComponent,
    HeatmapTrashBinMapsComponent,
    SankeyDiagramChartComponent,
    GoogleHeatmapComponent,
    DeviceCommandTypeDialogComponent,
    DeviceNotificationDetailDialogComponent,
    AddStreamingCameraDialogComponent,
    PathsChartComponent,
    GoogleBubblechartComponent,
    AddWidgetToDashboardDialogComponent,
    WidgetManagementDialogComponent,
    ServerSettingDialogComponent,
    AlertEventsWidgetComponent,
    AlertEventsDialogComponent,
    NameEntityDialogComponent,
    TemplateDialogComponent,
    PropertyDialogComponent,
    LocationEventDialogComponent,
    ManualEventDialogComponent,
    SearchbarComponent,
    DomainVisibilityDialogComponent,
    ScheduleRuleDialogComponent,
    BroadcastClientDialogComponent,
    ChannelDialogComponent,
    BroadcastDialogComponent,
    ContentDialogComponent,
    ImxCameraWidgetComponent,
    ContentDataDialogComponent,
    GooglePiechartComponent,
    TrafficWidgetComponent,
    ServiceTypeDialogComponent,
    ServiceDialogComponent,
    WeatherWidgetComponent,
    PolarAreaChartComponent,
    CrosswalkWidgetComponent,
    AlertPanelComponent,
    MarketingWidgetComponent,
    RadarChartComponent,
    TrashBinWidgetComponent,
    AdvertisingTypeDialogComponent,
    AdvertisingDialogComponent,
    ConsumerDialogComponent,
    WifiWidgetComponent,
    DeviceEventsRejectedWidgetComponent,
    DeviceModelEventBodyDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    GoogleChartsModule,
    DragDropModule,
    NgxDropzoneModule
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    MenuListComponent,
    MatModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderComponent,
    RemoteControlLoaderComponent,
    SearchDeviceWidgetComponent,
    MapsWidgetComponent,
    ParkingMapsWidgetComponent,
    AroundMeWidgetComponent,
    GoogleChartsModule,
    HeatmapWeatherMapsComponent,
    HeatmapPeopleMapsComponent,
    GoogleLinechartComponent,
    NoteWidgetComponent,
    DragDropModule,
    StreamingWidgetComponent,
    GoogleColumnchartComponent,
    GoogleAreachartComponent,
    TrafficMapsWidgetComponent,
    GoogleBarchartComponent,
    HeatmapTrashBinMapsComponent,
    SankeyDiagramChartComponent,
    GoogleHeatmapComponent,
    PathsChartComponent,
    GoogleBubblechartComponent,
    AlertEventsWidgetComponent,
    SearchbarComponent,
    ImxCameraWidgetComponent,
    GooglePiechartComponent,
    TrafficWidgetComponent,
    ServiceTypeDialogComponent,
    ServiceDialogComponent,
    WeatherWidgetComponent,
    PolarAreaChartComponent,
    CrosswalkWidgetComponent,
    AlertPanelComponent,
    MarketingWidgetComponent,
    RadarChartComponent,
    TrashBinWidgetComponent,
    WifiWidgetComponent,
    DeviceEventsRejectedWidgetComponent,
    DeviceModelEventBodyDialogComponent,
    NgxDropzoneModule
  ]
})
export class SharedModule { }
