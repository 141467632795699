<div class="dialgoContainer">
    <h3 class="title3 mb-4">{{ 'REPORT.' + selectedType[0].Model.Type.Name | translate }} - {{'ALERT_EVENTS_WIDGET.ERRORS' | translate}}</h3>

    <div class="mb-3 overflow-auto d-flex flex-column">
        <div class="d-flex" *ngFor="let errorDevice of selectedType">
            <i class="urbanicon-alert"></i>
            <p class="deviceName paragraphText mt-1">{{errorDevice.Model.Name}}</p>
            <p *ngIf="isAdmin" class="ml-auto mr-2 mt-1 link" (click)="goToDetail(errorDevice.Id)">{{'NOTIFICATION_ALL.DETAIL' | translate}}</p>
        </div>
    </div>

    <button mat-button class="btn outlineActionButton" mat-dialog-close>{{ 'GENERAL.CANCEL' | translate}}</button>
</div>