<div class="dialogContainer">
    <div class="text-center mb-3">
        <h3 class="title3">{{ 'DASHBOARD.ADD_WIDGET' | translate }}</h3>
        <p class="paragraphText">{{ 'ADD_WIDGET_DIALOG.SELECT_WIDGET' | translate }}</p>
    </div>
    
    <div class="overflow-auto">
        <mat-selection-list class="d-flex flex-column mb-3" #widget (selectionChange)="onGroupsChange(widget.selectedOptions.selected)">
            <mat-list-option [disableRipple]="true" *ngFor="let widget of passedWidgets" [value]="widget">
                {{widget.Name}}
            </mat-list-option>
        </mat-selection-list>
    </div>
    
    <div class="d-flex justify-content-between">
        <button mat-button class="btn actionButton w-50 mr-1" (click)="addWidget()">{{ 'DASHBOARD.ADD_WIDGET' | translate }}</button>
        <button mat-button class="btn outlineActionButton w-50 ml-1" mat-dialog-close>{{ 'GENERAL.CANCEL' | translate}}</button>
    </div>
</div>