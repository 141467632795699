import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'urban-device-group-detail-dialog',
  templateUrl: './device-group-detail-dialog.component.html',
  styleUrls: ['./device-group-detail-dialog.component.scss']
})
export class DeviceGroupDetailDialogComponent implements OnInit {

  public deviceGroupName: string;

  constructor(public dialogRef: MatDialogRef<DeviceGroupDetailDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        deviceGroupName: string
      }) {
    this.deviceGroupName = data.deviceGroupName;
    console.log(this.deviceGroupName)
  }

  ngOnInit(): void {
  }

  public confirmAction(): void {
    this.dialogRef.close(true);
  }
}
