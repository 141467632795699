export interface EventBody {
  Body: string;
  SingleFieldsToSet: EventBodyField[];
}

export interface EventParsedBody {
  MandatorySingleFields: EventBodyField[];
  Mapping: { [key: string]: any };
}

export interface EventBodyField {
  Name: string; //Name of the field in the event body of that device model
  Optional: boolean;  //If the field is optional or not
  Min?: number;  //If the field is a number, which is the minimum value
  Max?: number | string; //If the field is a number, which is the maximum value (string if you want to refer to max by naming another field in the body event)
  MaxOffset?: number;  //When Max is a string and refers to another property, this is the offset to add to the value of that property
  DecimalsCount?: number;  //For random event generation: if the field is a number, how many decimals
  RangeMandatory: EventRangeMandatory;  //Between min and max which are required (min, max or both)
  Ancestors?: FieldAncestor[];  //Route of the field
  MapTo: string;  //The name of the property which this field will be mapped to
  Type: EventRandomType; //For random event generation: the type of field (random value, random percentage or default value)
  Default?: number | string; //For random event generation: if Type is Default value, what is that value
  Defaults?: number[] | string[];  //For random event generation: if type is Default value and there are multiple values to generate for that field, what are those values
  RandomValuesCount?: number; //For random event generation: how many values to generate for that field
}

export interface FieldAncestor {
  Name: string;
  IsArray: boolean;
}

export interface FieldValue {
  Field: EventBodyField;
  Value: string | number;
}

export type FieldsGroup = FieldValue[];

export enum EventRangeMandatory {
  None = "None",
  Min = "Min",
  Max = "Max",
  Both = "Both"
}

export enum EventRandomType{
  NotRandom = "NotRandom",
  RandomPercentage = "RandomPercentage",
  RandomValue = "RandomValue"
}

