<div class="dialogContainer">
    <h3 class="title3 mb-3">{{'DEVICE_NOTIFICATION.TITLE' | translate }}</h3>

    <form [formGroup]="detailGroup">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'GENERAL.ID' | translate }}</mat-label>
            <input matInput formControlName="id" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'GENERAL.DEVICE_ID' | translate }}</mat-label>
            <input matInput formControlName="deviceId" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'GENERAL.DEVICE_COMMAND_ID' | translate }}</mat-label>
            <input matInput formControlName="deviceCommandId" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'GENERAL.DEVICE_COMMAND_TYPE' | translate }}</mat-label>
            <input matInput formControlName="deviceCommandType" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'GENERAL.CREATED' | translate }}</mat-label>
            <input matInput formControlName="created" autocomplete="off" readonly>
        </mat-form-field>
    </form>

    <div class="mb-4">
        <div class="myErrorSection warnColorBG d-flex justify-content-center" *ngIf="showError">
            <span style="white-space: pre-wrap" class="p-3 m-0">{{ this.deviceCommandJson?.Error }}</span>
        </div>
        <div class="myMessageSection panelAccentBG d-flex justify-content-center" *ngIf="showBody">
            <span style="white-space: pre-wrap" class="p-3 m-0">{{ this.deviceCommandJson?.Body }}</span>
        </div>
    </div>

    <button mat-button class="btn outlineActionButton" [mat-dialog-close]="true">{{'GENERAL.CLOSE' | translate }}</button>
</div>