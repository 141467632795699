<div class="seeAllDataBtn">
    <button mat-button class="btn outlineActionButton" (click)="goToRejectedEventsPage()">
        {{ 'GENERAL.SHOW_DETAILS' | translate }}
    </button>
</div>

<div class="customContainer position-relative">
    <div [ngClass]="{
        'finalHeight': dataReady,
        'initialHeight': !dataReady && !noDataAvailable
      }">
        <div *ngIf="dataReady" class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center col-6">
                <div>
                    <i class="urbanicon-rejected-event"></i>
                </div>
                <div class="d-flex flex-column">
                    <span class="subheadText mb-2">
                        {{ 'GENERAL.LAST_24H' | translate }}
                    </span>

                    <h2>
                        {{eventsLast24h}}
                    </h2>
                </div>
            </div>

            <div class="d-flex align-items-center col-6">
                <div>
                    <i class="urbanicon-rejected-event"></i>
                </div>
                <div class="d-flex flex-column">
                    <span class="subheadText mb-2">
                        {{ 'FILTER.LAST_48H' | translate }}
                    </span>

                    <h2>
                        {{eventsLast48h}}
                    </h2>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="noDataAvailable" class="noData">
        <p class="paragraphText">{{ 'GENERAL.NO_DATA' | translate }}</p>
    </div>

    <urban-loader [localized]="true" [disappearCondition]="dataReady || noDataAvailable"></urban-loader>
</div>