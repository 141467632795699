<div class="dialogContainer">
    <h3 class="title3 mb-3">{{ 'GENERAL.ADD' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.COMPANY_NAME' | translate }}</mat-label>
            <input matInput formControlName="companyName" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.ADDRESS' | translate }}</mat-label>
            <input matInput formControlName="address" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.ZIP_CODE' | translate }}</mat-label>
            <input matInput formControlName="zip" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.CITY' | translate }}</mat-label>
            <input matInput formControlName="city" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.STATE' | translate }}</mat-label>
            <input matInput formControlName="state" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.COUNTRY' | translate }}</mat-label>
            <input matInput formControlName="country" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.VAT' | translate }}</mat-label>
            <input matInput formControlName="vat" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.PHONE' | translate }}</mat-label>
            <input matInput formControlName="phone" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.FAX' | translate }}</mat-label>
            <input matInput formControlName="fax" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.WEB' | translate }}</mat-label>
            <input matInput formControlName="web" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.EMAIL' | translate }}</mat-label>
            <input matInput formControlName="email" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.FIRSTNAME' | translate }}</mat-label>
            <input matInput formControlName="firstname" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.LASTNAME' | translate }}</mat-label>
            <input matInput formControlName="lastname" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.TIMEZONE' | translate }}</mat-label>
            <input matInput formControlName="timezone" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customTextArea mb-3">
            <mat-label>{{ 'GENERAL.CUSTOM_CSS' | translate }}</mat-label>
            <textarea   matInput formControlName="customCss"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="15"
                        autocomplete="off">
            </textarea>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'SMSS.MAX_ACTIVE_CAMPAIGNS' | translate }}</mat-label>
            <input matInput formControlName="maxCampaigns" type="number" min="0" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'SMSS.PUBLIC_ADV_CAMPAIGNS' | translate }}</mat-label>
            <input matInput formControlName="publicAdvCampaigns" type="number" min="0" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'SMSS.ERP_CODE' | translate }}</mat-label>
            <input matInput formControlName="erpCode" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customTextArea mb-3">
            <mat-label>{{ 'GENERAL.NOTES' | translate }}</mat-label>
            <textarea   matInput formControlName="notes"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="15"
                        autocomplete="off">
        </textarea>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'SMSS.LOCKED' | translate }}</mat-label>
            <mat-select formControlName="locked" hideSingleSelectionIndicator="true">
                <mat-option value="false">
                    {{ 'GENERAL.FALSE' | translate }}
                </mat-option>
                <mat-option value="true">
                    {{ 'GENERAL.TRUE' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="addition()">{{ 'GENERAL.ADD' | translate }}</button>
        <button mat-button class="btn outlineActionButton w-50 ml-1" mat-dialog-close>{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>