import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EventBodyField, EventRandomType, EventRangeMandatory, FieldAncestor } from '../../models/administrator/eventBody';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { timer } from 'rxjs';

@Component({
  selector: 'urban-device-model-event-body-dialog',
  templateUrl: './device-model-event-body-dialog.component.html',
  styleUrls: ['./device-model-event-body-dialog.component.scss']
})
export class DeviceModelEventBodyDialogComponent implements OnInit {
  @ViewChild('errorMessage') public errorMessage: ElementRef;

  private eventBodyField: EventBodyField;
  public form: UntypedFormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    optional: [false],
    min: [''],
    max: [''],
    maxOffset: [''],
    decimalsCount: ['', Validators.min(0)],
    rangeMandatory: [EventRangeMandatory.None, Validators.required],
    path: [''],
    mapTo: [''],
    type: [EventRandomType.NotRandom, Validators.required],
    default: [''],
    defaults: [''],
    randomValuesCount: ['', Validators.min(2)],
  });
  public isAdd: boolean = true;

  public rangeMandatoryEnum: typeof EventRangeMandatory = EventRangeMandatory;
  public rangeMandatoryOptions: string[] = Object.keys(EventRangeMandatory);
  public randomTypeEnum: typeof EventRandomType = EventRandomType;
  public randomTypeOptions: string[] = Object.keys(EventRandomType);

  private maxLimited: boolean = false;
  public minRequired: boolean = false;
  public maxRequired: boolean = false;
  public showHint: 'default' | 'defaults' | 'random';
  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<DeviceModelEventBodyDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { field: EventBodyField }
  ) {
    if (this.data?.field) {
      this.eventBodyField = this.data.field;
      this.isAdd = false;
    }
  }

  ngOnInit(): void {
    this.setFormChangesControllers();

    if (this.eventBodyField) {
      this.form.patchValue({
        name: this.eventBodyField.Name,
        optional: this.eventBodyField.Optional,
        min: this.eventBodyField.Min,
        max: this.eventBodyField.Max,
        maxOffset: this.eventBodyField.MaxOffset,
        decimalsCount: this.eventBodyField.DecimalsCount,
        mapTo: this.eventBodyField.MapTo,
        default: this.eventBodyField.Default,
        randomValuesCount: this.eventBodyField.Name,
      });
      if (this.eventBodyField.Type) {
        this.form.controls.type.setValue(this.eventBodyField.Type)
      }
      if (this.eventBodyField.RangeMandatory) {
        this.form.controls.rangeMandatory.setValue(this.eventBodyField.RangeMandatory)
      }
      if (this.eventBodyField.Ancestors?.length > 0) {
        this.form.controls.path.setValue(this.eventBodyField.Ancestors
        .map((ancestor: FieldAncestor) => ancestor.IsArray ?
          (ancestor.Name + '[]') : ancestor.Name).join('/'));
      }
      if (this.eventBodyField.Defaults?.length > 0) {
        this.form.controls.defaults.setValue(this.eventBodyField.Ancestors.join(', '));
      }
    }
  }

  public saveEventBody(): void {
    if (this.form.valid) {
      let minValue: number = this.form.controls.min.value;
      let maxValue: string = this.form.controls.max.value;
      let maxOffsetValue: number = this.form.controls.maxOffset.value;
      let pathValue: string = this.form.controls.path.value;

      this.eventBodyField = {
        Name: this.form.controls.name.value,
        Optional: this.form.controls.optional.value,
        RangeMandatory: this.form.controls.rangeMandatory.value,
        MapTo: this.form.controls.mapTo.value,
        Type: this.form.controls.type.value
      };

      //Min and Max
      switch(this.eventBodyField.RangeMandatory) {
        case EventRangeMandatory.Min:
          this.eventBodyField.Min = typeof minValue === 'number' ? minValue :
            ((maxValue && !isNaN(+maxValue) && +maxValue <= 0) ? (+maxValue - 1) : 0 );
          break;
        case EventRangeMandatory.Max:
          this.eventBodyField.Max = maxValue ? (!isNaN(+maxValue) ? +maxValue : maxValue) :
            (this.eventBodyField.Min >= 0 ? this.eventBodyField.Min + 1 : 0);
          break;
        default:
          break;
      }
      if (this.eventBodyField.RangeMandatory === EventRangeMandatory.Both ||
        this.eventBodyField.Type === EventRandomType.RandomValue
      ) {
        this.eventBodyField.Min = typeof minValue === 'number' ? minValue :
          ((maxValue && !isNaN(+maxValue) && +maxValue <= 0) ? (+maxValue - 1) : 0 );
        this.eventBodyField.Max = maxValue ? (!isNaN(+maxValue) ? +maxValue : maxValue) :
          (this.eventBodyField.Min >= 0 ? (this.eventBodyField.Min + 1) : 0);
      }
      //Max offset
      if (typeof maxOffsetValue === 'number' && maxValue && isNaN(+maxValue)) {
        this.eventBodyField.MaxOffset = maxOffsetValue;
      }
      //Decimals count
      if (this.eventBodyField.Type === EventRandomType.RandomValue) {
        let decimalsCount: number = this.form.controls.decimalsCount.value;
        this.eventBodyField.DecimalsCount = typeof decimalsCount === 'number' ? decimalsCount : 0;
      }
      //Ancestors
      if (pathValue) {
        this.eventBodyField.Ancestors = pathValue.split('/')
        .map((ancestorName: string) => ({
          Name: ancestorName.replace('[]', ''),
          IsArray: ancestorName.includes('[]')
        }));
      }
      //Default or defaults
      if (this.eventBodyField.Type === EventRandomType.NotRandom) {
        let defaultValue: string = this.form.controls.default.value;
        let defaultsValue: string = this.form.controls.defaults.value;
        if (!defaultValue || !isNaN(+defaultValue)) {
          this.eventBodyField.Default = !isNaN(+defaultValue) ? +defaultValue : defaultValue;
        }
        else if (defaultsValue) {
          if (defaultsValue.includes(',')) {
            let defaults: string[] = defaultsValue.split(',');
            this.eventBodyField.Defaults = defaults
            .every((value: string) => !isNaN(+value)) ?
              defaults.map((value: string) => +value) as number[] : defaults;
          }
          else {
            this.eventBodyField.Default = !isNaN(+defaultsValue) ? +defaultsValue : defaultsValue;
          }
        }
        else {
          this.eventBodyField.Default = 0;
        }
      }
      //Random values count
      else {
        let randomCount: number = this.form.controls.randomValuesCount.value;
        if (typeof randomCount === 'number' && randomCount > 1) {
          this.eventBodyField.RandomValuesCount = randomCount;
        }
      }

      this.dialogRef.close(this.eventBodyField);
    }
    else {
      this.error = "ERROR_EMPTY";
      timer(0).subscribe(() => {
        this.errorMessage?.nativeElement.scrollIntoView({ behavior: 'smooth' })
      });
    }
  }

  private setFormChangesControllers(): void {
    this.form.controls.type.valueChanges.subscribe(value => {
      if (value === EventRandomType.NotRandom) {
        if (!this.form.controls.defaults.value) {
          this.form.controls.default.enable();
        }
        else {
          this.showHint = 'defaults';
        }
        if (!this.form.controls.default.value) {
          this.form.controls.defaults.enable();
        }
        this.form.controls.randomValuesCount.enable();
      }
      else {
        this.form.controls.default.disable();
        this.form.controls.defaults.disable();
        this.form.controls.randomValuesCount.disable();
        this.showHint = 'random';
      }
    });
    this.form.controls.default.valueChanges.subscribe(value => {
      if (value) {
        if (this.form.controls.defaults.enabled) {
          this.form.controls.defaults.disable();
        }
        this.showHint = 'defaults';
      }
      else if (this.form.controls.defaults.disabled &&
        this.form.controls.type.value === EventRandomType.NotRandom
      ) {
        this.form.controls.defaults.enable();
        this.showHint = undefined;
      }
    });
    this.form.controls.defaults.valueChanges.subscribe(value => {
      if (value) {
        if (this.form.controls.default.enabled) {
          this.form.controls.default.disable();
        }
        this.showHint = 'default';
      }
      else if (this.form.controls.default.disabled &&
        this.form.controls.type.value === EventRandomType.NotRandom
      ) {
        this.form.controls.default.enable();
        this.showHint = undefined;
      }
    });
    this.form.controls.min.valueChanges.subscribe(minValue => {
      if (typeof minValue === 'number') {
        this.form.controls.max.setValidators(Validators.min(minValue + Math.pow(10, -6)));
        this.form.controls.max.updateValueAndValidity();
        this.maxLimited = true;
        // let maxValue: string = this.form.controls.max.value;
        // this.fixMinMax(minValue, maxValue, 'max');
      }
      else if (this.maxLimited) {
        // before removing min validator, updated it to make input error work properly
        this.form.controls.max.setValidators(Validators.min(-Infinity));
        this.form.controls.max.removeValidators(Validators.min(-Infinity));
        this.form.controls.max.updateValueAndValidity();
        this.maxLimited = false;
      }
    });
    this.form.controls.max.valueChanges.subscribe((maxValue: string) => {
      if (maxValue && (!isNaN(+maxValue[0]) || (maxValue[0] === '-' && !isNaN(+maxValue[1])))) {
        let stringToCheck: string = maxValue;
        let offset: number = 0;
        if (maxValue[0] === '-') {
          stringToCheck = stringToCheck.slice(1);
          offset++;
        }
        let pointIndex: number = stringToCheck.indexOf('.');
        if (pointIndex !== -1) {
          offset = pointIndex + 1;
          stringToCheck = stringToCheck.slice(pointIndex + 1);
        }
        let regExp: RegExp = new RegExp('[^  0-9]');
        let firstCharIndex: number = stringToCheck.search(regExp);
        if (firstCharIndex !== -1) {
          this.form.controls.max.setValue(maxValue.slice(0, firstCharIndex + offset))
        }
      }
    });
    this.form.controls.rangeMandatory.valueChanges.subscribe(value => {
      switch (value) {
        case EventRangeMandatory.Min:
          this.minRequired = true;
          this.maxRequired = false;
          this.form.controls.max.removeValidators(Validators.required);
          this.form.controls.min.addValidators(Validators.required);
          this.setMinIfNull();
          break;
        case EventRangeMandatory.Max:
          this.minRequired = false;
          this.maxRequired = true;
          this.form.controls.min.removeValidators(Validators.required);
          this.form.controls.max.addValidators(Validators.required);
          this.setMaxIfNull();
          break;
        case EventRangeMandatory.Both:
          this.minRequired = true;
          this.maxRequired = true;
          this.form.controls.min.addValidators(Validators.required);
          this.form.controls.max.addValidators(Validators.required);
          this.setMinIfNull();
          this.setMaxIfNull();
          break;
        default:
          this.minRequired = false;
          this.maxRequired = false;
          this.form.controls.min.removeValidators(Validators.required);
          this.form.controls.max.removeValidators(Validators.required);
          break;
      }
      this.form.controls.min.updateValueAndValidity();
      this.form.controls.max.updateValueAndValidity();
    });
  }

  private setMinIfNull(): void {
    if (typeof this.form.controls.min.value !== 'number') {
      let maxValue: string = this.form.controls.max.value;
      let newValue: number = (!maxValue || isNaN(+maxValue) || +maxValue > 0) ?
        0 : (+maxValue - 1);
      this.form.controls.min.setValue(newValue);
    }
  }

  private setMaxIfNull(): void {
    if (!this.form.controls.max.value) {
      let minValue: number = this.form.controls.min.value;
      let newValue: string = (typeof minValue !== 'number' || minValue < 0) ?
        '0' : (minValue + 1).toString();
      this.form.controls.max.setValue(newValue);
    }
  }
}
