<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }}</h3>
    <h3 class="title3 mb-3" *ngIf="!this.isAdd">{{ 'GENERAL.DETAIL' | translate }}</h3>
    
    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customTextArea mb-3">
            <mat-label>{{ 'GENERAL.DESCRIPTION' | translate }}</mat-label>
            <textarea   matInput formControlName="description"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="5" 
                        autocomplete="off">
            </textarea>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.LANGUAGE' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" formControlName="language">
                <mat-option *ngFor="let language of languages" value={{language.Id}}>
                    {{language.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'CONTENT_MANAGEMENT.BROADCAST_CLIENT' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" formControlName="broadcastClient">
                <mat-option *ngFor="let broadcastClientElement of broadcastClients" value={{broadcastClientElement.Id}}>
                    {{broadcastClientElement.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
    
    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="this.isAdd" (click)="addition()">
            {{ 'GENERAL.ADD' | translate }}
        </button>
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="!this.isAdd" (click)="addition()">
            {{ 'GENERAL.UPDATE' | translate }}
        </button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>
