import { AddServiceTypeRequest, ServiceType } from 'src/app/shared/models/service/service';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'urban-service-type-dialog',
  templateUrl: './service-type-dialog.component.html',
  styleUrls: ['./service-type-dialog.component.scss']
})
export class ServiceTypeDialogComponent implements OnInit {

  public addView: boolean = true;
  public serviceType: ServiceType;
  public error: boolean;
  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required],
    icon: [{ value: '', disabled: false }, Validators.required],
    color: [{ value: '', disabled: false }, Validators.required],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ServiceTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ServiceType
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.addView = false;
      this.serviceType = this.data;

      this.form.controls.name.setValue(this.serviceType.Name);
      this.form.controls.icon.setValue(this.serviceType.Icon);
      this.form.controls.color.setValue(this.serviceType.Color);
    }
  }

  public executeAction(): void {
    if (this.form.valid) {
      this.error = false;

      let formValues: AddServiceTypeRequest = {
        Name: this.form.controls.name.value,
        Icon: this.form.controls.icon.value,
        Color: this.form.controls.color.value
      }

      if (this.addView) {
        this.dialogRef.close(formValues);
      } else {
        this.serviceType = {
          ...formValues,
          Id: this.serviceType.Id
        }
        this.dialogRef.close(this.serviceType);
      }
    }
    else {
      this.error = true;
    }
  }

}
