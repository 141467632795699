import { Injectable } from '@angular/core';
import { EventParsedBody, EventBodyField, EventRangeMandatory, FieldAncestor, EventBody, FieldValue, FieldsGroup } from '../models/administrator/eventBody';
import { DeviceEventsEvent } from '../models/deviceEvent';
import { EventLatest } from '../models/deviceEventLatest';
import { DeviceModel } from '../models/deviceModel';
import { Device } from '../models/device';

@Injectable({
  providedIn: 'root'
})
export class EventBodyUtilityService {

  constructor() { }

  public initEventBody(devices: Device[]): Record<string, EventParsedBody> {
    let parsedBodies: Record<string, EventParsedBody> = {};
    let models: DeviceModel[] = [];

    devices.forEach(device => {
      if (!models.includes(device.Model)){
        models.push(device.Model);
      }
    });

    models.forEach(model => {
      let eventBody: EventBody

      try {
        eventBody = JSON.parse(model.EventBody);
      }
      catch {}

      if (eventBody) {
        let mapping: Record<string, EventBodyField> = {};
        eventBody.SingleFieldsToSet.forEach((field: EventBodyField) => {
          if (field.MapTo) {
            mapping[field.MapTo] = field;
          }
        });

        parsedBodies[model.Name] = {
          MandatorySingleFields: eventBody.SingleFieldsToSet,
          Mapping: mapping
        };
      }
    });

    return parsedBodies;
  }

  public getFieldMax(body: any, field: EventBodyField, maxOffset: number): number {
    let fieldMax: number;

    if (field === undefined) {
      return undefined;
    }

    let nestedValue: string | number = this.getNestedValue(body, field);
    if (nestedValue === undefined || typeof nestedValue !== 'number') {
      return undefined;
    }

    fieldMax = +nestedValue;

    if (maxOffset) {
      fieldMax += maxOffset;
    }

    return fieldMax;
  }

  public getNestedValue(body: any, field: EventBodyField): string | number {
    let fieldValue: any;

    if (body && field) {
      fieldValue = body;
      field.Ancestors?.forEach((ancestor: FieldAncestor) => {
        if (fieldValue[ancestor.Name]) {
          fieldValue = fieldValue[ancestor.Name];
          if (ancestor.IsArray && fieldValue[0] !== undefined) {
            fieldValue = fieldValue[0];
          }
        }
      });
      fieldValue = fieldValue[field.Name];
    }

    return fieldValue ? fieldValue : "";
  }

  public getNestedValuesArray(body: any, field: EventBodyField): (string | number)[] {
    let values: (string | number)[] = [];

    if (body && field) {
      let fieldValue: any = body;
      let ancestor: FieldAncestor = field.Ancestors?.[0];

      if (ancestor) {
        let newField: EventBodyField = {
          ...field,
          Ancestors: field.Ancestors.slice(1)
        };

        fieldValue = fieldValue[ancestor.Name];
        if (ancestor.IsArray && Array.isArray(fieldValue)) {
          fieldValue.forEach(arrayElem => {
            values.push(...this.getNestedValuesArray(arrayElem, newField));
          });
        }
        else {
          values.push(...this.getNestedValuesArray(fieldValue, newField));
        }
      }
      else {
        fieldValue = fieldValue[field.Name];
        values.push(fieldValue ? fieldValue : "");
      }
    }

    return values;
  }

  public setNestedValues(body: any, fieldsGroup: FieldsGroup): void {
    let tempObject: any = body;
    fieldsGroup[0].Field.Ancestors?.forEach((ancestor: FieldAncestor, index: number) => {
      if (ancestor.IsArray) {
        if (tempObject[ancestor.Name] === undefined) {
          tempObject[ancestor.Name] = [];
        }

        let newIndex: number;
        if (tempObject[ancestor.Name].length === 0) {
          tempObject[ancestor.Name].push({});
          newIndex = 0;
        }
        else if (index === fieldsGroup[0].Field.Ancestors.length - 1) {
          let indexFound: number = tempObject[ancestor.Name].findIndex(object =>
            fieldsGroup.every((fieldValue: FieldValue) => !Object.keys(object).includes(fieldValue.Field.Name)));
          if (indexFound === -1) {
            tempObject[ancestor.Name].push({});
            newIndex = tempObject[ancestor.Name].length - 1;
          }
          else {
            newIndex = indexFound;
          }
        }

        tempObject = tempObject[ancestor.Name][newIndex];
      }
      else {
        if (tempObject[ancestor.Name] === undefined) {
          tempObject[ancestor.Name] = {};
        }
        tempObject = tempObject[ancestor.Name];
      }
    });

    fieldsGroup.forEach((fieldValue: FieldValue) => {
      tempObject[fieldValue.Field.Name] = fieldValue.Value;
    })
  }

  public isAProperEvent(event: DeviceEventsEvent | EventLatest, deviceModel: DeviceModel, eventBodies: Record<string, EventParsedBody>): boolean {
    if (eventBodies) {
      let expectedBody: EventParsedBody = eventBodies[deviceModel.Name];
      if (expectedBody) {
        return event.Body &&
          (expectedBody.MandatorySingleFields.length === 0 || expectedBody.MandatorySingleFields.every((field: EventBodyField) => {
            let fieldValue: string | number = this.getNestedValue(event.Body, field);
            if (fieldValue === undefined && field.Optional !== true) {
              return false;
            }
            if (typeof fieldValue === 'string') {
              return true;
            }
            if (typeof fieldValue !== 'number') {
              return false;
            }
            if (field.RangeMandatory === EventRangeMandatory.None) {
              return true;
            }
            if (field.RangeMandatory !== EventRangeMandatory.Min && !field.Max && field.Max !== 0) {
              return false;
            }
            if (field.RangeMandatory !== EventRangeMandatory.Max && typeof field.Min !== 'number') {
              return false;
            }

            let fieldValueNumber: number = +fieldValue;
            let fieldMax: number;
            if (field.Max || field.Max === 0) {
              switch (typeof field.Max) {
                case 'string':
                  let foundField: EventBodyField = expectedBody.MandatorySingleFields.find((field: EventBodyField) => field.Name === field.Max);
                  fieldMax = this.getFieldMax(event.Body, foundField, field.MaxOffset);
                  if (fieldMax === undefined) {
                    return false;
                  }
                  break;
                case 'number':
                  fieldMax = field.Max;
                  break;
                default:
                  return false;
              }
            }

            switch (field.RangeMandatory) {
              case EventRangeMandatory.Min:
                return fieldValueNumber >= field.Min;
              case EventRangeMandatory.Max:
                return fieldValueNumber <= fieldMax;
              case EventRangeMandatory.Both:
                return fieldValueNumber >= field.Min && fieldValueNumber <= fieldMax;
              default:
                return true;
            }
          }
        ));
      }
    }

    return false;
  }
}
