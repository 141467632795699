<div class="dialogContainer">
    <h3 class="title3 mb-3">{{ 'GENERAL.ADD_EVENT' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.TYPE' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" required formControlName="type">
                <mat-option *ngFor="let type of types" value={{type}}>
                    {{type}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.LEVEL' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" required formControlName="level">
                <mat-option *ngFor="let level of levels" value={{level}}>
                    {{level}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customDatePicker mb-3">
            <mat-label>{{ 'GENERAL.DATE' | translate }}</mat-label>
            <input matInput [owlDateTime]="dt1" (dateChange)="setDate($event.target.value)"
            formControlName="created" [owlDateTimeTrigger]="dt1" readonly>

            <i [owlDateTimeTrigger]="dt1" matSuffix class="urbanicon-monitoring-outline pointer"></i>

            <owl-date-time #dt1></owl-date-time>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customTextArea mb-3">
            <mat-label>{{ 'GENERAL.BODY' | translate }}</mat-label>
            <textarea   matInput formControlName="body"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="10"
                        autocomplete="off">
            </textarea>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
        <mat-error *ngIf="invalidBody">
            {{ 'GENERAL.' + invalidBody | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="addition()">{{
            'GENERAL.ADD' | translate }}
        </button>
            
        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>