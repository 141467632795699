<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }}</h3>
    <h3 class="title3 mb-3" *ngIf="!this.isAdd">{{ 'GENERAL.UPDATE' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'ADVERTISING_TYPE.ATTACHMENT_TYPE' | translate }}</mat-label>
            <mat-select formControlName="attachmentType" required>
                <mat-option *ngFor="let option of attachmentTypeOptions" value="{{ option }}">
                    {{ attachmentTypeEnum[option] }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button *ngIf="this.isAdd" type="submit" class="btn actionButton w-50 mr-1" (click)="addition()">{{
            'GENERAL.ADD' | translate }}</button>
        <button mat-button *ngIf="!this.isAdd" type="submit" class="btn actionButton w-50 mr-1" (click)="addition()">{{
            'GENERAL.UPDATE' | translate }}</button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" mat-dialog-close>{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>
