<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="serviceTypes">{{ 'GENERAL.ADD' | translate }} {{ 'SERVICE_DETAIL.SERVICE' | translate | lowercase }}</h3>
    <h3 class="title3 mb-3" *ngIf="deviceGroups">{{ 'GENERAL.ADD' | translate }} {{ 'SERVICE_DIALOG.DEVICE_GROUP_TO_SERVICE' | translate | lowercase }}</h3>
    
    <div *ngIf="serviceTypes">
        <form [formGroup]="form">
            <mat-form-field appearance="outline" class="customMatFormField mb-3">
                <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
                <input matInput formControlName="name" autocomplete="off" required>
            </mat-form-field>
    
            <mat-form-field appearance="outline" class="customMatFormField mb-3">
                <mat-label>{{ 'GENERAL.DESCRIPTION' | translate }}</mat-label>
                <input matInput formControlName="description" autocomplete="off" required>
            </mat-form-field>
    
            <mat-form-field appearance="outline" class="customMatFormField mb-3">
                <mat-label>{{ 'GENERAL.TYPE' | translate }}</mat-label>
                <mat-select hideSingleSelectionIndicator="true" formControlName="type" required>
                    <mat-option *ngFor="let type of serviceTypes" [value]="type.Id">
                        {{type.Name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>

    <div *ngIf="deviceGroups">
        <form [formGroup]="serviceGroupForm">
            <mat-form-field appearance="outline" class="customMatFormField mb-3">
                <mat-label class="secondaryTextColor paragraphText">{{ 'SERVICE_DETAIL.GROUP' | translate }}</mat-label>
                <mat-select hideSingleSelectionIndicator="true" formControlName="group">
                    <mat-option *ngFor="let group of deviceGroups" [value]="group.Id">
                        <span class="paragraphText">{{group.Name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>

    <mat-error *ngIf="error">
        {{ 'GENERAL.ERROR_ALL_FIELDS_REQUIRED' | translate }}
    </mat-error>
    
    <div class="d-flex justify-content-between mt-3">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="executeAction()">
            {{ 'GENERAL.ADD' | translate }}
        </button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" mat-dialog-close>{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>