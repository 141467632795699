<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }}</h3>
    <h3 class="title3 mb-3" *ngIf="!this.isAdd">{{ 'GENERAL.DETAIL' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.LANGUAGE' | translate }}</mat-label>
            <mat-select required formControlName="languageSelected">
                <mat-option *ngFor="let language of languages" value={{language.Id}}>
                    {{language.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.TYPE' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" required (selectionChange)="loadTemplateTypeOptions()"
                        formControlName="templateTypeSelected">
                <mat-option *ngFor="let templateType of templateTypes" value={{templateType.Id}}>
                    {{templateType.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div *ngIf="this.options && this.hasLength(this.options)">
            <span class="subheadText d-block mb-2 ml-1">{{ 'GENERAL.OPTIONS' | translate }}</span>
            <mat-form-field appearance="outline" class="customMatFormField mb-3" *ngFor="let option of getKeys(this.options)">
                <mat-label>{{option}}</mat-label>
                <input matInput value="" id="{{option}}" type="text"
                    autocomplete="off" value="{{this.data.templateItem?.Options[option]}}" [required]="this.options[option]">
            </mat-form-field>
        </div>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.FORMAT' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" required formControlName="templateFormatSelected">
                <mat-option *ngFor="let templateFormat of templateFormats" value={{templateFormat.Id}}>
                    {{templateFormat.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customTextArea mb-3">
            <mat-label>{{ 'GENERAL.CONTENT' | translate }}</mat-label>
            <textarea   matInput formControlName="content"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="5"
                        cdkAutosizeMaxRows="10"  
                        required>
            </textarea>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="this.isAdd" (click)="addition()">{{ 'GENERAL.ADD' | translate }}</button>
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="!this.isAdd" (click)="addition()">{{ 'GENERAL.UPDATE' | translate }}</button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>
