import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SMSSCampaign } from '../../models/smss/smssCampaign';

@Component({
  selector: 'urban-campaign-dialog',
  templateUrl: './campaign-dialog.component.html',
  styleUrls: ['./campaign-dialog.component.scss']
})
export class CampaignDialogComponent implements OnInit {

  public form: UntypedFormGroup = this.formBuilder.group({
    description: ['', Validators.required],
    frequency: [''],
    timezone: [''],
    startDate: [''],
    endDate: [''],
    timetable: [0],
    domain: [''],
    gateway: [''],
    managerType: [''],
    url: [''],
    totalBudget: [0],
    dailyBudget: [0],
    clickPrice: [0],
    impressionPrice: [0],
    address: [''],
    country: [''],
    longitude: [''],
    latitude: [''],
    radiusInMeters: [0],
    notes: [''],
    locked: ['false'],
    portalIntroType: [''],
    portalIntroImageFromFileImgType: [''],
    portalIntroStaticImageURL: [''],
    portalIntroVideoPosterImgType: [''],
    portalIntroFlickrPhotoSet: [''],
    portalIntroYoutubeVideoID: [''],
    portalIntroExternalURL: [''],
    portalIntroURL: [''],
    portalIntroVideoURL: [''],
    portalIntroMinSeconds: [''],
    impressionTime: [0],
    topBannerImgType: [''],
    topBannerImgLink: [''],
    leftBannerImgType: [''],
    leftBannerImgLink: [''],
    rightBannerImgType: [''],
    rightBannerImgLink: [''],
    coinType: [''],
    coinSkipTime: [''],
    coinYoutubeVideoID: [''],
    coinExternalURLLink: [''],
    coinVideoURL: [''],
    coinVideoPosterImgType: [''],
    coinImage1Position: [''],
    coinImage1Size: [''],
    coinImage1Link: [''],
    coinImage1Type: [''],
    coinImage2Position: [''],
    coinImage2Size: [''],
    coinImage2Link: [''],
    coinImage2Type: [''],
    coinImage3Position: [''],
    coinImage3Size: [''],
    coinImage3Link: [''],
    coinImage3Type: [''],
    coinExternalURLPosition: [''],
    coinExternalURLSize: [''],
    portalIntroImageFromFileImg: [''],
    portalIntroVideoPosterImg: [''],
    coinVideoPosterImg: [''],
    topBannerImg: [''],
    leftBannerImg: [''],
    rightBannerImg: [''],
    coinImage1: [''],
    coinImage2: [''],
    coinImage3: ['']
  });

  public campaign: SMSSCampaign;
  public error: string | null;

  public maxDate: Date;

  public minDate: Date;

  private advertiserId: string = '';
  constructor(
    public dialogRef: MatDialogRef<CampaignDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        advertiser: string,
        campaign: SMSSCampaign
      }
  ) {
  }

  ngOnInit(): void {
    let now = new Date();
    this.minDate = now;
    this.maxDate = new Date(now.setDate(now.getDate() + 30));
    this.advertiserId = this.data.advertiser;

    if (this.data.campaign !== null && this.data.campaign !== undefined) {
      this.campaign = this.data.campaign;

      this.form.controls.description.setValue(this.campaign.Description);
      this.form.controls.frequency.setValue(this.campaign.Frequency);
      this.form.controls.timezone.setValue(this.campaign.TimeZone);
      this.form.controls.startDate.setValue(this.campaign.StartDate);
      this.form.controls.endDate.setValue(this.campaign.EndDate);
      this.form.controls.timetable.setValue(+this.campaign.Timetables);
      this.form.controls.domain.setValue(this.campaign.DomainID);
      this.form.controls.gateway.setValue(this.campaign.GatewayID);
      this.form.controls.managerType.setValue(this.campaign.ManagerTypeID);
      this.form.controls.url.setValue(this.campaign.URL);
      this.form.controls.totalBudget.setValue(+this.campaign.TotalBudget);
      this.form.controls.dailyBudget.setValue(+this.campaign.DailyBudget);
      this.form.controls.clickPrice.setValue(+this.campaign.ClickPrice);
      this.form.controls.impressionPrice.setValue(+this.campaign.ImpressionPrice);
      this.form.controls.address.setValue(this.campaign.Address);
      this.form.controls.country.setValue(this.campaign.Country);
      this.form.controls.longitude.setValue(this.campaign.Longitude);
      this.form.controls.latitude.setValue(this.campaign.Latitude);
      this.form.controls.radiusInMeters.setValue(+this.campaign.RadiusInMeters);
      this.form.controls.notes.setValue(this.campaign.Notes);
      this.form.controls.locked.setValue(this.campaign.Locked);;
      this.form.controls.portalIntroType.setValue(this.campaign.PortalIntroType);
      this.form.controls.portalIntroImageFromFileImgType.setValue(this.campaign.PortalIntroImageFromFileImgType);
      this.form.controls.portalIntroStaticImageURL.setValue(this.campaign.PortalIntroStaticImageURL);
      this.form.controls.portalIntroVideoPosterImgType.setValue(this.campaign.PortalIntroVideoPosterImgType);
      this.form.controls.portalIntroFlickrPhotoSet.setValue(this.campaign.PortalIntroFlickrPhotoSet);
      this.form.controls.portalIntroYoutubeVideoID.setValue(this.campaign.PortalIntroYoutubeVideoID);
      this.form.controls.portalIntroExternalURL.setValue(this.campaign.PortalIntroExternalURL);
      this.form.controls.portalIntroURL.setValue(this.campaign.PortalIntroURL);
      this.form.controls.portalIntroVideoURL.setValue(this.campaign.PortalIntroVideoURL);
      this.form.controls.portalIntroMinSeconds.setValue(this.campaign.PortalIntroMinSeconds);
      this.form.controls.impressionTime.setValue(+this.campaign.ImpressionTime);
      this.form.controls.topBannerImgType.setValue(this.campaign.TopBannerImgType);
      this.form.controls.topBannerImgLink.setValue(this.campaign.TopBannerImgLink);
      this.form.controls.leftBannerImgType.setValue(this.campaign.LeftBannerImgType);
      this.form.controls.leftBannerImgLink.setValue(this.campaign.LeftBannerImgLink);
      this.form.controls.rightBannerImgType.setValue(this.campaign.RightBannerImgType);
      this.form.controls.rightBannerImgLink.setValue(this.campaign.RightBannerImgLink);
      this.form.controls.coinType.setValue(this.campaign.COINType);
      this.form.controls.coinSkipTime.setValue(this.campaign.COINSkipTime);
      this.form.controls.coinYoutubeVideoID.setValue(this.campaign.COINYoutubeVideoID);
      this.form.controls.coinExternalURLLink.setValue(this.campaign.COINExternalURLLink);
      this.form.controls.coinVideoURL.setValue(this.campaign.COINVideoURL);
      this.form.controls.coinVideoPosterImgType.setValue(this.campaign.COINVideoPosterImgType);
      this.form.controls.coinImage1Position.setValue(this.campaign.COINImage1Position);
      this.form.controls.coinImage1Size.setValue(this.campaign.COINImage1Size);
      this.form.controls.coinImage1Link.setValue(this.campaign.COINImage1Link);
      this.form.controls.coinImage1Type.setValue(this.campaign.COINImage1Type);
      this.form.controls.coinImage2Position.setValue(this.campaign.COINImage2Position);
      this.form.controls.coinImage2Size.setValue(this.campaign.COINImage2Size);
      this.form.controls.coinImage2Link.setValue(this.campaign.COINImage2Link);
      this.form.controls.coinImage2Type.setValue(this.campaign.COINImage2Type);
      this.form.controls.coinImage3Position.setValue(this.campaign.COINImage3Position);
      this.form.controls.coinImage3Size.setValue(this.campaign.COINImage3Size);
      this.form.controls.coinImage3Link.setValue(this.campaign.COINImage3Link);
      this.form.controls.coinImage3Type.setValue(this.campaign.COINImage3Type);
      this.form.controls.coinExternalURLPosition.setValue(this.campaign.COINExternalURLPosition);
      this.form.controls.coinExternalURLSize.setValue(this.campaign.COINExternalURLSize);
      this.form.controls.portalIntroImageFromFileImg.setValue(this.campaign.PortalIntroImageFromFileImg);
      this.form.controls.portalIntroVideoPosterImg.setValue(this.campaign.PortalIntroVideoPosterImg);
      this.form.controls.coinVideoPosterImg.setValue(this.campaign.COINVideoPosterImg);
      this.form.controls.topBannerImg.setValue(this.campaign.TopBannerImg);
      this.form.controls.leftBannerImg.setValue(this.campaign.LeftBannerImg);
      this.form.controls.rightBannerImg.setValue(this.campaign.RightBannerImg);
      this.form.controls.coinImage1.setValue(this.campaign.COINImage1);
      this.form.controls.coinImage2.setValue(this.campaign.COINImage2);
      this.form.controls.coinImage3.setValue(this.campaign.COINImage3);
    }
  }

  public addition(): void {
    if (this.form.valid) {
      this.campaign = {
        AdvertiserId: this.advertiserId,
        Description: this.form.controls.description.value,
        TimeZone: this.form.controls.timezone.value,
        StartDate: this.form.controls.startDate.value,
        EndDate: this.form.controls.endDate.value,
        Timetables: this.form.controls.timetables.value.toString(),
        DomainID: this.form.controls.domain.value,
        GatewayID: this.form.controls.gateway.value,
        ManagerTypeID: this.form.controls.managerType.value,
        URL: this.form.controls.url.value,
        TotalBudget: this.form.controls.totalBudget.value.toString(),
        DailyBudget: this.form.controls.dailyBudget.value.toString(),
        ClickPrice: this.form.controls.clickPrice.value.toString(),
        ImpressionPrice: this.form.controls.impressionPrice.value.toString(),
        Address: this.form.controls.address.value,
        City: this.form.controls.city.value,
        Country: this.form.controls.country.value,
        Longitude: this.form.controls.longitude.value,
        Latitude: this.form.controls.latitude.value,
        RadiusInMeters: this.form.controls.radiusInMeters.value.toString(),
        Notes: this.form.controls.notes.value,
        Locked: this.form.controls.locked.value,
        PortalIntroType: this.form.controls.portalIntroType.value,
        PortalIntroImageFromFileImgType: this.form.controls.portalIntroImageFromFileImgType.value,
        PortalIntroStaticImageURL: this.form.controls.portalIntroStaticImageURL.value,
        PortalIntroVideoPosterImgType: this.form.controls.portalIntroVideoPosterImgType.value,
        PortalIntroFlickrPhotoSet: this.form.controls.portalIntroFlickrPhotoSet.value,
        PortalIntroYoutubeVideoID: this.form.controls.portalIntroYoutubeVideoID.value,
        PortalIntroExternalURL: this.form.controls.portalIntroExternalURL.value,
        PortalIntroURL: this.form.controls.portalIntroURL.value,
        PortalIntroVideoURL: this.form.controls.portalIntroVideoURL.value,
        PortalIntroMinSeconds: this.form.controls.portalIntroMinSeconds.value,
        ImpressionTime: this.form.controls.impressionTime.value.toString(),
        TopBannerImgType: this.form.controls.topBannerImgType.value,
        TopBannerImgLink: this.form.controls.topBannerImgLink.value,
        LeftBannerImgType: this.form.controls.leftBannerImgType.value,
        LeftBannerImgLink: this.form.controls.leftBannerImgLink.value,
        RightBannerImgType: this.form.controls.rightBannerImgType.value,
        RightBannerImgLink: this.form.controls.rightBannerImgLink.value,
        COINType: this.form.controls.coinType.value,
        COINSkipTime: this.form.controls.coinSkipTime.value,
        COINYoutubeVideoID: this.form.controls.coinYoutubeVideoID.value,
        COINExternalURLLink: this.form.controls.coinExternalURLLink.value,
        COINVideoURL: this.form.controls.coinVideoURL.value,
        COINVideoPosterImgType: this.form.controls.coinVideoPosterImgType.value,
        COINImage1Position: this.form.controls.coinImage1Position.value,
        COINImage1Size: this.form.controls.coinImage1Size.value,
        COINImage1Link: this.form.controls.coinImage1Link.value,
        COINImage1Type: this.form.controls.coinImage1Type.value,
        COINImage2Position: this.form.controls.coinImage2Position.value,
        COINImage2Size: this.form.controls.coinImage2Size.value,
        COINImage2Link: this.form.controls.coinImage2Link.value,
        COINImage2Type: this.form.controls.coinImage2Type.value,
        COINImage3Position: this.form.controls.coinImage3Position.value,
        COINImage3Size: this.form.controls.coinImage3Size.value,
        COINImage3Link: this.form.controls.coinImage3Link.value,
        COINImage3Type: this.form.controls.coinImage3Type.value,
        COINExternalURLPosition: this.form.controls.coinExternalURLPosition.value,
        COINExternalURLSize: this.form.controls.coinExternalURLSize.value,
        PortalIntroImageFromFileImg: this.form.controls.portalIntroImageFromFileImg.value,
        PortalIntroVideoPosterImg: this.form.controls.portalIntroVideoPosterImg.value,
        COINVideoPosterImg: this.form.controls.coinVideoPosterImg.value,
        TopBannerImg: this.form.controls.topBannerImg.value,
        LeftBannerImg: this.form.controls.leftBannerImg.value,
        RightBannerImg: this.form.controls.rightBannerImg.value,
        COINImage1: this.form.controls.coinImage1.value,
        COINImage2: this.form.controls.coinImage2.value,
        COINImage3: this.form.controls.coinImage3.value
      }

      this.dialogRef.close(this.campaign)
    }
  }
}

