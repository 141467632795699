<div class="dialogContainer">
    <h3 class="title3 mb-3">{{ 'CONTENT_MANAGEMENT.CONTENT_DATA' | translate }}</h3>

    <div class="dialogContent overflow-auto">
        <ul *ngIf="contentDataModel && contentData">
            <ng-container *ngFor="let property of contentDataModel" [ngTemplateOutlet]="treeNode"
                [ngTemplateOutletContext]="{ $implicit: property, data: contentData }">
            </ng-container>
        </ul>
    </div>

    <div class="d-flex justify-content-between">
        <button mat-button class="btn actionButton w-50 mr-1" (click)="saveChanges()">
            {{ 'GENERAL.SAVE' | translate }}
        </button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" mat-dialog-close>
            {{ 'GENERAL.CANCEL' | translate }}
        </button>
    </div>
    
    <ng-template #treeNode let-field let-data="data" let-isSchema="isSchema" let-schemaData="schemaData">
        <li class="mt-3 d-flex flex-row align-items-start">
            <h6 *ngIf="((!field.type || ['object', 'object[]'].includes(field.type)) && field.children) ||
                (field.type.includes('[]') && field.type !== 'object[]' || field.type === 'schemaMatrix')">
                {{ field.name }}
            </h6>
    
            <ng-container *ngIf="field.type && field.type !== 'object' && !field.type.includes('[]')">
                <mat-form-field appearance="outline" class="customMatFormField mb-3" *ngIf="field.type === 'number'" style="width: 400px">
                    <mat-label>{{ field.name }}</mat-label>
                    <input matInput type="number" (change)="correctFieldNumberType(data, field.name)" [(ngModel)]="data[field.name]" />
                </mat-form-field>
    
                <mat-checkbox *ngIf="field.type === 'boolean'" class="mb-3" labelPosition="left" [(ngModel)]="data[field.name]">
                    {{ field.name }}
                </mat-checkbox>
    
                <mat-form-field appearance="outline" class="customTextArea mb-3 w-75" *ngIf="!['number', 'boolean', 'schemaMatrix'].includes(field.type)">
                    <mat-label>{{ field.name }}</mat-label>
                    <textarea   *ngIf="!isSchema" matInput [(ngModel)]="data[field.name]"                         
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="15"
                                autocomplete="off">
                    </textarea>

                    <textarea   *ngIf="isSchema" matInput [value]="data[field.name]"
                                (change)="updateSchemaProperty($event.target.value.trim(), data, field.name, schemaData)"                         
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="15"
                                autocomplete="off">
                    </textarea>
                </mat-form-field>
            </ng-container>
        </li>
        <p *ngIf="field.type==='schemaMatrix' && schema === undefined">
            {{ 'CONTENT_MANAGEMENT.SCHEMA_NECESSARY' | translate }}
        </p>
    
        <ul class="mb-5" *ngIf="field.type && (field.type.includes('[]') && (field.children || field.type !== 'object[]') || field.type === 'schemaMatrix')"> <!-- data is array -->
            <li *ngFor="let item of data[field.name]; let i = index; trackBy: trackByFn" class="d-flex flex-row gap-1 align-items-center">
                <ul *ngIf="field.type === 'object[]' && field.children" class="pl-3 pr-4 mt-2 mb-2" [ngClass]="isDarkActive ? 'objectArrayCardDark' : 'objectArrayCardLight'">
                    <ng-container *ngFor="let child of field.children; let childIndex = index" [ngTemplateOutlet]="treeNode"
                        [ngTemplateOutletContext]="field.name === 'schema' ? { $implicit: child, data: item, isSchema: true, schemaData: data[field.name] } :
                        { $implicit: child, data: item }">
                    </ng-container>
                </ul>
    
                <ul *ngIf="field.type === 'schemaMatrix'" class="pl-3 pr-4 mt-2 mb-2" [ngClass]="isDarkActive ? 'objectArrayCardDark' : 'objectArrayCardLight'">
                    <ng-container *ngFor="let singleValue of item; let j = index; trackBy: trackByFn">
                        <ng-container *ngIf="schema?.[j]?.type">
                            <mat-form-field appearance="outline" class="customMatFormField mb-3" *ngIf="schema[j].type === 'double'" style="width: 400px">
                                <mat-label>{{ schema[j].name }}</mat-label>
                                <input matInput type="number" (change)="correctFieldNumberType(item, j)"
                                    [(ngModel)]="item[j]" />
                            </mat-form-field>
    
                            <mat-checkbox *ngIf="schema[j].type === 'bool'" class="mb-3 d-block" labelPosition="left" [(ngModel)]="item[j]">
                                {{ schema[j].name }}
                            </mat-checkbox>
    
                            <mat-form-field appearance="outline" class="customTextArea mb-3 w-75" *ngIf="schema[j].type === 'string'">
                                <mat-label>{{ schema[j].name }}</mat-label>
                                <textarea   matInput [(ngModel)]="item[j]"                                
                                            cdkTextareaAutosize
                                            #autosize="cdkTextareaAutosize"
                                            cdkAutosizeMinRows="1"
                                            cdkAutosizeMaxRows="15"
                                            autocomplete="off">
                                </textarea>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>
                </ul>
    
                <div #parentRef *ngIf="!['object[]', 'schemaMatrix'].includes(field.type)" class="mt-2 mb-2" [ngClass]="{'w-75': field.type !== 'number[]'}" >
                    <mat-form-field appearance="outline" class="customMatFormField" *ngIf="field.type === 'number[]'" style="width: 400px">
                        <input matInput type="number" (change)="correctFieldNumberType(data, field.name, i)" [(ngModel)]="data[field.name][i]"/>
                    </mat-form-field>
    
                    <mat-form-field appearance="outline" class="customTextArea" *ngIf="field.type !== 'number[]'">
                        <textarea   matInput [(ngModel)]="data[field.name][i]"                                
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="15"
                                    autocomplete="off">
                        </textarea>
                    </mat-form-field>
                </div>

                <button mat-button class="btn warnButton squareButton" (click)="removeArrayElement(data[field.name], i, field.name === 'schema' && field.type === 'object[]')"> - </button>
            </li>
            <button *ngIf="field.type !== 'schemaMatrix' || schema !== undefined" mat-button class="btn actionButton squareButton"
                (click)="addArrayElement(data[field.name], field)"> + </button>
        </ul>
    
        <ul *ngIf="(!field.type || field.type === 'object') && field.children"> <!-- data is object -->
            <ng-container *ngFor="let child of field.children" [ngTemplateOutlet]="treeNode"
                [ngTemplateOutletContext]="{ $implicit: child, data: data[field.name] }">
            </ng-container>
        </ul>
    </ng-template>
</div>