<div class="dialogContainer">
    <h3 class="title3">{{ 'STREAMING_DIALOG.TITLE' | translate }}</h3>
    <p class="paragraphText mb-4">{{ 'STREAMING_DIALOG.SUBTITLE' | translate }}</p>

    <form [formGroup]="form" class="d-flex justify-content-between mb-4">
        <mat-form-field appearance="outline" class="customMatFormField flex-grow-1 mr-3">
            <mat-label>{{ 'STREAMING_DIALOG.LINK' | translate }}</mat-label>
            <input matInput formControlName="link" autocomplete="off" readonly>
        </mat-form-field>

        <button id="smallCopyButton" mat-button type="submit" class="btn smallOutlineActionButton no-wrap-text"
            (click)="copyToClipboard()">
            <i class="urbanicon-copy"></i>
        </button>
    </form>

    <button mat-button class="btn outlineActionButton" mat-dialog-close>{{ 'GENERAL.CANCEL' | translate }}</button>
</div>