import { Component, Input, OnDestroy, SimpleChanges, OnChanges, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ChartDataList, PolarAreaChartData } from '../../models/ChartDataList';
import { Subject } from 'rxjs';
import { ArcElement, Chart, Legend, PolarAreaController, RadialLinearScale, Tooltip, } from 'chart.js';

@Component({
  selector: 'urban-polar-area-chart',
  templateUrl: './polar-area-chart.component.html',
  styleUrls: ['./polar-area-chart.component.scss']
})
export class PolarAreaChartComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input('polarAreaChartTitle') public chartTitle: string = '';
  @Input('passedData') private passedData: ChartDataList;
  @Input('dataDescriptions') private dataDescriptions: string[];
  @Input('passedSubtitle') public chartSubtitle: string = '';
  @Input('darkModeStatus') public isDarkActive: boolean;
  @ViewChild('polarAreaChartCanvas') chartCanvas: ElementRef;

  private chart: Chart<'polarArea', number[], string>;
  public noDataOnChart: boolean = false;
  private colors: string[] = this.getChartColors();
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor() {
    Chart.register(PolarAreaController, RadialLinearScale, ArcElement, Legend, Tooltip);
  }

  ngAfterViewInit(): void {
    this.drawChart();
  }

  private drawChart(): void {
    this.clearChart();

    if (this.passedData && this.dataDescriptions?.length > 0) {
      this.noDataOnChart = false;

      let chartData: PolarAreaChartData = {
        labels: this.dataDescriptions,
        datasets: [
          {
            label: "",
            backgroundColor: this.colors,
            data: Object.values(this.passedData)
          }
        ]
      };

      var canvas: HTMLCanvasElement = this.chartCanvas.nativeElement;
      var context: CanvasRenderingContext2D = canvas.getContext("2d");

      this.chart = new Chart(context, {
        type: 'polarArea',
        data: chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            arc: {
              borderColor: 'transparent'
            }
          },
          scales: {
            r: {
              grid: {
                 color: window.getComputedStyle(document.body).getPropertyValue('--custom-light-accent-text-color')
              },
              ticks: {
                color: this.isDarkActive ? window.getComputedStyle(document.body).getPropertyValue('--custom-light-primary-text-color') : 'black',
                backdropColor: 'transparent'
              }
            }
          },
          plugins: {
            legend: {
              align: "start",
              position: "right",
              labels: {
                color: window.getComputedStyle(document.body).getPropertyValue('--custom-dark-accent-text-color'),
                font: {
                  size: 11.2
                }
              }
            },
            
          }
        }
      });

    } else {
      this.noDataOnChart = true;
    }
  }

  private getChartColors(): string[] {
    let colors: string[] = [];

    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-red-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-yellow-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-green-color'));
    
    return colors;
  }

  private clearChart(): void {
    if(this.chart) {
      this.chart.destroy();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isDarkActive']) {
      const isDarkActive = changes['isDarkActive'];
      if (isDarkActive.currentValue !== isDarkActive.previousValue && isDarkActive.firstChange == false) {
        this.drawChart();
      }
    }

    if (changes['passedData']) {
      const passedData = changes['passedData'];
      if (passedData.currentValue !== passedData.previousValue && passedData.firstChange == false) {
        this.drawChart();
      }
    }

    if (changes['dataDescriptions']) {
      const dataDescriptions = changes['dataDescriptions'];
      if (dataDescriptions.currentValue !== dataDescriptions.previousValue && dataDescriptions.firstChange == false) {
        this.drawChart();
      }
    }
  }

  ngOnDestroy(): void {
    this.clearChart();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
