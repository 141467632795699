<div class="dialogContainer">
    <div class="d-flex justify-content-between align-items-center mb-3">
        <h3 class="title3">{{'STATISTICS_DIALOG.TITLE' | translate }}</h3>

        <div>
            <i *ngIf="myTraceItem.Error" class="urbanicon-alert" matTooltip="{{'STATISTICS.CHECK_ERROR' | translate }}" (click)="toggleErrorSection()"></i>
            <i *ngIf="myTraceItem.Message" class="urbanicon-sms-outline" matTooltip="{{'STATISTICS.CHECK_MESSAGE' | translate }}" (click)="toggleMessageSection()"></i>
        </div>
    </div>

    <form [formGroup]="detailGroup" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'STATISTICS.HOSTNAME' | translate }}</mat-label>
            <input matInput formControlName="hostname" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'STATISTICS.RESOURCE' | translate }}</mat-label>
            <input matInput formControlName="resource" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'STATISTICS.CREATED' | translate }}</mat-label>
            <input matInput formControlName="created" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'STATISTICS.MILLIS' | translate }}</mat-label>
            <input matInput formControlName="millis" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'STATISTICS.DOMAIN' | translate }}</mat-label>
            <input matInput formControlName="domain" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'STATISTICS.CLIENTIP' | translate }}</mat-label>
            <input matInput formControlName="clientIp" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'STATISTICS.SESSIONID' | translate }}</mat-label>
            <input matInput formControlName="sessionId" autocomplete="off"
                readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'STATISTICS.USERNAME' | translate }}</mat-label>
            <input matInput formControlName="username" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{'STATISTICS.METHOD' | translate }}</mat-label>
            <input matInput formControlName="method" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{'STATISTICS.CLASS' | translate }}</mat-label>
            <input matInput formControlName="class" autocomplete="off" readonly>
        </mat-form-field>
    </form>

    <div class="mb-3">
        <div class="myErrorSection warnColorBG d-flex justify-content-center" *ngIf="showError">
            <p class="p-3 m-0">{{ myTraceItem?.Error }}</p>
        </div>
        <div class="myMessageSection accentColorBG d-flex justify-content-center" *ngIf="showMessage">
            <p class="p-3 m-0">{{ myTraceItem?.Message }}</p>
        </div>
    </div>

    <button mat-button class="btn outlineActionButton" [mat-dialog-close]="true">{{'GENERAL.CLOSE' | translate }}</button>
</div>