import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { WidgetRequest } from "../../models/administrator/widgetRequest";
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { Role } from '../../models/userRoles';
import { Widget } from '../../models/widget';

@Component({
  selector: 'urban-widget-management-dialog',
  templateUrl: './widget-management-dialog.component.html',
  styleUrls: ['./widget-management-dialog.component.scss']
})
export class WidgetManagementDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('roleSelectionList') roleSelectionList: MatSelectionList;

  public roleList: Role[];
  public addMode: boolean = true;
  public error: boolean;

  public form: UntypedFormGroup = this.formBuilder.group({
    Id: [{value: '', disabled: false}],
    Name: [{value: '', disabled: false}, Validators.required],
    Description: [{value: '', disabled: false}, Validators.required],
    Roles: [[]]
  });

  constructor(
    public dialogRef: MatDialogRef<WidgetManagementDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        widgetToEdit: Widget,
        roleList: Role[]
      }
  ) {}

  ngOnInit(): void {
    if (this.data?.roleList) {
      this.roleList = this.data.roleList;
    }

    if (this.data?.widgetToEdit) {
      this.addMode = false;

      this.form.patchValue({
        Id: this.data.widgetToEdit.Id,
        Name: this.data.widgetToEdit.Name,
        Description: this.data.widgetToEdit.Description,
        Roles: this.data.widgetToEdit.Roles,
      });
    }
  }

  ngAfterViewInit(): void {
    if (!this.addMode && this.roleSelectionList) {
      setTimeout(() => {
        this.populateRoleSelectionList();
      });
    }
  }

  private populateRoleSelectionList(): void {
    const selectedRoles = this.form.get('Roles').value;

    if (selectedRoles && selectedRoles.length > 0) {
      this.roleSelectionList.options.forEach((option: MatListOption) => {
        option.selected = selectedRoles.some((role: Role) => role.Id === option.value.Id);
      });
    }
  }

  public onRoleSelected(options: MatListOption[]): void {
    this.form.get('Roles').setValue(options.map(o => (o.value as Role)));
  }

  public removeRoleFromWidget(roleIdToRemove: string): void {
    this.form.patchValue({
      Roles: (this.form.get('Roles').value as Role[]).filter(role => role.Id !== roleIdToRemove)
    });
  
    const optionToDeselect = this.roleSelectionList.options.find(option => option.value.Id === roleIdToRemove);
    if (optionToDeselect) {
      optionToDeselect.selected = false;
    }
  }

  private createWidgetRequest(): WidgetRequest {
    const formValue = this.form.getRawValue();
    const adminRole: Role | undefined = this.roleList?.find(role => role.Name === "Administrators");
    
    const roleIds: string[] = formValue.Roles?.map((role: Role) => role.Id) ?? [];
    if (adminRole && !roleIds?.includes(adminRole.Id)) {
      roleIds.push(adminRole.Id);
    }

    return {
      Id: formValue.Id ?? null,
      Name: formValue.Name,
      Description: formValue.Description,
      RoleIds: roleIds
    };
  }
  
  public addWidget(): void {
    if (this.form.valid) {
      this.error = false;
      this.dialogRef.close(this.createWidgetRequest());
      return;
    }

    this.error = true;
  }
  
  public editWidget(): void {
    if (this.form.valid) {
      this.error = false;

      const initialWidget: WidgetRequest = {
        Id: this.data.widgetToEdit.Id,
        Name: this.data.widgetToEdit.Name,
        Description: this.data.widgetToEdit.Description,
        RoleIds: this.data.widgetToEdit.Roles.map((role: Role) => role.Id) ?? []
      }

      const editedWidget: WidgetRequest = this.createWidgetRequest();

      if (JSON.stringify(initialWidget) === JSON.stringify(editedWidget)) {
        this.dialogRef.close();
        return;
      }

      this.dialogRef.close(this.createWidgetRequest());

      return;
    }

    this.error = true;
  }
}
