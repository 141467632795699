import {Component, OnDestroy, OnInit} from '@angular/core';
import {interval, Observable, Subject, Subscription} from "rxjs";
import {ApiService} from "../../services/api.service";
import {map, switchMap, takeUntil} from "rxjs/operators";
import {
  DeviceByModel,
  DeviceEventByModelPassage,
  DeviceEventByModelTotalPassages
} from "../../models/deviceEventByModel";
import {ClientSettings} from "../../models/clientSettings";
import {LoaderService} from "../../services/loader/loader.service";
import {Router} from "@angular/router";
import {MainSubscriptionsService} from "../../services/main-subscriptions/main-subscriptions.service";
import { ChartDataDescription, ChartMultiDataList } from '../../models/ChartDataList';
import { TranslateService } from '@ngx-translate/core';
import { PassDataService } from '../../services/pass-data/pass-data.service';

@Component({
  selector: 'urban-imx-camera-widget',
  templateUrl: './imx-camera-widget.component.html',
  styleUrls: ['./imx-camera-widget.component.scss']
})
export class ImxCameraWidgetComponent implements OnInit, OnDestroy {
  private events: DeviceByModel[] = [];
  public eventsMapped: DeviceEventByModelPassage[] = [];
  public eventsMappedLastHour: DeviceEventByModelPassage[] = [];
  public eventsMappedLast5Minutes: DeviceEventByModelPassage[] = [];
  private eventPollingIntervalMs: number = 10000;
  private eventsPolling$: Subject<void>;
  private clientSettings: ClientSettings = new ClientSettings();
  eventsColumns = ['Name', 'Aliases', 'In', 'Out', 'Detail'];
  public totalPassages24Hours: DeviceEventByModelTotalPassages = { In : 0, Out: 0, Total : 0};
  public totalPassagesLastHour: DeviceEventByModelTotalPassages = { In : 0, Out: 0, Total : 0};
  public totalPassagesLast5Minutes: DeviceEventByModelTotalPassages = { In : 0, Out: 0, Total : 0};
  public totalPeoplePassages: ChartMultiDataList;
  public allCamerasPassage: Record<string, number>;
  public dataDescriptions: ChartDataDescription[] = [];
  public legendColors: Record<string, string>;
  public legendKeys: string[] = ['In', 'Out', 'Total'];
  public periodSelected: string = '24_hours';
  public filterPeriods: string[] = ['24_hours', 'last_hour', '5_minutes'];

  private translateSubscription: Subscription = new Subscription();
  public translationsReady: boolean = false;
  private isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private apiService: ApiService,
              private passDataService: PassDataService,
              private loaderService: LoaderService,
              private translate: TranslateService,
              private router: Router,
              private mainService: MainSubscriptionsService) {
  }

  ngOnInit(): void {
    this.eventsPolling$ = new Subject<void>();

    this.setDataDescriptions();
    this._loadData();

    this.passDataService.currentDarkModeStatus$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(res => {
      this.isDarkActive = res === true;

      this.legendColors = this.getLegendColors();
    });
  }

  private setDataDescriptions(): void {
    this.translateSubscription.unsubscribe();
    let prefix: string = 'IMX_CAMERA_WIDGET.';
    let phrases: string[] = ['In', 'Out', 'Total'];

    this.getTranslations(phrases, prefix);
    this.translateSubscription = this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, prefix);
    });
  }

  private getTranslations(phrases: string[], prefix: string): void {
    this.translate.get(phrases.map((phrase: string) => prefix + phrase.toUpperCase())).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.dataDescriptions = [];
        phrases.forEach((phrase: string) => {
          let translation: string = res[prefix + phrase.toUpperCase()];
          let dataDescription: ChartDataDescription = {
            Original: phrase,
            Translated: translation !== (prefix + phrase.toUpperCase()) ? translation : phrase
          }
          this.dataDescriptions.push(dataDescription);
        });
        this.translationsReady = true;
      });
  }

  private _loadData(): void {
    this.apiService.getClientSettings().pipe(takeUntil(this.ngUnsubscribe)).subscribe(clientSettings => {
      if (clientSettings !== undefined) {
        this.clientSettings.Settings = clientSettings;

        if (this.clientSettings.Settings["event_polling_ms"]) {
          this.eventPollingIntervalMs = +this.clientSettings.Settings["event_polling_ms"];
        }

        this.loaderService.disable();

        this.getEvents().subscribe();

        interval(this.eventPollingIntervalMs).pipe(
          takeUntil(this.eventsPolling$),
          switchMap(() => this.getEvents())
        ).subscribe();
      }
    });
  }

  private getEvents(): Observable<void> {
    return this.apiService.getDeviceEventLatest24HoursByModel('IMX500').pipe(map((response) => {
      if (response && response.Items) {
        this.events = response.Items;
        this.mapResponse();
        this.eventsMappedLastHour = this.filterByMinutes(60, this.eventsMappedLastHour.slice());
        this.eventsMappedLast5Minutes = this.filterByMinutes(5, this.eventsMappedLast5Minutes.slice());
        this.mapTotalsForLastHour();
        this.mapTotalsForLast5Minutes();
        this.changePeriodFilter(this.periodSelected);
      }
    }));
  }

  private getTotalPeoplePassage(passageEvents: DeviceEventByModelPassage[]): ChartMultiDataList {
    let totalPeoplePassage: ChartMultiDataList = {};

    passageEvents.forEach(passageEvent => {
      totalPeoplePassage[passageEvent.Name] = {
        In: passageEvent.In,
        Out: passageEvent.Out,
        Total: passageEvent.In - passageEvent.Out
      };
    });

    return totalPeoplePassage;
  }

  private getAllCamerasPassage (passageEvents: DeviceEventByModelPassage[]): Record<string, number> {
    let allCamerasPassage: Record<string, number> = {
      In: 0,
      Out: 0
    };

    passageEvents.forEach(passageEvent => {
      allCamerasPassage.In += passageEvent.In;
      allCamerasPassage.Out += passageEvent.Out;
    });
    allCamerasPassage.Total = allCamerasPassage.In - allCamerasPassage.Out;

    return allCamerasPassage;
  }

  private mapResponse() {
    for (const event of this.events) {
      let eventMapped = this.eventsMapped.find(em => em.Id === event.Id);

      if (!eventMapped) {
        eventMapped = {
          Id: event.Id,
          Name: event.Name,
          Aliases: event.Aliases?.join(';'),
          In: event.Events.reduce((curr, next) => {
            return +curr + (+next.Body.in)
          }, 0),
          Out: event.Events.reduce((curr, next) => {
            return +curr + (+next.Body.out)
          }, 0)
        }

        this.eventsMapped.push(eventMapped);
      }
    }

    this.totalPassages24Hours.In = this.getTotalIn(this.eventsMapped);
    this.totalPassages24Hours.Out = this.getTotalOut(this.eventsMapped);
    this.totalPassages24Hours.Total = this.totalPassages24Hours.In + this.totalPassages24Hours.Out;
  }

  changePeriodFilter(period: string): void {
    if(period !== undefined && this.filterPeriods.includes(period)) {
      if(period === '24_hours') {
        this.totalPeoplePassages = this.getTotalPeoplePassage(this.eventsMapped);
        this.allCamerasPassage = this.getAllCamerasPassage(this.eventsMapped);
      }
      else if(period === 'last_hour') {
        this.totalPeoplePassages = this.getTotalPeoplePassage(this.eventsMappedLastHour);
        this.allCamerasPassage = this.getAllCamerasPassage(this.eventsMappedLastHour);
      }
      else if(period === '5_minutes') {
        this.totalPeoplePassages = this.getTotalPeoplePassage(this.eventsMappedLast5Minutes);
        this.allCamerasPassage = this.getAllCamerasPassage(this.eventsMappedLast5Minutes);
      }
    }
  }

  private filterByMinutes(minutes: number, currentEvents: DeviceEventByModelPassage[] = []): DeviceEventByModelPassage[] {
    const endDate = new Date();

    let startDate = new Date(endDate);

    startDate.setMinutes(endDate.getMinutes() - minutes);

    const eventsFiltered: DeviceByModel[] = [];
    for (const event of this.events) {
      const foundEvents = event.Events.filter((item) => {
        return new Date(+item.Created * 1000).getTime() >= startDate.getTime() && new Date(item.Created).getTime() <= endDate.getTime()
      });
      if (foundEvents && foundEvents.length > 0) {
        eventsFiltered.push({
          Id: event.Id,
          Name: event.Name,
          Aliases: event.Aliases,
          Events: foundEvents
        });
      }
    }

    if (!eventsFiltered) {
      return [];
    }

    for (const eventFiltered of eventsFiltered) {
      let eventMapped = currentEvents.find(em => em.Id === eventFiltered.Id);

      if (!eventMapped) {
        eventMapped = {
          Id: eventFiltered.Id,
          Name: eventFiltered.Name,
          Aliases: eventFiltered.Aliases?.join(';'),
          In: eventFiltered.Events.reduce((curr, next) => {
            return +curr + (+next.Body.in)
          }, 0),
          Out: eventFiltered.Events.reduce((curr, next) => {
            return +curr + (+next.Body.out)
          }, 0)
        }

        currentEvents.push(eventMapped);
      }
    }

    return currentEvents;
  }

  private mapTotalsForLastHour(): void {
    this.totalPassagesLastHour.In = this.getTotalIn(this.eventsMappedLastHour);
    this.totalPassagesLastHour.Out = this.getTotalOut(this.eventsMappedLastHour);
    this.totalPassagesLastHour.Total = this.totalPassagesLastHour.In + this.totalPassagesLastHour.Out;
  }

  private mapTotalsForLast5Minutes(): void {
    this.totalPassagesLast5Minutes.In = this.getTotalIn(this.eventsMappedLast5Minutes);
    this.totalPassagesLast5Minutes.Out = this.getTotalOut(this.eventsMappedLast5Minutes);
    this.totalPassagesLast5Minutes.Total = this.totalPassagesLast5Minutes.In + this.totalPassagesLast5Minutes.Out;
  }

  private getTotalIn(eventsMapped: DeviceEventByModelPassage[]): number {
    if (!eventsMapped) {
      return 0;
    }

    let result: number = 0;

    for (const eventMapped of eventsMapped) {
      result += eventMapped.In;
    }

    return result;
  }

  private getTotalOut(eventsMapped: DeviceEventByModelPassage[]): number {
    if (!eventsMapped) {
      return 0;
    }

    let result: number = 0;

    for (const eventMapped of eventsMapped) {
      result += eventMapped.Out;
    }

    return result;
  }

  private getColor(theme: 'light' | 'dark', palette: 'primary' | 'accent' | 'warn', colorName: string = 'main'): string {
    let colorVariable: string = `--custom-${theme}-${palette}-${colorName}-color`
    let color: string = window.getComputedStyle(document.body).getPropertyValue(colorVariable);
    color = color.trim(); //remove eventual spaces
    return color;
  }

  private getLegendColors(): Record<string, string> {
    let colors: Record<string, string> = {};

    colors['In'] = this.getColor(this.isDarkActive ? 'dark' : 'light', 'primary');
    colors['Out'] = window.getComputedStyle(document.body).getPropertyValue('--custom-chart-4-color');
    colors['Total'] = '#5352e4';

    return colors;
  }

  goToDetail(deviceId: string): void {
    this.mainService.setNavigationInfoComand({ Id: deviceId });
    this.router.navigate(['main/device']);
  }

  public goToPeoplePassage(): void {
    this.mainService.setNavigationInfoComand({ BackRoute: 'dashboard' })
    this.router.navigate(['main/people-passage']);
  }

  ngOnDestroy(): void {
    this.eventsPolling$.next();
    this.eventsPolling$.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
