<div class="dialogContainer">
    <h3 class="title3 mb-3">{{ 'DEVICE.NEW_DEVICE' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE.LATITUDE' | translate }}</mat-label>
            <input matInput formControlName="latitude" type="text" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE.LONGITUDE' | translate }}</mat-label>
            <input matInput formControlName="longitude" type="text" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE.PARENT' | translate }}</mat-label>
            <mat-select formControlName="parentSelected" (selectionChange)="updateParent()" hideSingleSelectionIndicator="true">
                <mat-option *ngFor="let parentDevice of parents" value={{parentDevice.Id}}>
                    <span class="urbanicon-{{ parentDevice.Model.Type?.Icon }}"></span>
                    {{parentDevice.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE.BRAND' | translate }}</mat-label>
            <mat-select formControlName="brandSelected" (selectionChange)="updateModels()" hideSingleSelectionIndicator="true">
                <mat-option *ngFor="let brand of listBrands" value={{brand.Id}}>{{brand.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE.MODEL' | translate }}</mat-label>
            <mat-select formControlName="modelSelected" hideSingleSelectionIndicator="true">
                <mat-option *ngFor="let model of modelsSelected" value={{model.Id}}>{{model.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="!form.valid">
            {{ 'DEVICE.FORM_ERROR' | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="addition()">{{ 'DEVICE.ADD' | translate }}</button>
        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'DEVICE.CANCEL' | translate }}</button>
    </div>
</div>
