import { AfterViewInit, Component, ElementRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Device } from '../../models/device';
import Hls from 'hls.js'

@Component({
    selector: 'urban-streaming-dialog',
    templateUrl: './streaming-dialog.component.html',
    styleUrls: ['./streaming-dialog.component.scss']
})

export class StreamingDialogComponent implements AfterViewInit {

    public videoUrl: string;
    public deviceName: string;
    public noVideo: boolean = false;
    public loadingVideo: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<StreamingDialogComponent>,
        private elementRef: ElementRef,
        @Inject(MAT_DIALOG_DATA) public data:
            {
                device: Device
            }
    ) {
        this.videoUrl = 'https://video.urbanvision.app/' + this.data.device.Id + `/proxy/video.m3u8?${+Date.now()}`;
        this.deviceName = data.device.Name;
    }

    ngAfterViewInit(): void {
        let videoContent = this.elementRef.nativeElement.querySelector('#videoContent');

        let hls = new Hls();
        hls.loadSource(this.videoUrl);
  
        hls.on(Hls.Events.ERROR, () => {
            this.loadingVideo = false;
            this.noVideo = true;
        });
        
        if (Hls.isSupported()) {
            hls.loadSource(this.videoUrl);
            hls.attachMedia(videoContent);
            hls.on(Hls.Events.MANIFEST_PARSED, function () {
                this.loadingVideo = false;
                videoContent.play();
            });
        }
        else if (videoContent.nativeElement.canPlayType('application/vnd.apple.mpegurl')) {
            videoContent.src = this.videoUrl;
            videoContent.nativeElement.addEventListener('loadedmetadata', function () {
                this.loadingVideo = false;
                videoContent.play();
            });
        }
    }
}