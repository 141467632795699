<div class="dialogContainer">
    <h3 class="title3 mb-3">{{ 'ADMINISTRATION.ADD_AUTHORIZATION' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'ADMINISTRATION.DOMAIN' | translate }}</mat-label>
            <mat-select formControlName="domainSelected" (selectionChange)="updateAvailableRoles()" hideSingleSelectionIndicator="true">
                <mat-option *ngFor="let domain of domains" [value]="domain.Id">{{domain.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'ADMINISTRATION.ROLE' | translate }}</mat-label>
            <mat-select formControlName="roleSelected" hideSingleSelectionIndicator="true">
                <mat-option *ngFor="let role of availableRoles" [value]="role.Id">
                    {{role.Name}}
                  </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
    
    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="add()">{{ 'GENERAL.ADD'| translate }}</button>
        <button mat-button class="btn outlineActionButton w-50 ml-1" matDialogClose>{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>