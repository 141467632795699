<div class="dialogContainer">
    <div *ngIf="!data.seeMore">
        <h1 mat-dialog-title *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }}</h1>
        <h1 mat-dialog-title *ngIf="!this.isAdd">{{ 'GENERAL.DETAIL' | translate }}</h1>

        <form [formGroup]="form" class="mb-4">
            <mat-form-field appearance="outline" class="customMatFormField mb-4">
                <mat-label>{{ 'GENERAL.TITLE' | translate }}</mat-label>
                <input matInput formControlName="title" autocomplete="off" required>
            </mat-form-field>

            <mat-form-field appearance="outline" class="customMatFormField mb-4">
                <mat-label>{{ 'GENERAL.SOURCE' | translate }}</mat-label>
                <input matInput formControlName="source" autocomplete="off" required>
            </mat-form-field>

            <mat-expansion-panel class="mb-3 mat-elevation-z0">
                <mat-expansion-panel-header class="pl-0">
                    <mat-panel-title class="pl-2">
                        {{ 'GENERAL.IMAGE' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="p-0 mt-1">
                    <div class="d-flex flex-column align-items-center">
                        <div class="d-flex align-items-baseline mb-3">
                            <p class="paragraphText mr-2" *ngIf="this.form.controls.image.value === ''">{{ 'NEWS.ADD_IMAGE' | translate }}</p>

                            <input style="display: none;" type="file" (change)="imageUpload($event)"
                                accept=".png, .jpg, .jpeg" #imageInput>
    
                            <button *ngIf="this.form.controls.image.value === ''" mat-mini-fab color="primary"
                                class="my-fab mat-elevation-z2" (click)="imageInput.click()">
                                <i class="urbanicon-edit"></i>
                            </button>
    
                            <button *ngIf="this.form.controls.image.value !== ''" mat-mini-fab color="primary"
                                class="my-fab mat-elevation-z2 ml-1" (click)="removeImage()">
                                <i class="urbanicon-remove position-relative" style="top: 2px;"></i>
                            </button>
                        </div>

                        <img *ngIf="this.form.controls.image.value !== ''" src="{{ this.form.controls.image.value }}" class="img-fluid w-25">

                        <mat-error *ngIf="imageError" class="text-center">
                            {{ 'ERROR.'+imageError | translate }}
                        </mat-error>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-form-field appearance="outline" class="customTextArea mb-3">
                <mat-label>{{ 'GENERAL.BODY' | translate }}</mat-label>
                <textarea   matInput formControlName="body"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="2"
                            cdkAutosizeMaxRows="10"
                            autocomplete="off">
                </textarea>
            </mat-form-field>

            <mat-form-field appearance="outline" class="customDatePicker mb-3">
                <mat-label>{{ 'GENERAL.DATE' | translate }}</mat-label>
                <input matInput [owlDateTime]="dt1" (dateChange)="setDate($event.target.value)" 
                formControlName="date" [owlDateTimeTrigger]="dt1" readonly>
    
                <i [owlDateTimeTrigger]="dt1" matSuffix class="urbanicon-monitoring-outline pointer"></i>
    
                <owl-date-time #dt1></owl-date-time>
            </mat-form-field>

            <mat-error *ngIf="error">
                {{ 'GENERAL.ERROR_EMPTY' | translate }}            
            </mat-error>
        </form>

        <div class="d-flex justify-content-between">
            <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="this.isAdd" (click)="addition()">{{ 'GENERAL.ADD' | translate }}</button>
            <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="!this.isAdd" (click)="addition()">{{ 'GENERAL.UPDATE' | translate }}</button>

            <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'GENERAL.CLOSE' | translate }}</button>
        </div>
    </div>
    
    <!-- SeeMore view -->
    <div *ngIf="data.seeMore">
        <div class="mb-4">
            <a class="linkText mb-3 d-block text-center" href="{{news.Source}}" target="_blank">{{news.Source}}</a>
            <p class="paragraphText"> {{news.Body}} </p>
        </div>

        <button mat-button class="btn outlineActionButton" (click)="cancel()">{{ 'GENERAL.CLOSE' | translate }}</button>
    </div>
</div>