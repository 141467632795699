<div class="dialogContainer">
    <h3 class="title3">{{ 'CAMERA.ADD' | translate }}</h3>
    <p class="secondaryTextColor mb-3">{{ 'ADD_STREAMING_CAMERA.ADD_CAMERA' | translate }}</p>

    <mat-list class="overflow-auto mb-3">
        <mat-radio-group aria-labelledby="streaming-camera" color="primary" class="d-flex flex-column" (change)="radioChange($event)">
            <mat-radio-button *ngFor="let camera of cameras" [value]="camera">
                {{camera.Name}}
            </mat-radio-button>
        </mat-radio-group>
    </mat-list>

    <div class="d-flex justify-content-between">
        <button mat-button class="btn actionButton w-50 mr-1" (click)="addCamera()">{{ 'CAMERA.ADD' | translate }}</button>
        <button mat-button class="btn outlineActionButton w-50 ml-1" mat-dialog-close>{{ 'GENERAL.CANCEL' | translate}}</button>
    </div>
</div>