import { Router } from '@angular/router';
import { Component, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { MapInfoWindow, GoogleMap, MapMarker } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { Subject, timer } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { Device } from '../../models/device';
import { GoogleMarker, InfoContentData } from '../../models/googleMarker';
import { UserRoles } from '../../models/userRoles';
import { MainSubscriptionsService } from '../../services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from '../../services/pass-data/pass-data.service';
import { Location } from '../../models/location';
import { DomainProperty } from '../../models/domainProperty';

@Component({
  selector: 'urban-traffic-maps-widget',
  templateUrl: './traffic-maps-widget.component.html',
  styleUrls: ['./traffic-maps-widget.component.scss']
})
export class TrafficMapsWidgetComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  private listLocations: Location[];
  public userRoles: UserRoles['Roles'];
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public mapOptions: google.maps.MapOptions;
  public mapBounds: google.maps.LatLngBounds;
  public mapZoom: number;
  private defaultPosition: google.maps.LatLng = null;
  public markers: Array<GoogleMarker> = [];
  public isAdmin: boolean = false;
  public loadingNavigation: boolean = false;
  public refreshMap: boolean = false;

  @Input('trafficList') public listTraffics: Device[];
  @Input('passedHeight') public passedHeight: number = null;
  @Input('initialZoom') private defaultZoom: number;
  @Input('darkModeStatus') public isDarkActive: boolean;

  constructor(
    private mainService: MainSubscriptionsService,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private router: Router
  ) {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(roles => {
      this.userRoles = roles;
    });

    this.passDataService.locations$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(locations => {
      this.listLocations = locations;
    });

    this.passDataService.currentDomainProperties$.pipe(first()).subscribe(properties => {
      let defCoordinatesProp: DomainProperty = properties
        .find((property: DomainProperty) => property.Key === 'DefaultCoordinates');
      if (defCoordinatesProp) {
        try {
          let coordinatesObject: any = JSON.parse(defCoordinatesProp.Value);
          if (['lat', 'lng'].every((key: string) => Object.keys(coordinatesObject).includes(key))) {
            this.defaultPosition = new google.maps.LatLng(coordinatesObject.lat, coordinatesObject.lng);
          }
        }
        catch {}
      }
    });
  }

  ngAfterViewInit() {
    this.setBounds();
  }

  guidPattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');

  private setBounds(): void {
    if (this.mapBounds === undefined) {
      this.mapBounds = new google.maps.LatLngBounds();

      if (this.listTraffics?.length > 0) {
        for (let i = 0; i < this.listTraffics.length; i++) {
          this.mapBounds.extend(new google.maps.LatLng(this.listTraffics[i].Latitude, this.listTraffics[i].Longitude));
        }
        timer(0).subscribe(() => {
          this.map.fitBounds(this.mapBounds);
        });
      }
      else if (this.defaultPosition) {
        this.mapBounds.extend(this.defaultPosition);
        this.map.fitBounds(this.mapBounds);
        this.map.boundsChanged.pipe(first()).subscribe(() => {
          if (this.defaultZoom !== undefined) {
            this.map.googleMap.setZoom(this.defaultZoom);
          }
        });
      }
    } else {
      timer(0).subscribe(() => {
        this.map.fitBounds(this.mapBounds, 0);
      });
    }
  }


  private getIcon(deviceTypeMapPin: string): google.maps.Icon {
    if (deviceTypeMapPin.includes('_')) deviceTypeMapPin.replace('_', '-')
    return {
      url: "/assets/img/icons/" + deviceTypeMapPin + ".png",
      labelOrigin: new google.maps.Point(90, 20)
    };
  }

  ngOnInit(): void {
    this.mapOptions = {
      mapId: this.isDarkActive ? 'af8729d51fc92cc9' : '805d80ad475b1388',
      gestureHandling: "cooperative",
      mapTypeId: "roadmap"
    } as google.maps.MapOptions;

    if (this.listTraffics?.length > 0) {
      for (let i = 0; i < this.listTraffics.length; i++) {

          let children = this._buildDevices(this.listTraffics[i].Childs);

          this.markers.push(
            new GoogleMarker(
              new google.maps.LatLng(this.listTraffics[i].Latitude, this.listTraffics[i].Longitude),
              {
                color: 'black',
                text: this.listTraffics[i].Name.length > 12 ? this.listTraffics[i].Name.slice(0, 12) + '...' : this.listTraffics[i].Name,
                fontSize: '0px',
                fontWeight: 'normal',
                scaledSize: new google.maps.Size(80, 80),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(20, 0),
                labelOrigin: null
              },
              this.listTraffics[i].Name,
              { animation: google.maps.Animation.DROP, icon: this.getIcon(this.listTraffics[i].Model.Type.MapPin) },
              this.listTraffics[i].Id,
              this._buildInfoContentArray(children)
            )
          );

      }
    }

    if (this.listLocations?.length > 0) {
      for (let i = 0; i < this.listLocations.length; i++) {

        this.markers.push(
          new GoogleMarker(
            new google.maps.LatLng(+this.listLocations[i].Latitude, +this.listLocations[i].Longitude),
            {
              color: 'black',
              text: this.listLocations[i].Name.length > 12 ? this.listLocations[i].Name.slice(0, 12) + '...' : this.listLocations[i].Name,
              fontSize: '0px',
              fontWeight: 'normal',
              scaledSize: new google.maps.Size(80, 80),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(20, 0),
              labelOrigin: null
            },
            this.listLocations[i].Name,
            { animation: google.maps.Animation.DROP, icon: this.getIcon('pin-location') },
            'Location:' + this.listLocations[i].Id,
            []
          )
        );

      }
    }
  }

  openInfoWindow(marker: MapMarker, windowIndex: number, info: string) {
    if (info.startsWith('Location:')){
      return
    }
    else {
      /// stores the current index in forEach
      let curIdx = 0;
      this.infoWindowsView.forEach((window: MapInfoWindow) => {
        if (windowIndex === curIdx) {
          window.open(marker);
          curIdx++;
        } else {
          curIdx++;
        }
      });
    }
  }

  openInfo(content: string) {
    if (content != undefined && content != null && content != '' && content.match(this.guidPattern)) {
      this.goToDeviceDetail(content);
    }
  }

  goToDeviceDetail(deviceId: string): void {
    if(!this.loadingNavigation) {
      this.loadingNavigation = true;
      let currentUrl: string = this.router.url.split('/').pop().split(';')[0];

      this.mainService.setNavigationInfoComand({ Id: deviceId, BackRoute: currentUrl });
      this.mainService.updateAllDataAndNavigateComand('device');
    }
  }

  private _buildInfoContentArray(devices: Device[]): InfoContentData[] {

    if (!devices || devices === undefined) {
      return [];
    }

    let elements: Array<InfoContentData> = [];

    devices.forEach((element) => {
      if (elements.some(function (item) {
        return item.Name == element.Model.Type.Name
      })) {
        elements.find(x => x.Name == element.Model.Type.Name).Elements++;
      }
      else {
        elements.push(new InfoContentData(element.Model.Type.Name, element.Model.Type.Icon))
      }
    });

    return elements;
  }

  private _buildDevices(devices: Device[]): Device[] {
    if (!devices || devices === undefined) {
      return [];
    }

    let childs: Device[] = [];

    for (let device of devices) {
      if (device.Childs?.length > 0) {
        childs.push(device);
        for (let child of device.Childs) {
          childs.push(child);
        }
      }
      else {
        childs.push(device);
      }
    }

    return childs;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDarkActive && changes.isDarkActive.currentValue !== changes.isDarkActive.previousValue && !changes.isDarkActive.firstChange) {
      this.mapZoom = this.map.getZoom();
      this.mapBounds = this.map.getBounds();

      this.refreshMap = true;
      timer(0).subscribe(() => {
        this.mapOptions = {
          mapId: this.isDarkActive ? 'af8729d51fc92cc9' : '805d80ad475b1388',
          gestureHandling: "cooperative",
          mapTypeId: "roadmap",
          zoom: this.mapZoom
        } as google.maps.MapOptions;

        this.refreshMap = false;
        this.setBounds();
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
