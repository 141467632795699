import { Component, Inject, OnInit } from '@angular/core';
import { AdvertisingType } from '../../models/advertising/advertising';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AttachmentType } from '../../models/advertising/attachmentType';

@Component({
  selector: 'urban-advertising-type-dialog',
  templateUrl: './advertising-type-dialog.component.html',
  styleUrls: ['./advertising-type-dialog.component.scss']
})
export class AdvertisingTypeDialogComponent implements OnInit {
  public advertisingType: AdvertisingType;
  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required],
    attachmentType: [{ value: ''}, Validators.required]
  });
  public error: string | null;
  public isAdd: boolean = true;
  public attachmentTypeEnum: typeof AttachmentType = AttachmentType;
  public attachmentTypeOptions: string[] = Object.keys(AttachmentType);

  constructor(
    public dialogRef: MatDialogRef<AdvertisingTypeDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: AdvertisingType
  ) {
    if (this.data && this.data !== undefined) {
      this.isAdd = false;
      this.advertisingType = this.data;
    }
  }

  ngOnInit(): void {
    if (!this.isAdd) {
      this.form.controls.name.setValue(this.advertisingType.Name);
      this.form.controls.attachmentType.setValue(this.advertisingType.AttachmentType);
    }
  }

  public addition() {
    if (this.form.valid) {
      if (this.isAdd) {
        this.advertisingType = {
          Id: 0,
          Name: this.form.controls.name.value,
          AttachmentType: this.form.controls.attachmentType.value
        }
      }
      else {
        this.advertisingType.Name = this.form.controls.name.value;
        this.advertisingType.AttachmentType = this.form.controls.attachmentType.value;
      }

      this.dialogRef.close(this.advertisingType)
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }
}
