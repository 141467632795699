import { Component, OnInit } from '@angular/core';
import { MainSubscriptionsService } from '../../services/main-subscriptions/main-subscriptions.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DeviceEventRejected } from '../../models/administrator/deviceEventsRejectedResponse';
import { AdministratorApiService } from '../../services/administrator-api.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'urban-device-events-rejected-widget',
  templateUrl: './device-events-rejected-widget.component.html',
  styleUrls: ['./device-events-rejected-widget.component.scss']
})
export class DeviceEventsRejectedWidgetComponent implements OnInit {
  public dataReady: boolean = false;
  public loading: boolean = true;
  public noDataAvailable: boolean;
  public eventsLast24h: number;
  public eventsLast48h: number;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private AdministratorApiService: AdministratorApiService,
    private mainService: MainSubscriptionsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.AdministratorApiService.deviceEventsRejected(2880).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res?.Items.length > 0) {
        this.countEvents(res.Items);
        this.dataReady = true
      }
      else {
        this.noDataAvailable = true;
      }
    });
  }

  private countEvents(rejectedEvents: DeviceEventRejected[]): void {
    const now = new Date().getTime();
    const oneDay = 24 * 60 * 60 * 1000;
  
    this.eventsLast24h = rejectedEvents.filter(event => {
      if (!event.Created) {
        return false;
      }

      const eventTime = new Date(event.Created).getTime();
      return !isNaN(eventTime) && now - eventTime <= oneDay;
    }).length;
  
    this.eventsLast48h = rejectedEvents.length;
  }

  public goToRejectedEventsPage(): void {
    this.mainService.setNavigationInfoComand({ BackRoute: 'dashboard' });
    this.router.navigate(['main/device-events-rejected']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
