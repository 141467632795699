<div class="goToPeoplePassage">
    <button mat-button class="btn outlineActionButton" (click)="goToPeoplePassage()">
        {{ 'DASHBOARD.FULL_VIEW' | translate }}
    </button>
</div>

<div class="container-fluid pb-3 imxMonitor">
    <div [ngClass]="totalPeoplePassages !== undefined && translationsReady ? 'finalHeight' : 'initialHeight'" class="position-relative">
        <div *ngIf="totalPeoplePassages !== undefined && translationsReady" class="appearing">
            <div class="my-3 toggleButtons">
                <mat-button-toggle-group aria-label="Passage Period Set" [(ngModel)]="periodSelected" hideSingleSelectionIndicator="true"
                    (valueChange)="changePeriodFilter($event)">
                    <mat-button-toggle *ngFor="let period of filterPeriods" [value]="period">
                        {{ 'IMX_CAMERA_WIDGET.EVENTS_' + period.toUpperCase() | translate }}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div class="d-flex flex-row justify-content-start align-items-start">
                <div *ngIf="legendColors && legendColors.In !== undefined && legendColors.Out !== undefined && legendColors.Total !== undefined"
                    class="d-flex flex-column chartInfo">
                    <div *ngFor="let legendKey of legendKeys" class="d-flex align-items-start mb-4">
                        <div class="legendRectangle" [ngStyle]="{'background-color': legendColors[legendKey]}"></div>
                        <div class="d-flex flex-column">
                            <span class="paragraphText">{{ ('IMX_CAMERA_WIDGET.' + legendKey.toUpperCase()) | translate }}</span>
                            <span class="substrongText">{{ allCamerasPassage[legendKey] }}</span>
                        </div>
                    </div>
                </div>

                <urban-google-columnchart id="peoplePassage-content"
                    class="appearing flex-grow-1 mt-4" [multipleColumns]="true" [thinColumns]="true" columnsTitle="IMX_CAMERA_WIDGET.IMX_CAMERAS"
                    [multiValuesDescription]="dataDescriptions" [passedChartMultiData]="totalPeoplePassages" [isLimitedBelow]="false"
                    [darkThemeActive]="isDarkActive" [heightPercent]="100" [automaticPadding]="false" [chartMarginTop]="20">
                </urban-google-columnchart>
            </div>

        </div>
        <urban-loader [localized]="true" [disappearCondition]="totalPeoplePassages !== undefined && translationsReady"></urban-loader>
    </div>

</div>
