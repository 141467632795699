<div class="dialogContainer">
    <h3 class="title3 mb-3">{{ 'LOGIN.CHOOSE_DOMAIN_TITLE' | translate }}</h3>

    <mat-form-field appearance="outline" class="customMatFormField mb-4">
        <mat-label>{{ 'LOGIN.CHOOSE_DOMAIN' | translate }}</mat-label>
        <mat-select hideSingleSelectionIndicator="true">
            <mat-option class="user-select-none" *ngFor="let domain of data.domains" [value]="domain.Id"
                (click)="setDomain(domain)">{{domain.Name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <div class="d-flex justify-content-between">
        <button mat-button type="button" class="btn actionButton w-50 mr-1" [disabled]="!domainChoosed"
            (click)="continue()">{{ 'LOGIN.CONTINUE' | translate }}
        </button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>