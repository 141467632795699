<div class="dialogContainer">
    <h3 class="title3 mb-3">{{this.deviceGroupName}}</h3>

    <p class="paragraphText mb-3">
        {{ 'DEVICE_GROUP_DETAIL.SUBTITLE' | translate }}
    </p>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="confirmAction()">{{ 'GENERAL.GO'| translate }}</button>
        <button mat-button class="btn outlineActionButton w-50 ml-1" mat-dialog-close>{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>