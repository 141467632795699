import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Calendar } from '../../models/calendar';
import { CalendarRequest } from '../../models/calendarRequest';
import { Dictionary } from '../../models/dictionary';
import { Location } from '../../models/location';

@Component({
  selector: 'urban-calendar-dialog',
  templateUrl: './calendar-dialog.component.html',
  styleUrls: ['./calendar-dialog.component.scss']
})
export class CalendarDialogComponent implements OnInit {

  public calendar: Calendar = null;
  public isAdd: boolean = true;
  public locations: Location[] = [];
  public maxDate: Date;
  public minDate: Date;
  public priorities: Array<Dictionary> = [];
  public currentColor: string;

  public form: UntypedFormGroup = this.formBuilder.group({
    location: [{ value: '', disabled: false }, Validators.required],
    start: [{ value: '', disabled: false }, Validators.required],
    end: [{ value: '', disabled: false }, Validators.required],
    title: [{ value: '', disabled: false }, Validators.required],
    text: [{ value: '', disabled: false }, Validators.required],
    priority: [{ value: '', disabled: false }, Validators.required]
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<CalendarDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        calendar: Calendar,
        locations: Location[],
        priorities: any
      }
  ) {
    let now: Date = new Date();
    this.minDate = now;
    this.maxDate = new Date(now.getFullYear(), now.getMonth() + 2, 1);
  }

  ngOnInit(): void {
    if (this.data !== null && this.data !== undefined && this.data.calendar !== null && this.data.calendar !== undefined) {
      this.calendar = this.data.calendar;
      this.isAdd = false;

      this.form.setValue({
        location: this.calendar.Location.Id,
        start: new Date(this.calendar.Start * 1000),
        end: new Date(this.calendar.End * 1000),
        title: this.calendar.Title,
        text: this.calendar.Text,
        priority: this.calendar.Priority
      });
      this.currentColor = this.calendar.Priority;
    }
    else {
      this.currentColor = 'ffffff';
    }

    this.locations = this.data.locations;
    this.data.priorities.forEach(priority => {
      let keys = Object.keys(priority);
      for (let key of keys) {
        this.priorities.push({ Key: key, Value: priority[key].toString(), Translation: key });
      }
    });
  }

  addition() {
    if (this.form.valid) {
      let calendarRequest: CalendarRequest = {
        Id: this.calendar !== null ? this.calendar.Id : '',
        LocationId: this.form.controls.location.value,
        Start: +this.form.controls.start.value / 1000,
        End: +this.form.controls.end.value / 1000,
        Title: this.form.controls.title.value,
        Text: this.form.controls.text.value,
        Priority: this.form.controls.priority.value
      };

      this.dialogRef.close(calendarRequest)
    }
    else if(this.form.controls.start.value < this.minDate || this.form.controls.end.value < this.minDate) {
      this.error = "CALENDARS.ERROR_TOO_EARLY";
    }
    else if(Object.keys(this.form.controls).some((key: string) => this.form.controls[key].value === '')) {
      this.error = "GENERAL.ERROR_EMPTY";
    }
  }

  updateColor(colorValue: string): void {
    this.currentColor = colorValue.substring(1);
    this.form.patchValue({
      priority: this.currentColor
    });
  }

  setColor(): void {

  }

  cancel() {
    this.dialogRef.close();
  }
}
