<div class="m-3">
    <h3 class="title3 mb-4 text-center">{{ 'PROFILE.CHANGE_PASSWORD' | translate }}</h3>

    <form [formGroup]="form" class="d-flex flex-column mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
             <mat-label>{{ 'CHANGE_PASSWORD_DIALOG.OLD_PASSWORD' | translate }}</mat-label>
             <input [type]="hideOldPassword ? 'password' : 'text'" matInput
                 formControlName="oldPassword" autocomplete="off">
             <i class="passwordIcon" matSuffix [ngClass]="hideOldPassword ? 'urbanicon-visibility-off-outline' : 'urbanicon-visibility-outline'" (click)="hideOldPassword = !hideOldPassword"></i>
         </mat-form-field>

         <mat-form-field appearance="outline" class="customMatFormField mb-3">
             <mat-label>{{ 'CHANGE_PASSWORD_DIALOG.NEW_PASSWORD' | translate }}</mat-label>
             <input [type]="hideNewPassword ? 'password' : 'text'" matInput
                 formControlName="newPassword" autocomplete="off">
             <i class="passwordIcon" matSuffix [ngClass]="hideNewPassword ? 'urbanicon-visibility-off-outline' : 'urbanicon-visibility-outline'" (click)="hideNewPassword = !hideNewPassword"></i>
         </mat-form-field>

         <mat-form-field appearance="outline" class="customMatFormField mb-3">
             <mat-label>{{ 'CHANGE_PASSWORD_DIALOG.CONFIRMED_NEW_PASSWORD' | translate }}</mat-label>
             <input [type]="hideConfirmedNewPassword ? 'password' : 'text'" matInput
                 formControlName="confirmedNewPassword" autocomplete="off">
             <i class="passwordIcon" matSuffix [ngClass]="hideConfirmedNewPassword ? 'urbanicon-visibility-off-outline' : 'urbanicon-visibility-outline'" (click)="hideConfirmedNewPassword = !hideConfirmedNewPassword"></i>
         </mat-form-field>
     
         <mat-error *ngIf="error">
             {{ 'CHANGE_PASSWORD_DIALOG.' + error | translate }}
         </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="change()">{{ 'CHANGE_PASSWORD_DIALOG.CONFIRM' | translate }}</button>
        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'CHANGE_PASSWORD_DIALOG.CANCEL' | translate }}</button>
    </div>
</div>