import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SMSSAdvertiser } from '../../models/smss/smssAdvertiser';

@Component({
  selector: 'urban-advertiser-dialog',
  templateUrl: './advertiser-dialog.component.html',
  styleUrls: ['./advertiser-dialog.component.scss']
})
export class AdvertiserDialogComponent implements OnInit {

  public form: UntypedFormGroup = this.formBuilder.group({
    companyName: ['', Validators.required],
    address: [''],
    zip: [''],
    city: [''],
    state: [''],
    country: [''],
    vat: [''],
    phone: [''],
    fax: [''],
    web: [''],
    email: [''],
    firstname: [''],
    lastname: [''],
    timezone: [''],
    customCss: [''],
    maxCampaigns: [0],
    publicAdvCampaigns: [0],
    erpCode: [''],
    notes: [''],
    locked: ['false']
  });

  public advertiser: SMSSAdvertiser;
  public error: string | null;

  private resellerId: string = '';
  constructor(
    public dialogRef: MatDialogRef<AdvertiserDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        reseller: string
      }
  ) {
  }

  ngOnInit(): void {
    this.resellerId = this.data.reseller;
  }

  public addition(): void {
    if (this.form.valid) {
      this.advertiser = {
        CompanyName: this.form.controls.companyName.value,
        ResellerID: this.resellerId,
        Address: this.form.controls.address.value,
        Zip: this.form.controls.zip.value,
        City: this.form.controls.city.value,
        State: this.form.controls.state.value,
        Country: this.form.controls.country.value,
        VAT: this.form.controls.vat.value,
        Phone: this.form.controls.phone.value,
        Fax: this.form.controls.fax.value,
        Web: this.form.controls.web.value,
        EmailAddress: this.form.controls.email.value,
        FirstName: this.form.controls.firstname.value,
        LastName: this.form.controls.lastname.value,
        TimeZone: this.form.controls.timezone.value,
        CustomCSS: this.form.controls.customCss.value,
        MaxActiveCampaigns: this.form.controls.maxCampaigns.value.toString(),
        PublicAdvCampaigns: this.form.controls.publicAdvCampaigns.value.toString(),
        ERPCode: this.form.controls.erpCode.value,
        Notes: this.form.controls.notes.value,
        Locked: this.form.controls.locked.value
      }

      this.dialogRef.close(this.advertiser)
    }
  }
}
