<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="this.isAdd">{{ 'DEVICE_MODEL.ADD' | translate }}</h3>
    <h3 class="title3 mb-3" *ngIf="!this.isAdd">{{ 'DEVICE_MODEL.UPDATE' | translate }}</h3>
    
    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE_MODEL.BRAND' | translate }}</mat-label>
            <input matInput formControlName="brand" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.TYPE' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" formControlName="type">
                <mat-option *ngFor="let type of deviceTypes" value={{type.Id}}>
                    {{type.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.PROPERTIES' | translate }}</mat-label>
            <input matInput formControlName="properties" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customTextArea mb-3">
            <mat-label>{{ 'GENERAL.EVENT_BODY' | translate }}</mat-label>
            <textarea   matInput formControlName="eventBody"                      
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="3"
                        cdkAutosizeMaxRows="10"  
                        autocomplete="off">
            </textarea>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button *ngIf="this.isAdd" type="submit" class="btn actionButton w-50 mr-1" (click)="addition()">
            {{ 'GENERAL.ADD' | translate }}
        </button>
        <button mat-button *ngIf="!this.isAdd" type="submit" class="btn actionButton w-50 mr-1" (click)="addition()">
            {{ 'GENERAL.UPDATE' | translate }}
        </button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>