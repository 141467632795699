<div *ngIf="!isSidebarOpened">
    <a mat-list-item class="menuItem customBorderRadius p-0 text-center" matTooltip="{{ item.Title | translate }}"
        (click)="onItemSelected(); warnAboutSelection();" [@submenuOpen]="isChild ? submenuState : null">
        <i class="parentIcon" [ngClass]="!expanded && !this.active ? (item.Image + '-' + 'outline') : item.Image"></i>
    </a>
</div>

<div *ngIf="isSidebarOpened">
    <div [ngClass]="!isChild ? 'parentDiv' : 'childDiv'">
        <a mat-list-item class="menuItem" [ngClass]="{
            'active': this.active, 
            'expanded': expanded, 
            'childBg': isChild,
            'customBorderRadius': (!isChild && !expanded) || (!item.Childs || item.Childs.length === 0)}"
            [ngStyle]="{'padding-left': (depth * 12) + 'px'}" id="{{item.Id}}"
            (click)="onItemSelected(); warnAboutSelection()" [@submenuOpen]="isChild ? submenuState : null">

            <div class="d-flex align-items-center pl-3">
                <i class="parentIcon" [ngClass]="!expanded && !this.active ? (item.Image + '-' + 'outline') : item.Image"></i>

                <span class="parentTitle linkText flex-grow-1">{{ item.Title | translate }}</span>
                
                <i *ngIf="item.Childs && item.Childs.length" class="urbanicon-expand-more mr-2 d-flex align-items-center"
                [@indicatorRotate]="expanded ? 'expanded': 'collapsed'"></i>
            </div>
        </a>
    </div>

    <div>
        <urban-menu-list *ngFor="let child of item.Childs; let isFirst = first; let isLast = last" [item]="child"
            [depth]="depth+1" [isChild]="true" [submenuState]="this.submenuState" [expandedItem]='this.expandedItemId'
            [activeItem]='this.activeItemId' (sidebarToggler)="this.sidebarCloser()"
            (clickedToggler)="this.clickedEvent($event)" [ngClass]="{'last-child': isLast}">
        </urban-menu-list>
    </div>
</div>