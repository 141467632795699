<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }} {{ 'GENERAL.LOCATION' | translate }}</h3>
    <h3 class="title3 mb-3" *ngIf="!this.isAdd">{{ 'GENERAL.DETAIL' | translate }} {{ 'GENERAL.LOCATION' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.DESCRIPTION' | translate }}</mat-label>
            <input matInput formControlName="description" autocomplete="off" required>
        </mat-form-field>

        <div class="d-flex w-100 mb-3">
            <mat-form-field appearance="outline" class="customDatePicker w-50 mr-1">
                <mat-label>{{'GENERAL.START_DATE' | translate }}</mat-label>
                <input matInput [owlDateTime]="dt1" (dateChange)="setDate($event.target.value)" 
                formControlName="start" [owlDateTimeTrigger]="dt1" readonly>
    
                <i [owlDateTimeTrigger]="dt1" matSuffix class="urbanicon-monitoring-outline pointer"></i>
    
                <owl-date-time #dt1></owl-date-time>
            </mat-form-field>
    
            <mat-form-field appearance="outline" class="customDatePicker w-50 ml-1">
                <mat-label>{{'GENERAL.END_DATE' | translate }}</mat-label>
                <input matInput [owlDateTime]="dt2" (dateChange)="setDate($event.target.value)"
                formControlName="end" [owlDateTimeTrigger]="dt2" readonly>
    
                <i [owlDateTimeTrigger]="dt2" matSuffix class="urbanicon-monitoring-outline pointer"></i>
    
                <owl-date-time #dt2></owl-date-time>
            </mat-form-field>
        </div>

        <mat-error *ngIf="error">
            {{ error }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="this.isAdd" (click)="addition()">
            {{ 'GENERAL.ADD' | translate }}
        </button>

        <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="!this.isAdd" (click)="addition()">
            {{ 'GENERAL.UPDATE' | translate }}
        </button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'GENERAL.CLOSE' | translate }}</button>
    </div>
</div>