<div class="myContainer">
    <div class="d-flex customMargin" *ngIf="!confirmChoice">
        <span class="paragraphText mr-4 pointer" [ngClass]="{'active' : dashboardManagementView}" (click)="dashboardManagementView = true">1. <span class="underline">{{(editMode ? 'DASHBOARD.EDIT' : 'DASHBOARD_MANAGEMENT.ADD_DASHBOARD') | translate }}</span></span>
        <span class="paragraphText pointer" [ngClass]="{'active': !dashboardManagementView, 'pointer': dashboardForm.valid}"
            (click)="dashboardForm.valid ? dashboardManagementView = false : ''">2. <span class="underline">{{(editMode ? 'DASHBOARD.EDIT_WIDGET' : 'DASHBOARD.ADD_WIDGET') | translate }}</span>
        </span>
    </div>

    <h3 *ngIf="dashboardManagementView && !confirmChoice" class="mb-0 title3">{{(editMode ? 'DASHBOARD.EDIT' : 'DASHBOARD_MANAGEMENT.ADD_DASHBOARD') | translate }}</h3>
    <h3 *ngIf="!dashboardManagementView && !confirmChoice" class="mb-0 title3">{{(editMode ? 'DASHBOARD.EDIT_WIDGET' : 'DASHBOARD.ADD_WIDGET') | translate }}</h3>
    <span *ngIf="!confirmChoice" class="subheadText customMargin d-block secondaryTextColor">{{ 'GENERAL.DOMAIN' | translate }} {{data.currentDomain ?? ''}}</span>
    
    <div *ngIf="!confirmChoice" class="mb-5">
        <div *ngIf="dashboardManagementView">
            <form [formGroup]="dashboardForm" class="d-flex flex-column">
                <mat-form-field appearance="outline" class="customMatFormField mb-3">
                    <mat-label>{{ 'CREATE_DASHBOARD_DIALOG.NAME' | translate }}</mat-label>
                    <input matInput formControlName="name" autocomplete="off" placeholder="{{ 'CREATE_DASHBOARD_DIALOG.INSTRUCTION' | translate }}">
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label class="secondaryTextColor paragraphText">{{ 'DASHBOARD_MANAGEMENT_DIALOG.TYPE' | translate }}</mat-label>
                    <mat-select hideSingleSelectionIndicator="true" formControlName="type">
                        <mat-option value="">
                            <span class="paragraphText">{{ 'DASHBOARD_MANAGEMENT_DIALOG.CUSTOM' | translate }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>

        <div *ngIf="!dashboardManagementView" class="d-flex flex-column">
            <button type="button" mat-button class="btn outlineActionButton" (click)="checkIfSelected()" [matMenuTriggerFor]="selectWidgetMenu">{{ 'DASHBOARD.ADD_WIDGET' | translate }}</button>

            <mat-menu #selectWidgetMenu="matMenu" class="customMenu h-75">
                <div class="menuPadding overlayBG">
                    <mat-selection-list #selectionList class="d-flex flex-column" #widget (selectionChange)="onGroupsChange(widget.selectedOptions.selected)">
                        <mat-list-option (click)="$event.stopPropagation()" [disableRipple]="true" *ngFor="let widget of data.widgetList" [value]="widget">
                            {{widget.Name}}
                        </mat-list-option>
                    </mat-selection-list>
                </div>
            </mat-menu>

            <div class="mt-4">
                <div *ngFor="let widget of selectedWidgets" class="d-flex align-items-center d-inline-flex selectedWidget">
                    <span class="paragraphText">{{widget.Name}}</span>
                    <i class="urbanicon-remove active pointer" (click)="removeWidget(widget.Id)"></i>
                </div>
            </div>
        </div>

        <mat-error *ngIf="error" class="mt-2">
            {{ 'GENERAL.ERROR_ALL_FIELDS_REQUIRED' | translate }}
        </mat-error>
    </div>

    <div *ngIf="confirmChoice" class="d-flex flex-column customMargin">
        <div class="text-center">
            <i class="urbanicon-dashboard active"></i>
        </div>

        <h3 class="title3 mb-2">{{ (editMode ? 'DASHBOARD_MANAGEMENT_DIALOG.DASHBOARD_EDITED' : 'DASHBOARD_MANAGEMENT_DIALOG.DASHBOARD_ADDED') | translate }}</h3>

        <span class="subheadText secondaryTextColor" *ngIf="!editMode">{{ 'DASHBOARD_MANAGEMENT_DIALOG.DASHBOARD_ADDED_INFO' | translate }}</span>
    </div>
    
    <div class="d-flex justify-content-between">
        <button *ngIf="!confirmChoice" type="button" mat-button class="btn outlineActionButton w-50 mr-1" (click)="cancel()">{{ (dashboardManagementView ? 'CREATE_DASHBOARD_DIALOG.CANCEL' : 'DASHBOARD_MANAGEMENT_DIALOG.SKIP')  | translate }}</button>
        <button *ngIf="!confirmChoice" type="button" mat-button class="btn actionButton w-50 ml-1" (click)="continue()">{{ (dashboardManagementView ? 'DASHBOARD_MANAGEMENT_DIALOG.NEXT' : 'CONFIRMATION.CONFIRM') | translate }}</button>
        <button *ngIf="confirmChoice" type="button" mat-button class="btn actionButton w-100" (click)="continue()">{{ 'GENERAL.CLOSE' | translate }}</button>
    </div>
</div>