<div class="dialogContainer">
    <div class="d-flex notificationHeader">
        <i class="urbanicon-info"></i>
        <p class="paragraphText mr-auto">{{ 'NOTIFICATION.NEW_NOTIFICATION' | translate | uppercase }}</p>
        <p class="paragraphText">{{ data.singleNotification.Created * 1000 | date : 'short' }}</p>
    </div>

    <div class="notificationContent mb-3 overflow-auto">
        <p class="paragraphText">{{data.singleNotification.Payload}}</p>
    </div>

    <button mat-button type="button" class="btn outlineActionButton w-100" [matDialogClose]="data.singleNotification?.Id">{{ 'GENERAL.CLOSE' | translate }}</button>
</div>