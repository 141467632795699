import { timer } from 'rxjs';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { WeatherElementsValues, WeatherstationHeatmapElement } from '../../models/weatherstationHeatmapElement';
import { GoogleMap } from '@angular/google-maps';
import { PassDataService } from '../../services/pass-data/pass-data.service';
import { first } from 'rxjs/operators';
import { DomainProperty } from '../../models/domainProperty';

@Component({
  selector: 'urban-heatmap-weather-maps',
  templateUrl: './heatmap-weather-maps.component.html',
  styleUrls: ['./heatmap-weather-maps.component.scss']
})
export class HeatmapWeatherMapsComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(GoogleMap, { static: false }) public map: GoogleMap;
  public heatmap: google.maps.visualization.HeatmapLayer;
  public mapOptions: google.maps.MapOptions;
  public mapBounds: google.maps.LatLngBounds;
  public mapZoom: number;
  private defaultPosition: google.maps.LatLng = null;
  public gradient: string[] = [
    "rgba(0, 255, 255, 0)",
    "#69D27D",
    "#E7CD77",
    "#EB565D"
  ]
  @Input('heatmapElements') public passedHeatmapElements: WeatherstationHeatmapElement[];
  @Input('elementToAnalyze') public passedElementToAnalyze: string;
  @Input('maxValuesHeatmap') public heatmapMaxValues: WeatherElementsValues;
  @Input('passedLastUpdate') public passedLastUpdate: number;
  @Input('initialZoom') private defaultZoom: number;
  @Input('darkModeStatus') public isDarkActive: boolean;

  constructor(private passDataService: PassDataService) {
    this.passDataService.currentDomainProperties$.pipe(first()).subscribe(properties => {
      let defCoordinatesProp: DomainProperty = properties
        .find((property: DomainProperty) => property.Key === 'DefaultCoordinates');
      if (defCoordinatesProp) {
        try {
          let coordinatesObject: any = JSON.parse(defCoordinatesProp.Value);
          if (['lat', 'lng'].every((key: string) => Object.keys(coordinatesObject).includes(key))) {
            this.defaultPosition = new google.maps.LatLng(coordinatesObject.lat, coordinatesObject.lng);
          }
        }
        catch {}
      }
    });
  }

  ngOnInit(): void {
    this.mapOptions = {
      mapId: this.isDarkActive ? 'af8729d51fc92cc9' : '805d80ad475b1388',
      gestureHandling: "cooperative",
      mapTypeId: "roadmap"
    } as google.maps.MapOptions
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.passedElementToAnalyze && changes.passedElementToAnalyze.currentValue !== changes.passedElementToAnalyze.previousValue &&
      changes.passedElementToAnalyze.firstChange === false) {
      this.heatmap.setMap(null)
      this.setHeatmap();
    }
    if (changes.passedLastUpdate && changes.passedLastUpdate.currentValue !== changes.passedLastUpdate.previousValue && !changes.passedLastUpdate.firstChange) {
      this.heatmap.setMap(null)
      this.setHeatmap();
    }

    if (changes.isDarkActive && changes.isDarkActive.currentValue !== changes.isDarkActive.previousValue && !changes.isDarkActive.firstChange) {
      this.mapZoom = this.map.getZoom();

      this.mapOptions = {
        mapId: this.isDarkActive ? 'af8729d51fc92cc9' : '805d80ad475b1388',
        gestureHandling: "cooperative",
        mapTypeId: "roadmap",
        zoom: this.mapZoom
      } as google.maps.MapOptions,

      this.mapBounds = this.map.getBounds();
      this.reInitMap();
    }

  }

  public initMap(): void {
    this.setHeatmap();

    this.setBounds();
    timer(0).subscribe(() => {
      this.map.fitBounds(this.mapBounds);
    });
  }

  public reInitMap(): void {
    this.map.googleMap = new google.maps.Map(this.map.googleMap.getDiv(), this.mapOptions);

    this.setHeatmap();
    this.setBounds();

    timer(0).subscribe(() => {
      this.map.fitBounds(this.mapBounds, 0);
    });
  }

  public setHeatmap(): void {
    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: this.getPoints(),
      maxIntensity: this.heatmapMaxValues[this.passedElementToAnalyze],
      map: this.map.googleMap,
      radius: 70,
      gradient: this.gradient
    });
  }

  private setBounds(): void {
    if (this.mapBounds === undefined) {
      this.mapBounds = new google.maps.LatLngBounds();
      if (this.passedHeatmapElements?.length > 0) {
        for (let i = 0; i < this.passedHeatmapElements.length; i++) {
          this.mapBounds.extend(new google.maps.LatLng(this.passedHeatmapElements[i].lat, this.passedHeatmapElements[i].lng));
        }
      }
      else if (this.defaultPosition) { //case no devices
        this.mapBounds.extend(this.defaultPosition);
        this.map.fitBounds(this.mapBounds);
        this.map.boundsChanged.pipe(first()).subscribe(() => {
          if (this.defaultZoom !== undefined) {
            this.map.googleMap.setZoom(this.defaultZoom);
          }
        });
      }
    }
  }

  /* public toggleHeatmap(): void {
  this.heatmap.setMap(this.heatmap.getMap() ? null : this.map.googleMap);
  } */

  public getPoints(): google.maps.visualization.WeightedLocation[] {
    let pointsToShow: google.maps.visualization.WeightedLocation[] = [];

    for (let sensor of this.passedHeatmapElements) {
      let sensorValue: number = sensor.values[this.passedElementToAnalyze];
      if (sensorValue !== undefined) {
        pointsToShow.push({
          location: new google.maps.LatLng(sensor.lat, sensor.lng),
          weight: sensor.values[this.passedElementToAnalyze]
        });
      }
    }

    return pointsToShow;
  }
}
