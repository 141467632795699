<div class="dialogContainer">
    <h3 class="title3 mb-3">{{ 'GENERAL.ADD' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.DESCRIPTION' | translate }}</mat-label>
            <input matInput formControlName="description"
                autocomplete="off" required>
        </mat-form-field>

        <div class="d-flex mb-3">
            <mat-form-field appearance="outline" class="customDatePicker mr-2">
                <mat-label>{{'GENERAL.START_DATE' | translate }}</mat-label>
                <input matInput [owlDateTime]="dt1" [min]="minDate" [max]="maxDate" formControlName="startDate" [owlDateTimeTrigger]="dt1" readonly>
    
                <i [owlDateTimeTrigger]="dt1" matSuffix class="urbanicon-monitoring-outline pointer"></i>
    
                <owl-date-time #dt1></owl-date-time>
            </mat-form-field>
    
            <mat-form-field appearance="outline" class="customDatePicker">
                <mat-label>{{'GENERAL.END_DATE' | translate }}</mat-label>
                <input matInput [owlDateTime]="dt2" f[min]="minDate" [max]="maxDate" formControlName="endDate" [owlDateTimeTrigger]="dt2" readonly>
    
                <i [owlDateTimeTrigger]="dt2" matSuffix class="urbanicon-monitoring-outline pointer"></i>
    
                <owl-date-time #dt2></owl-date-time>
            </mat-form-field>
        </div>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'SMSS.TIMETABLES' | translate }}</mat-label>
            <input matInput formControlName="timetable" type="number" min="0" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'SMSS.FREQUENCY' | translate }}</mat-label>
            <input matInput
                formControlName="frequency" type="number" min="0" max="100" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customTextArea mb-3">
            <mat-label>{{ 'GENERAL.NOTES' | translate }}</mat-label>
            <textarea   matInput formControlName="notes"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="10"
                        autocomplete="off">
            </textarea>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'SMSS.LOCKED' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" formControlName="locked">
                <mat-option value="false">
                    {{ 'GENERAL.FALSE' | translate }}
                </mat-option>
                <mat-option value="true">
                    {{ 'GENERAL.TRUE' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="addition()">
            {{'GENERAL.ADD' | translate }}
        </button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" mat-dialog-close>{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>
