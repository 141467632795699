import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, AfterViewInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { MapInfoWindow, GoogleMap, MapMarker } from '@angular/google-maps';
import { AddDeviceDialogComponent } from '../../dialogs/add-device-dialog/add-device-dialog.component';
import { Device } from '../../models/device';
import { GoogleMarker, InfoContentData } from '../../models/googleMarker';
import { MatDialog } from '@angular/material/dialog';
import { DeviceBrand } from '../../models/deviceBrand';
import { DeviceModel } from '../../models/deviceModel';
import { MainSubscriptionsService } from '../../services/main-subscriptions/main-subscriptions.service';
import { SMSSGateway } from '../../models/smss/smssGateway';
import { PassDataService } from '../../services/pass-data/pass-data.service';
import { first, takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';
import { AdministratorApiService } from '../../services/administrator-api.service';
import { DeviceGroup } from '../../models/deviceGroup';
import { Router } from '@angular/router';
import { DeviceGroupDetailDialogComponent } from '../../dialogs/device-group-detail-dialog/device-group-detail-dialog.component';
import { LocationDialogComponent } from '../../dialogs/location-dialog/location-dialog.component';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { Location } from '../../models/location';
import { GooglePolygon } from '../../models/googlePolygon';
import { DomainProperty } from '../../models/domainProperty';

@Component({
  selector: 'urban-maps-widget',
  templateUrl: './maps-widget.component.html',
  styleUrls: ['./maps-widget.component.scss']
})
export class MapsWidgetComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap
  @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  private listLocations: Location[];
  private listBrands: DeviceBrand[];
  private listModels: DeviceModel[];
  private defaultPosition: google.maps.LatLng = null;
  private firstBoundsSaving: boolean = true;
  private guidPattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public mapOptions: google.maps.MapOptions;
  public mapBounds: google.maps.LatLngBounds;
  public mapZoom: number;
  public markers: Array<GoogleMarker> = [];
  public polygons: Array<GooglePolygon> = [];
  public groups: boolean = false;
  public isAdmin: boolean = false;
  public currentUrl: string;
  public refreshMap: boolean = false;
  public infoActionPanel: boolean = false;
  private firstLocationsUpdate: boolean = true;

  @Input('asWidget') public meantAsWidget: boolean = false;
  @Input('showGroups') public isGroupsShown: boolean = false;
  @Input('editMode') public editMode: string = '';
  @Input('devicesList') public listDevices: Device[];
  @Input('deviceGroupList') public listDeviceGroups: DeviceGroup[];
  @Input('singleLocation') public singleLocation: Location;
  @Input('gatewaysList') public listGateways: SMSSGateway[];
  @Input('showInfo') public showInfoWindow: boolean = true;
  @Input('passedHeight') public passedHeight: number = null;
  @Input('darkModeStatus') public isDarkActive: boolean;
  @Input('initialZoom') private defaultZoom: number;
  @Input('outputChangePosition') private outputChangePosition: boolean = false;

  @Output('changeDevicePosition') private changeDevicePositionEvent: EventEmitter<Device> = new EventEmitter<Device>();
  @Output('changeDeviceGroupsPosition') private changeDeviceGroupsPositionEvent: EventEmitter<DeviceGroup[]> = new EventEmitter<DeviceGroup[]>();
  @Output('changeLocationPosition') private changeLocationPositionEvent: EventEmitter<Location> = new EventEmitter<Location>();

  constructor(
    private mainService: MainSubscriptionsService,
    private passDataService: PassDataService,
    private administratorApiService: AdministratorApiService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(roles => {
      if (roles?.length > 0) {
        this.isAdmin = roles.some(x => x.Name === 'Administrators' || x.Name === 'Domain admin');
      }
    });
    this.passDataService.deviceBrands$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(brands => {
      this.listBrands = brands;
    });
    this.passDataService.deviceModels$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(models => {
      this.listModels = models;
    });
    this.passDataService.currentDomainProperties$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(properties => {
        let defCoordinatesProp: DomainProperty = properties
          .find((property: DomainProperty) => property.Key === 'DefaultCoordinates');
        if (defCoordinatesProp) {
        try {
          let coordinatesObject: any = JSON.parse(defCoordinatesProp.Value);
          if (['lat', 'lng'].every((key: string) => Object.keys(coordinatesObject).includes(key))) {
            this.defaultPosition = new google.maps.LatLng(coordinatesObject.lat, coordinatesObject.lng);
          }
        }
        catch {}
      }
    });
    this.passDataService.locations$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(locations => {
      this.listLocations = locations;

      if (!this.firstLocationsUpdate) {
        this.refreshMarkers();
      }

      this.firstLocationsUpdate = false;
    });
  }

  ngAfterViewInit() {
    this.setBounds();
  }

  ngOnInit(): void {
    this.currentUrl = this.router.url.split('/').pop().split(';')[0];

    this.mapOptions = {
      mapId: this.isDarkActive ? 'af8729d51fc92cc9' : '805d80ad475b1388',
      gestureHandling: "cooperative",
      mapTypeId: "roadmap"
    } as google.maps.MapOptions

    this.addLocationAndGatewayMarkers(true);
    this.addDeviceMarkers(true);
  }

  private setBounds(): void {
    if (this.mapBounds === undefined) {
      let mapBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
      let atLeastOnePosition: boolean = false;
      if (this.listDevices != null && this.listDevices.length !== undefined) {
        for (let i = 0; i < this.listDevices.length; i++) {
          mapBounds.extend(new google.maps.LatLng(this.listDevices[i].Latitude, this.listDevices[i].Longitude));
          atLeastOnePosition = true;
        }
      }
      if (this.listGateways != null && this.listGateways.length !== undefined) {
        for (let i = 0; i < this.listGateways.length; i++) {
          mapBounds.extend(new google.maps.LatLng(+this.listGateways[i].Latitude, +this.listGateways[i].Longitude));
          atLeastOnePosition = true;
        }
      }
      if (this.singleLocation) {
        mapBounds.extend(new google.maps.LatLng(+this.singleLocation.Latitude, +this.singleLocation.Longitude));
        atLeastOnePosition = true;
      }
      if (!atLeastOnePosition && this.defaultPosition) {
        mapBounds.extend(this.defaultPosition);
      }
      if(mapBounds) {
        this.mapBounds = mapBounds;
        this.map.boundsChanged.pipe(first()).subscribe(() => {
          this.map.fitBounds(this.mapBounds);
          this.map.boundsChanged.pipe(first()).subscribe(() => {
            if (this.defaultZoom !== undefined && !atLeastOnePosition) {
              this.map.googleMap.setZoom(this.defaultZoom);
            }
          });
        });
      }
    } else {
      this.tryTillCondition( 'fit',
        () => {
          this.map.fitBounds(this.mapBounds, 0);
        },
        () => this.map !== undefined && this.mapBounds !== null,
        true);
    }
  }

  private tryTillCondition(name: string, callback: () => void, condition: () => boolean = () => true, firstTime: boolean = false): void {
    timer(firstTime ? 0 : 500).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      if (condition()) {
        callback();
      }
      else {
        this.tryTillCondition(name, callback, condition);
      }
    });
  }

  public showGroups(groups: DeviceGroup[]): void {
    this.polygons = [];
    this.groups = true;

    groups.forEach(group => {
      let coordinates: google.maps.LatLng[] = [];

      group.Devices.forEach(groupDevice => {
        if (this.listDevices.some(x => x.Id === groupDevice.Id)) {
          coordinates.push(new google.maps.LatLng(groupDevice.Latitude, groupDevice.Longitude));
        }
      });

      if (coordinates.length > 0) {
        coordinates.push(coordinates[0]);

        let polygon = new GooglePolygon(coordinates, { name: group.Name, id: group.Id, color: group.Color }, {
          paths: coordinates,
          geodesic: true,
          clickable: true,
          draggable: false,
          editable: false,
          strokeColor: group.Color,
          fillColor: group.Color,
          fillOpacity: 0.1,
          strokeWeight: 3
        });

        this.polygons.push(polygon);
      }
    });
  }

  public hideGroups() {
    this.polygons = [];
    this.groups = false;
  }

  public addGroupsToMarkers(): void {
    this.listDeviceGroups?.forEach(group => {
      group.Devices.forEach(groupDevice => {

        if (this.listDevices.some(x => x.Id === groupDevice.Id)) {
          let marker = this.markers.find(x => x.info === groupDevice.Id);

          if (marker !== undefined && marker !== null) {
            if (marker.groups === undefined || marker.groups === null) {
              marker.groups = [];
            }

            const groupToAdd = { Id: group.Id, Name: group.Name, Color: group.Color };

            if (!marker.groups.some(group => group.Id === groupToAdd.Id)) {
              marker.groups.push(groupToAdd);
            }
          }
        }
      });
    });
  }

  public openDeviceGroupDetail(deviceGroupId: string, deviceGroupName: string): void {
    console.log(deviceGroupId, deviceGroupName)
    const deviceGroupDetailDialogRef = this.dialog.open(DeviceGroupDetailDialogComponent, {
      data: {
        deviceGroupName: deviceGroupName
      }
    });

    deviceGroupDetailDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.mainService.setNavigationInfoComand({ Id: deviceGroupId, BackRoute: this.currentUrl });
        this.router.navigate(['main/device-group-detail']);
      }
    });
  }

  public openLocationDetail(locationId: string): void {
    if (locationId === null || locationId === undefined || locationId === '') {
      return;
    }

    let location = this.listLocations.find(x => x.Id === locationId);

    if (location === null || location === undefined) {
      return;
    }

    const locationDetailDialogRef = this.dialog.open(LocationDialogComponent, {
      data: {
        location: location
      },
      width: '50%'
    });

    locationDetailDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(updatedLocation => {
      if (updatedLocation) {
        const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent);

        confirmationDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            if (this.currentUrl !== 'dashboard') {
              this.mainService.setNavigationInfoComand({ BackRoute: this.currentUrl });
            }
            this.mainService.updateLocationComand(updatedLocation);
          }
        });
      }
    });
  }

  private getIcon(deviceTypeMapPin: string): google.maps.Icon {
    if (deviceTypeMapPin?.includes('_')) {
      deviceTypeMapPin.replace('_', '-')
    }

    return {
      url: deviceTypeMapPin ? `/assets/img/icons/${deviceTypeMapPin}.png` : '',
      labelOrigin: new google.maps.Point(90, 20)
    };
  }

  private addLocationAndGatewayMarkers(animation: boolean): void {
    if (this.listGateways?.length > 0) {
      for (let i = 0; i < this.listGateways.length; i++) {

        this.markers.push(
          new GoogleMarker(
            new google.maps.LatLng(+this.listGateways[i].Latitude, +this.listGateways[i].Longitude),
            {
              color: 'black',
              //text: this.listGateways[i].GatewayName,
              text: this.listGateways[i].GatewayName.length > 12 ? this.listGateways[i].GatewayName.slice(0, 12) + '...' : this.listGateways[i].GatewayName,
              fontSize: '0px',
              fontWeight: 'normal',
              scaledSize: new google.maps.Size(80, 80),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(20, 0),
              labelOrigin: null
            },
            this.listGateways[i].GatewayName,
            {
              animation: animation ? google.maps.Animation.DROP : null,
              icon: this.getIcon('pin-building')
            },
            this.listGateways[i].id,
            []
          )
        );

      }
    }

    if (this.listLocations?.length > 0) {
      let locations: Location[] = this.listLocations;

      if (this.singleLocation) {
        locations = [];
        locations.push(this.singleLocation);
      }

      for (let i = 0; i < locations.length; i++) {
        this.markers.push(
          new GoogleMarker(
            new google.maps.LatLng(+locations[i].Latitude, +locations[i].Longitude),
            {
              color: 'black',
              text: locations[i].Name.length > 12 ? locations[i].Name.slice(0, 12) + '...' : locations[i].Name,
              fontSize: '0px',
              fontWeight: 'normal',
              scaledSize: new google.maps.Size(80, 80),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(20, 0),
              labelOrigin: null
            },
            locations[i].Name,
            {
              animation: animation ? google.maps.Animation.DROP : null,
              icon: this.getIcon('pin-location'),
              draggable: (this.isAdmin && this.editMode === 'drag' && this.singleLocation) ? true : false
            },
            'Location:' + locations[i].Id,
            []
          )
        );
      }
    }
  }

  private addDeviceMarkers(animation: boolean): void {
    if (this.listDevices?.length > 0) {
      for (let i = 0; i < this.listDevices.length; i++) {
        if (this.listDevices[i].Parent === null || this.listDevices[i].Parent === undefined) {
          let children = this._buildDevices(this.listDevices[i].Childs);

          this.markers.push(
            new GoogleMarker(
              new google.maps.LatLng(this.listDevices[i].Latitude, this.listDevices[i].Longitude),
              {
                color: 'black',
                text: this.listDevices[i].Name.length > 12 ? this.listDevices[i].Name.slice(0, 12) + '...' : this.listDevices[i].Name,
                fontSize: '0px',
                fontWeight: 'normal',
                scaledSize: new google.maps.Size(80, 80),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(20, 0),
                labelOrigin: null
              },
              this.listDevices[i].Name,
              {
                animation: animation ? google.maps.Animation.DROP : null,
                icon: this.getIcon(this.listDevices[i].Model.Type.MapPin),
                draggable: this.isAdmin && this.editMode === 'drag'
              },
              this.listDevices[i].Id,
              this._buildInfoContentArray(children)
            )
          );
        }
      }

      this.addGroupsToMarkers();
    }
  }

  public executeAction(event: google.maps.MapMouseEvent): void {
    if (this.isAdmin) {
      if (this.editMode === 'device') {
        this.openAddDeviceDialog(event.latLng);
      } else if (this.editMode === 'location') {
        this.openAddLocationDialog(event.latLng);
      }
    }
  };

  private openAddDeviceDialog(latLng: google.maps.LatLng): void {
    const addDeviceDialogRef = this.dialog.open(AddDeviceDialogComponent, {
      data: {
        latitude: latLng.lat(),
        longitude: latLng.lng(),
        parents: this.listDevices,
        brands: this.listBrands,
        models: this.listModels,
        parent: ''
      }
    });

    addDeviceDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            if (this.currentUrl !== 'dashboard') {
              this.mainService.setNavigationInfoComand({ BackRoute: this.currentUrl });
            }
            this.mainService.addDeviceComand(device);
          }
        });
      }
    });
  }

  private openAddLocationDialog(latLng: google.maps.LatLng): void {
    const addLocationDialogRef = this.dialog.open(LocationDialogComponent, {
      data: {
        latitude: latLng.lat(),
        longitude: latLng.lng()
      },
      width: '50%'
    });

    addLocationDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(location => {
      if (location) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.mainService.addLocationComand(location);
          }
        });
      }
    });
  }

  public openInfoWindow(marker: MapMarker, windowIndex: number, info: string) {
    if (info.startsWith('Location:')) {
      let locationId: string = info.split('Location:')[1];
      this.openLocationDetail(locationId);
    }
    else {
      /// stores the current index in forEach
      let curIdx = 0;
      this.infoWindowsView.forEach((window: MapInfoWindow) => {
        if (windowIndex === curIdx) {
          window.open(marker);
          curIdx++;
        } else {
          curIdx++;
        }
      });
    }
  }

  public setMarkerCoordinates(marker: MapMarker, id: string): void {
    if (!this.isAdmin) {
      return;
    }

    const position = marker.getPosition();
    const latitude = +position.lat();
    const longitude = +position.lng();

    if (id?.startsWith('Location:')) {
      this.setLocationMarkerCoordinates(id, latitude, longitude);
      return;
    }

    if (id?.match(this.guidPattern)) {
      this.setDeviceMarkerCoordinates(id, latitude, longitude);
    }
  }

  private setLocationMarkerCoordinates(id: string, latitude: number, longitude: number): void {
    if (this.outputChangePosition) {
      if (this.singleLocation) {
        const locationToLocate: Location = {
          ...this.singleLocation,
          Latitude: latitude,
          Longitude: longitude
        };

        this.changeLocationPositionEvent.emit(locationToLocate);
        return;
      }

      const locationId: string = id.split('Location:')[1];
      const locationToLocateIndex: number = this.listLocations.findIndex(location => location.Id === locationId);

      if (locationToLocateIndex !== -1) {
        const locationToLocate: Location = {
          ...this.listLocations[locationToLocateIndex],
          Latitude: latitude,
          Longitude: longitude
        };

        this.changeLocationPositionEvent.emit(locationToLocate);
      }
    }
  }

  private setDeviceMarkerCoordinates(id: string, latitude: number, longitude: number): void {
    if (this.outputChangePosition) {
      let deviceToLocateIndex: number = this.listDevices.findIndex(device => device.Id === id);

      if (deviceToLocateIndex !== -1) {
        let deviceToLocate: Device = {
          ...this.listDevices[deviceToLocateIndex],
          Latitude: latitude,
          Longitude: longitude
        };

        this.changeDevicePositionEvent.emit(deviceToLocate);
      }
    }
    else {
      this.administratorApiService.deviceSetCoordinates(id, latitude, longitude).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        if (res) {
          this.mainService.updateAllDataAndNavigateComand();

          if (this.isAdmin) {
            this.administratorApiService.deviceGroupList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(groups => {
              if (groups?.length > 0) {
                this.listDeviceGroups = [...groups];
                this.changeDeviceGroupsPositionEvent.emit(this.listDeviceGroups);

                if (this.isGroupsShown) {
                  this.showGroups(this.listDeviceGroups);
                }
              }
            });
          }
        }
      });
    }
  }

  public openInfo(content: string): void {
    if (content != undefined && content != null && content != '' && content.match(this.guidPattern)) {
      this.goToDeviceDetail(content);
    }
  }

  public goToDeviceDetail(deviceId: string): void {
    this.mainService.setNavigationInfoComand({ Id: deviceId, BackRoute: this.currentUrl });
    this.mainService.updateAllDataAndNavigateComand('device');
  }

  private _buildInfoContentArray(devices: Device[]): InfoContentData[] {

    if (!devices || devices === undefined) {
      return [];
    }

    let elements: Array<InfoContentData> = [];

    devices.forEach((element) => {
      if (elements.some(function (item) {
        return item.Name == element.Model.Type.Name
      })) {
        elements.find(x => x.Name == element.Model.Type.Name).Elements++;
      }
      else {
        elements.push(new InfoContentData(element.Model.Type.Name, element.Model.Type.Icon))
      }
    });

    return elements;
  }

  private _buildDevices(devices: Device[]): Device[] {
    if (!devices || devices === undefined) {
      return [];
    }

    let childs: Device[] = [];

    for (let device of devices) {
      if (device.Childs?.length > 0) {
        childs.push(device);
        for (let child of device.Childs) {
          childs.push(child);
        }
      }
      else {
        childs.push(device);
      }
    }

    return childs;
  }

  public saveBounds(): void {
    if (this.firstBoundsSaving || false) {
      this.firstBoundsSaving = false;
    }
    else {
      let mapBounds: google.maps.LatLngBounds = this.map.getBounds();
      if (mapBounds !== null) {
        this.mapBounds = mapBounds;
      }
    }
  }

  private refreshMarkers(): void {
    this.markers = [];
    this.addLocationAndGatewayMarkers(false);
    this.addDeviceMarkers(false);
  }

  public goToMapPage(): void {
    const actualRoute: string = this.router.url.split('/').pop();
    this.mainService.setNavigationInfoComand({ BackRoute: actualRoute });
    this.router.navigate(['main/map']);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDarkActive && changes.isDarkActive.currentValue !== changes.isDarkActive.previousValue && !changes.isDarkActive.firstChange) {
      this.refreshMap = true;
      timer(0).subscribe(() => {
        this.mapOptions = {
          mapId: this.isDarkActive ? 'af8729d51fc92cc9' : '805d80ad475b1388',
          gestureHandling: "cooperative",
          mapTypeId: "roadmap",
        } as google.maps.MapOptions;

        this.refreshMap = false;
        this.setBounds();
      });
    }

    if (changes.listDevices && changes.listDevices.currentValue !== changes.listDevices.previousValue && !changes.listDevices.firstChange) {
      this.refreshMarkers();
    }

    if (changes.editMode && changes.editMode.currentValue !== changes.editMode.previousValue && !changes.editMode.firstChange) {
      this.editMode ? this.infoActionPanel = true : this.infoActionPanel = false;

      if ((!this.editMode && this.markers.some(marker => marker.options.draggable)) || this.editMode === 'drag') {
        this.refreshMarkers();
      }
    }

    if (changes.isGroupsShown && changes.isGroupsShown.currentValue !== changes.isGroupsShown.previousValue && !changes.isGroupsShown.firstChange) {
      if (this.isGroupsShown) {
        this.showGroups(this.listDeviceGroups);
      } else {
        this.hideGroups();
      }
    }

    if (changes.listDeviceGroups && changes.listDeviceGroups.currentValue !== changes.listDeviceGroups.previousValue && !changes.listDeviceGroups.firstChange) {
      this.addGroupsToMarkers();

      if (this.isGroupsShown) {
        this.showGroups(this.listDeviceGroups);
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
