<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="this.isAdd">{{ 'RESOURCE.ADD' | translate }}</h3>
    <h3 class="title3 mb-3" *ngIf="!this.isAdd">{{ 'RESOURCE.DETAIL' | translate }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'RESOURCE.URL' | translate }}</mat-label>
            <input matInput formControlName="url" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'RESOURCE.PARENT' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" formControlName="parentSelected">
                <mat-option *ngFor="let parentResource of parentResources" value={{parentResource.Id}}>
                    {{parentResource.Url}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'RESOURCE.LABEL' | translate }}</mat-label>
            <input matInput formControlName="label" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'RESOURCE.TITLE' | translate }}</mat-label>
            <input matInput formControlName="title" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'RESOURCE.IMAGE' | translate }}</mat-label>
            <input matInput formControlName="image" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'RESOURCE.TYPE' | translate }}</mat-label>
            <input matInput formControlName="type" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'RESOURCE.ORDER' | translate }}</mat-label>
            <input matInput type="number" formControlName="order"
                autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'RESOURCE.IS_PUBLIC' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" formControlName="isPublic">
                <mat-option value="0">
                    {{ 'GENERAL.NO' | translate }}
                </mat-option>
                <mat-option value="1">
                    {{ 'GENERAL.YES' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'RESOURCE.REQUIRE_TOKEN' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" formControlName="requireToken">
                <mat-option value="0">
                    {{ 'GENERAL.NO' | translate }}
                </mat-option>
                <mat-option value="1">
                    {{ 'GENERAL.YES' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="this.isAdd" (click)="addition()">{{ 'GENERAL.ADD' | translate }}</button>
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" *ngIf="!this.isAdd" (click)="addition()">{{ 'GENERAL.UPDATE' | translate }}</button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>