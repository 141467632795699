import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { TranslateService } from '@ngx-translate/core';
import { MainSubscriptionsService } from '../../services/main-subscriptions/main-subscriptions.service';

@Component({
  selector: 'urban-streaming-link-dialog',
  templateUrl: './streaming-link-dialog.component.html',
  styleUrls: ['./streaming-link-dialog.component.scss']
})
export class StreamingLinkDialogComponent implements OnInit {

  public form: UntypedFormGroup = this.formBuilder.group({
    link: [{ value: '', disabled: true }]
  });


  constructor(public dialogRef: MatDialogRef<StreamingLinkDialogComponent>,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private clipboard : Clipboard,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        link: string
      }) {
    this.form.controls.link.setValue(data.link);
  }

  ngOnInit(): void {
  }

  public copyToClipboard() {
    this.clipboard.copy(this.form.get('link')?.value);

    this.translate.get('GENERAL.COPIED_TO_CLIPBOARD').subscribe(translated => {
      if (translated) {
        this.mainService.setSuccessMessageComand(translated);
      }
    });
  }
}
