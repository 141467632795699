<div class="dialogContainer">
    <h3 class="title3 mb-3">{{ 'GENERAL.DETAIL' | translate }}</h3>

    <form [formGroup]="form" class="mb-3">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.KEY' | translate }}</mat-label>
            <input matInput formControlName="key" autocomplete="off" required>
        </mat-form-field>

        <div class="d-flex align-items-center mb-3">
            <mat-checkbox formControlName="themeCheck" labelPosition="before" class="specialCheckbox">
                {{ 'GENERAL.THEME' | translate }}
            </mat-checkbox>
            <mat-checkbox formControlName="sidebarCheck" class="specialCheckbox ml-4"
                [ngClass]="{'d-none': !(sidebarResources?.length > 0)}" labelPosition="before">
                {{ 'DOMAIN_DETAIL.SIDEBAR_RESOURCES' | translate }}
            </mat-checkbox>
        </div>

        <mat-form-field appearance="outline" class="customTextArea mb-3" [ngClass]="{ 'd-none': themeCase || sidebarCase }">
            <mat-label>{{ 'GENERAL.VALUE' | translate }}</mat-label>
            <textarea
                matInput formControlName="value"                         
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="15" 
                autocomplete="off" 
                required></textarea>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>

    <div *ngIf="themeCase" class="p-3">
        <div class="d-flex flex-row justify-content-between align-items-baseline">
            <h3 class="title3">{{ 'DOMAIN_DETAIL.STYLE_PROPERTIES' | translate }}</h3>
            <a class="ml-3" target="_blank"
                href="https://dev.azure.com/envisionsrl/Urbanvision/_wiki/wikis/Urbanvision.wiki/164/Theme-property?anchor=urbanvision-theme-legend">
                {{ 'DOMAIN_DETAIL.STYLE_LEGEND' | translate }}
            </a>
        </div>

        <div *ngFor="let styleModel of styleModels; let i = index" class="d-flex flex-row justify-content-between align-items-center mb-3">
            <mat-form-field appearance="outline" class="customMatFormField flex-grow-1">
                <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
                <input #thisInput matInput [value]="styleModels[i].Variable" #tooltip="matTooltip" matTooltipDisabled [matTooltip]="'DOMAIN_DETAIL.NOT_STYLE_PROPERTY' | translate"
                   (change)="checkStyleVariableName($event, i, thisInput, tooltip)"/>
            </mat-form-field>
            <div class="circle" [style.background]="colorPicker.value">
                <input #colorPicker type="color" [(ngModel)]="styleModels[i].Value" colorpick-eyedropper-active="false">
            </div>
            <i class="ml-2" matTooltip="{{'GENERAL.REMOVE' | translate }}"
                (click)="removeStyleVariable(i)" class="iconDeleteButton urbanicon-delete">
            </i>
        </div>

        <button mat-button class="btn actionButton squareButton" [matTooltip]="'DOMAIN_DETAIL.ADD_STYLE_PROPERTY' | translate"
            (click)="addStyleVariable()"> + </button>
    </div>

    <ng-container *ngIf="sidebarCase">
        <h3 class="title3">{{ 'DOMAIN_DETAIL.SIDEBAR_RESOURCES' | translate }}</h3>
        <div class="p-3 checkboxArea">
            <mat-checkbox *ngFor="let resource of sidebarResources" class="mr-5" labelPosition="before"
                (change)="checkSidebarResource(resource.Url, $event.checked)" [checked]="sidebarAllowedResources[resource.Url]">
                {{ resource.Url }}
            </mat-checkbox>
        </div>
    </ng-container>

    <div class="d-flex justify-content-between">
        <button *ngIf="domainProperty" mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="edit()">{{ 'GENERAL.EDIT' | translate }}</button>
        <button *ngIf="!domainProperty" mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="addition()">{{ 'GENERAL.ADD' | translate }}</button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" mat-dialog-close>{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>
