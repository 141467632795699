import { NavigationInfo } from './../../shared/models/navigationExtra';
import { createAction, props } from '@ngrx/store';
import { ChangePasswordRequest } from '../../shared/models/changePasswordRequest';
import { ClientSettings } from '../../shared/models/clientSettings';
import { Device } from '../../shared/models/device';
import { DeviceBrand } from '../../shared/models/deviceBrand';
import { DeviceCreateRequest } from '../../shared/models/deviceCreateRequest';
import { DeviceDeleteRequest } from '../../shared/models/deviceDeleteRequest';
import { DeviceModel } from '../../shared/models/deviceModel';
import { DeviceUpdateRequest } from '../../shared/models/deviceUpdateRequest';
import { RemoteControlService } from '../../shared/models/siralab/remoteControlService';
import { SetEntityStateRequest } from '../../shared/models/siralab/setEntityStateRequest';
import { Sensor } from '../../shared/models/weatherstation/sensor';
import { WeatherStationRequest } from '../../shared/models/weatherstation/weatherstationRequest';
import { Resource } from '../../shared/models/resource';
import { UVNotification } from '../../shared/models/notification';
import { StyleModel } from '../../shared/models/styleModel';
import { Location } from '../../shared/models/location';
import { Dashboard, DashboardRequest, SelectedDashboardUpdater } from 'src/app/shared/models/dashboard';
import { Widget, WidgetToHandle } from 'src/app/shared/models/widget';

export const initResources = createAction(
  '[Main] Init Resources'
);

export const setResources = createAction(
  '[Main] Set Resources',
  props<{ resources: Resource[] }>()
);

export const initMapsKey= createAction(
  '[Main] Init Maps Key'
);

export const setMapsKey = createAction(
  '[Main] Set Maps Key',
  props<{ googleMapsKey: string }>()
);

export const initDashboards = createAction(
  '[Main] Init Dashboard'
);

export const setDashboards = createAction(
  '[Main] Set Dashboard',
  props<{ dashboards: Dashboard[] }>()
);

export const deleteDashboard = createAction(
  '[Main] Delete Dashboard',
  props<{ dashboardId: string }>()
);

export const createDashboard = createAction(
  '[Main] Create Dashboard',
  props<{ dashboard: DashboardRequest }>()
);

export const updateAndReInitDashboard = createAction(
  '[Main] Update And ReInit Dashboard',
  props<{ dashboard: DashboardRequest }>()
);

export const updateDashboard = createAction(
  '[Main] Update Dashboard',
  props<{ dashboard: DashboardRequest }>()
);

export const changeSelectedDashboard = createAction(
  '[Main] Change Selected Dashboard',
  props<{ dashboardsToEdit: SelectedDashboardUpdater}>()
);

export const initWidgets = createAction(
  '[Main] Init Widgets'
);

export const setWidgets = createAction(
  '[Main] Set Widgets',
  props<{ widgets: Widget[] }>()
);

export const setDashboardToShow = createAction(
  '[Main/Sidebar] Select Dashboard To Show',
  props<Dashboard>()
);

export const addWidgetsToDashboard = createAction(
  '[Main/Dashboard] Add Widgets To Dashboard',
  props<{ widgets: WidgetToHandle[] }>()
)

export const removeWidgetToDashboard = createAction(
  '[Main/Dashboard] Remove Widget To Dashboard',
  props<{ widget: WidgetToHandle }>()
)

export const reInitOnlyDashboards = createAction(
  '[Main/Dashboard] Re-Init Only Dashboards'
)

export const updateWidgetPosition = createAction(
  '[Main/Widget] Update Widget Position',
  props<{ Positions: { WidgetId: string, Position: number, Column: number }[], DashboardId: string }>()
)

export const setError = createAction(
  '[Main] Set error',
  props<{ error: string }>()
)

export const logout = createAction(
  '[Main] Logout'
)

export const initDevices = createAction(
  '[Main] Init Devices'
);

export const setDevices = createAction(
  '[Main] Set Devices',
  props<{ devices: Device[] }>()
);

export const initBrands = createAction(
  '[Main] Init Brands'
);

export const setBrands = createAction(
  '[Main] Set Brands',
  props<{ brands: DeviceBrand[] }>()
);

export const initModels = createAction(
  '[Main] Init Models'
);

export const setModels = createAction(
  '[Main] Set Models',
  props<{ models: DeviceModel[] }>()
);

//---------------------------------------------------------------------------------

export const reInitDevices = createAction(
  '[Main] Re-init Devices',
  props<{ path?: string }>()
);

export const reInitBrands = createAction(
  '[Main] Re-init Brands',
  props<{ path?: string }>()
);

export const reInitModels = createAction(
  '[Main] Re-init Models',
  props<{ path?: string }>()
);

export const goToPath = createAction(
  '[Main] Go to path',
  props<{ path?: string }>()
)
//---------------------------------------------------------------------------------

export const addDeviceToMap = createAction(
  '[Main] Add Device To Map',
  props<{ device: DeviceCreateRequest }>()
)

export const deleteDeviceFromMap = createAction(
  '[Main] Remove Device From Map',
  props<{ device: DeviceDeleteRequest }>()
)

export const updateDeviceMap = createAction(
  '[Main] Update Device',
  props<{ device: DeviceUpdateRequest }>()
)

export const changePassword = createAction(
  '[Main/Profile] Change Password',
  props<{ changePasswordRequest: ChangePasswordRequest }>()
)

export const goToMain = createAction(
  '[Main] Go To Main'
)

export const initClientSettings = createAction(
  '[Main] Init ClientSettings'
);

export const setClientSettings = createAction(
  '[Main] Set ClientSettings',
  props<{ clientSettings: ClientSettings }>()
);

export const getDeviceReport = createAction(
  '[Main/Siralab] Get RCDevice Report',
  props<{ deviceId: string, delayMilliseconds : number }>()
);

export const setRCServices = createAction(
  '[Main/Siralab] Set RC Services',
  props<{ remoteControlServices: RemoteControlService[] }>()
);

export const setEntityState = createAction(
  '[Main/Siralab] Set Entity State',
  props<{ request: SetEntityStateRequest }>()
);

export const getWSSensors = createAction(
  '[Main/WeatherStation] Get WSSensors',
  props<{ request: WeatherStationRequest }>()
);

export const setWSSensors = createAction(
  '[Main/WeatherStation] Set WS Sensors',
  props<{ weatherStationSensors: Sensor[] }>()
);

export const getDcpCamInfo = createAction(
  '[Main/DcpCam] Get DcpCamInfo',
  props<{ request: string }>()
);

export const setSuccessMessage = createAction(
  '[Main] Set Success Message',
  props<{ success: string }>()
);

export const setNotificationToShow = createAction(
  '[Main] Select Notification To Show',
  props<UVNotification>()
);


export const initDomainStyle = createAction(
  '[Main] Init Domain Style'
);

export const reInitDomainStyle = createAction(
  '[Main] Re-init Domain Style'
);

export const setDomainStyle = createAction(
  '[Main] Set Domain Style',
  props<{ styles: StyleModel[] }>()
);

export const checkTranslatorRole = createAction(
  '[Main] Check Translator Role'
);

export const initResourcesForTranslator = createAction(
  '[Main] Init Resources Translator'
);

export const addLocationToMap = createAction(
  '[Main] Add Location To Map',
  props<{ location: Location }>()
);

export const updateLocationOnMap = createAction(
  '[Main] Update Location On Map',
  props<{ location: Location }>()
);

export const initLocations = createAction(
  '[Main] Init Locations'
);

export const reInitOnlyLocations = createAction(
  '[Main] Re-init Only Locations'
);

export const setLocations = createAction(
  '[Main] Set Locations',
  props<{ locations: Location[] }>()
);

export const setNavigationInfo = createAction(
  '[Main] Set Navigation Info',
  props<{ navigationInfo: NavigationInfo }>()
);

export const waitMilliseconds = createAction(
  '[Main] WaitMilliseconds',
  props<{ ms: number }>()
);

