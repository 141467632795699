<div class="dialogContainer">
    <h3 class="title3 mb-3" *ngIf="advertisingTypes">{{ 'GENERAL.ADD' | translate }} {{ 'ADVERTISING_DIALOG.ADVERTISING' | translate | lowercase }}</h3>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.TYPE' | translate }}</mat-label>
            <mat-select formControlName="type" hideSingleSelectionIndicator="true" required>
                <mat-option *ngFor="let type of advertisingTypes" [value]="type.Id">
                    {{type.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.MESSAGE' | translate }}</mat-label>
            <input matInput formControlName="message" autocomplete="off">
        </mat-form-field>

        <ngx-dropzone id="ngx1" (change)="imageUpload($event)" class="imageDropzone mt-1 mb-3"
            multiple
            accept=".jpg,.png"
            [maxFileSize]="10000000">
            <ngx-dropzone-label>
                <img src="../../../../../assets/img/icons/image.svg" class="mb-3" alt="file">
                <div class="imageUploadLabel mb-1">
                    <span>{{ 'ADVERTISING_DIALOG.IMAGE_UPLOAD_LABEL_1' | translate }} </span>
                    <span>{{ 'ADVERTISING_DIALOG.IMAGE_UPLOAD_LABEL_2' | translate }}</span>
                </div>
                <span class="imageUploadCaption">{{ 'ADVERTISING_DIALOG.IMAGE_UPLOAD_CAPTION' | translate }}</span>
            </ngx-dropzone-label>
        </ngx-dropzone>

        <div *ngFor="let image of images; let i = index" class="imageBlock px-3 py-2 mb-3">
            <div class="d-flex align-items-center">
                <img src="../../../../../assets/img/icons/image.svg" class="mr-3" alt="file">
                <span class="link" (click)="imageDownload(image)">{{ image.Name }}</span>
                <i class="urbanicon-close pointer ml-auto" aria-label="Remove"
                    (click)="imageRemove(i)" matTooltip="{{'GENERAL.REMOVE' | translate }}"></i>
            </div>
        </div>

        <mat-form-field appearance="outline" class="customDatePicker mt-1 mb-4">
            <mat-label>{{ 'ADVERTISING_LIST.DATE_RANGE' | translate }}</mat-label>
            <input matInput [owlDateTime]="dt1" formControlName="rangeDate" [min]="minDateRange" [owlDateTimeTrigger]="dt1" readonly [selectMode]="'range'" showTi required>

            <i [owlDateTimeTrigger]="dt1" matSuffix class="urbanicon-monitoring-outline pointer"></i>

            <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time>
        </mat-form-field>

        <div class="d-flex align-content-center mb-4">
            <p class="paragraphText mb-0 mt-2 mr-3 no-wrap-text">{{ 'GENERAL.TIME_RANGE' | translate }}:</p>

            <mat-form-field appearance="outline" class="customMatFormField mr-2">
                <mat-label>{{ 'GENERAL.START' | translate }}</mat-label>
                <input matInput type="time" formControlName="startTime" [owlDateTimeTrigger]="tm1" [owlDateTime]="tm1" readonly>
                <owl-date-time [pickerType]="'timer'" #tm1></owl-date-time>
            </mat-form-field>

            <mat-form-field appearance="outline" class="customMatFormField">
                <mat-label>{{ 'GENERAL.END' | translate }}</mat-label>
                <input matInput type="time" formControlName="endTime" [owlDateTimeTrigger]="tm2" [owlDateTime]="tm2" readonly>
                <owl-date-time [pickerType]="'timer'" #tm2></owl-date-time>
            </mat-form-field>
        </div>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'ADVERTISING_LIST.SERVICE' | translate }}</mat-label>
            <mat-select formControlName="service" hideSingleSelectionIndicator="true" required>
                <mat-option *ngFor="let service of services" [value]="service.Id">
                    {{service.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>

    <mat-error *ngIf="error" class="mb-3">
        {{ 'GENERAL.ERROR_ALL_FIELDS_REQUIRED' | translate }}
    </mat-error>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="saveAdvertising()">
            {{ (advertising ? 'GENERAL.UPDATE' : 'GENERAL.ADD') | translate }}
        </button>

        <button mat-button class="btn outlineActionButton w-50 ml-1" mat-dialog-close>{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
</div>
