<div [ngClass]="overlayBG ? 'overlayBG' : 'panelBG'"
    class="d-md-inline-flex d-block-flex flex-sm-wrap align-items-center searchBar">
    <form [formGroup]="formDate" class="mr-3">
        <mat-form-field appearance="outline" class="customDatePicker mr-3">
            <mat-label>{{'GENERAL.START_DATE' | translate }}</mat-label>
            <input matInput [owlDateTime]="dt1" (dateChange)="onStartDateUpdate($event.target.value); removeRangeBtnActive()" 
            [min]="this.minStartDate"
            [max]="this.maxStartDate" formControlName="startDate" [owlDateTimeTrigger]="dt1" readonly>

            <i [owlDateTimeTrigger]="dt1" matSuffix class="urbanicon-monitoring-outline pointer" (click)="onBlankDatesFocus()"></i>

            <owl-date-time #dt1></owl-date-time>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customDatePicker">
            <mat-label>{{'GENERAL.END_DATE' | translate }}</mat-label>
            <input matInput [owlDateTime]="dt2" (dateChange)="onStartDateUpdate($event.target.value); removeRangeBtnActive()" 
            [min]="this.minEndDate"
            [max]="this.maxEndDate" formControlName="endDate" [owlDateTimeTrigger]="dt2" readonly>

            <i [owlDateTimeTrigger]="dt2" matSuffix class="urbanicon-monitoring-outline pointer" (click)="onBlankDatesFocus()"></i>

            <owl-date-time #dt2></owl-date-time>
        </mat-form-field>
    </form>

    <div class="mr-4 mb-4 mb-md-0">
        <button type="submit"  class="btn mb-3 smallActionButton" [disabled]="loadingEvents !== false" (click)="onSearch()"
            matTooltip="{{ 'WEATHERSTATION.SEARCH' | translate }}">
            <i class="urbanicon-search"></i>
        </button>
        <button type="submit" class="btn smallOutlineActionButton marginClearBtn mb-3" (click)="clearDateAndUnsubscribe()"
            matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
            <i class="urbanicon-close"></i>
        </button>
    </div>

    <div>
        <mat-button-toggle-group aria-label="Time Range Set" class="searchRangeBtn mb-4" hideSingleSelectionIndicator="true"
            (valueChange)="setTimeRange($event)" [(ngModel)]="buttonToggleSelected">
            <mat-button-toggle *ngFor="let period of filterPeriods" [value]="period" [disabled]="loadingEvents !== false">
                <div
                    [ngClass]="{'button-toggle-wrap lineheightIT' : currentLanguage === 'it', 'fontSizeToggleEn' : currentLanguage === 'en'}">
                    <span [ngClass]="{'uppercaseText' : currentLanguage === 'it'}">{{'GENERAL.HOURS_LAST' | translate}}
                    </span><span>{{period}}{{'GENERAL.HOURS_H' | translate}}</span></div>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>