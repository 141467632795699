import { DomainProperty } from './../../models/domainProperty';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Device } from '../../models/device';
import { Resource } from '../../models/resource';
import { Domain } from '../../models/domain';
import { Role } from '../../models/userRoles';
import { DeviceBrand } from '../../models/deviceBrand';
import { DeviceModel } from '../../models/deviceModel';
import { Configuration } from '../../models/configuration';
import { Note } from '../../models/Note';
import { RemoteControlService } from '../../models/siralab/remoteControlService';
import { User } from '../../models/loginResponse';
import { Location } from '../../models/location';
import { NavigationInfo } from '../../models/navigationExtra';
import { Widget } from '../../models/widget';
import { Dashboard } from '../../models/dashboard';

@Injectable({
  providedIn: 'root'
})
export class PassDataService {
  private appConfiguration: Subject<Configuration> = new BehaviorSubject<Configuration>(null);
  private dashboards:Subject<Dashboard[]> = new BehaviorSubject<Dashboard[]>([]);
  private devices:Subject<Device[]> = new BehaviorSubject<Device[]>([]);
  private resources:Subject<Resource[]> = new BehaviorSubject<Resource[]>([]);
  private widgets:Subject<Widget[]> = new BehaviorSubject<Widget[]>([]);
  private deviceBrands:Subject<DeviceBrand[]> = new BehaviorSubject<DeviceBrand[]>([]);
  private deviceModels:Subject<DeviceModel[]> = new BehaviorSubject<DeviceModel[]>([]);
  private availableDomains: Subject<Domain[]> = new BehaviorSubject<Domain[]>([]);
  private currentDomain: Subject<Domain> = new BehaviorSubject<Domain>(null);
  private currentDomainProperties: Subject<DomainProperty[]> = new BehaviorSubject<DomainProperty[]>([]);
  private currentUserRoles: Subject<Role[]> = new BehaviorSubject<Role[]>([]);
  private currentUserNotes: Subject<Note[]> = new BehaviorSubject<Note[]>([]);
  private darkModeStatus: Subject<boolean> = new BehaviorSubject<boolean>(null);
  private dashboardToShow: Subject<Dashboard> = new BehaviorSubject<Dashboard>(null);
  private remoteControlServices: Subject<RemoteControlService[]> = new BehaviorSubject<RemoteControlService[]>([]);
  private currentUser: Subject<User> = new BehaviorSubject<User>(null);
  private locations: Subject<Location[]> = new BehaviorSubject<Location[]>([]);
  private navigationInfo: Subject<NavigationInfo> = new BehaviorSubject<NavigationInfo>(null);
  private mapReady: Subject<boolean> = new BehaviorSubject<boolean>(false);

  public get appConfiguration$(): Observable<Configuration> {
    return this.appConfiguration.asObservable();
  }
  public get dashboards$(): Observable<Dashboard[]> {
    return this.dashboards.asObservable();
  }
  public get devices$(): Observable<Device[]> {
    return this.devices.asObservable();
  }
  public get resources$(): Observable<Resource[]> {
    return this.resources.asObservable();
  }
  public get widgets$(): Observable<Widget[]> {
    return this.widgets.asObservable();
  }
  public get deviceBrands$(): Observable<DeviceBrand[]> {
    return this.deviceBrands.asObservable();
  }
  public get deviceModels$(): Observable<DeviceModel[]> {
    return this.deviceModels.asObservable();
  }
  public get availableDomains$(): Observable<Domain[]> {
    return this.availableDomains.asObservable();
  }
  public get currentDomain$(): Observable<Domain> {
    return this.currentDomain.asObservable();
  }
  public get currentDomainProperties$(): Observable<DomainProperty[]> {
    return this.currentDomainProperties.asObservable();
  }
  public get currentUserRoles$(): Observable<Role[]> {
    return this.currentUserRoles.asObservable();
  }
  public get currentUserNotes$(): Observable<Note[]> {
    return this.currentUserNotes.asObservable();
  }
  public get currentDarkModeStatus$(): Observable<boolean> {
    return this.darkModeStatus.asObservable();
  }
  public get dashboardToShow$(): Observable<Dashboard> {
    return this.dashboardToShow.asObservable();
  }
  public get RCServices$(): Observable<RemoteControlService[]> {
    return this.remoteControlServices.asObservable();
  }
  public get currentUser$(): Observable<User>{
    return this.currentUser.asObservable();
  }
  public get locations$(): Observable<Location[]> {
    return this.locations.asObservable();
  }
  public get navigationInfo$(): Observable<NavigationInfo> {
    return this.navigationInfo.asObservable();
  }
  public get mapReady$(): Observable<boolean> {
    return this.mapReady.asObservable();
  }

  public loadAppConfiguration(data: Configuration): void {
    this.appConfiguration.next(data);
  }
  public addDashboards(data: Dashboard[]): void {
    this.dashboards.next(data);
  }
  public addDevices(data: Device[]): void {
    this.devices.next(data);
  }
  public addResources(data: Resource[]): void {
    this.resources.next(data);
  }
  public addWidgets(data: Widget[]): void {
    this.widgets.next(data);
  }
  public addDeviceBrands(data: DeviceBrand[]): void {
    this.deviceBrands.next(data);
  }
  public addDeviceModels(data: DeviceModel[]): void {
    this.deviceModels.next(data);
  }
  public addAvailableDomains(data: Domain[]): void {
    this.availableDomains.next(data);
  }
  public addCurrentDomain(data: Domain): void {
    this.currentDomain.next(data);
  }
  public loadCurrentDomainProperties(data: DomainProperty[]): void {
    this.currentDomainProperties.next(data);
  }
  public loadCurrentUserRoles(data: Role[]): void {
    this.currentUserRoles.next(data);
  }
  public loadCurrentUserNotes(data: Note[]): void {
    this.currentUserNotes.next(data);
  }
  public loadDarkModeStatus(data: boolean): void {
    this.darkModeStatus.next(data);
  }
  public loadDashboardToShow(dashboard: Dashboard) {
    this.dashboardToShow.next(dashboard);
  }
  public loadRCServices(data: RemoteControlService[]) {
    this.remoteControlServices.next(data);
  }
  public loadUser(user: User) {
    this.currentUser.next(user);
  }
  public addLocations(data: Location[]): void {
    this.locations.next(data);
  }
  public setNavigationInfo(data: NavigationInfo): void {
    this.navigationInfo.next(data);
  }
  public loadMap(): void {
    this.mapReady.next(true);
  }
}
