<div class="dialogContainer">
    <h3 class="title3 mb-3">{{ 'CONFIRMATION.CONFIRM' | translate }}</h3>    

    <div class="mb-4">
        <p>{{ 'CONFIRMATION.CONTINUE' | translate }}</p>
        <p *ngIf="confirmMessage">{{ '' + confirmMessage +'' | translate }}</p>
    </div>

    <div class="d-flex justify-content-between">
        <button mat-button type="submit" class="btn actionButton w-50 mr-1" (click)="continue()">{{ 'CONFIRMATION.YES' | translate }}</button>
        <button mat-button class="btn outlineActionButton w-50 ml-1" (click)="cancel()">{{ 'CONFIRMATION.NO' | translate }}</button>
    </div>
</div>